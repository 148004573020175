export interface PayAmountOwingForm {
  successMessage?: string;
  errorMessage?: string;
}

export enum PayAmountOwingFormActions {
  CLEAR_MESSAGES = 'CLEAR_MESSAGES',
}

export type clearMessagesAction = {
  type: PayAmountOwingFormActions.CLEAR_MESSAGES;
};

export type PayAmountOwingFormTypes = clearMessagesAction;
