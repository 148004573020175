import createCachedSelector from 're-reselect';
import { AppState } from 'store';
import { PaymentSource } from 'store/payment';

const getPaymentSources = (
  state: AppState,
): PaymentSource[] | null | undefined => state.customer.paymentSources;

const selectPaymentSource = (
  paymentSources?: PaymentSource[] | null,
): PaymentSource | undefined => {
  if (paymentSources) {
    return paymentSources.filter(ps => ps.default === true)[0];
  }
};

export default createCachedSelector(
  getPaymentSources,
  selectPaymentSource,
)(state => state.customer.id);
