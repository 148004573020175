export const customerHasBalance = (
  balanceInCents?: number,
): balanceInCents is number => {
  return typeof balanceInCents !== 'undefined' && balanceInCents < 0;
};

export const customerHasBalanceDue = (
  balanceInCents?: number,
): balanceInCents is number => {
  return typeof balanceInCents !== 'undefined' && balanceInCents > 0;
};
