import { PlansActions } from './types';
import { get } from 'utils/fetch';
import { IAPIResponse, IAPIPlansAndAddons, API_BASE_URL } from 'store/api';
import { ThunkyDispatch, ThunkyAction } from 'store/types';
import { asyncAction } from 'store/asyncActions';

export const getPlans = (): ThunkyAction => {
  return async (dispatch: ThunkyDispatch) => {
    return dispatch(asyncAction(PlansActions.GET_PLANS, apiGetPlans));
  };
};

export const apiGetPlans = async (): Promise<any> => {
  const response = await get<IAPIResponse<IAPIPlansAndAddons>>(
    `${API_BASE_URL}/plans`,
  );

  return response;
};
