import { get, IHttpResponse } from 'utils/fetch';
import { InvoicesActions } from './types';
import { IAPIResponse, IAPIInvoice, API_BASE_URL } from 'store/api';
import { ThunkyDispatch, ThunkyAction } from 'store/types';
import { asyncAction } from 'store/asyncActions';

export const getInvoices = (): ThunkyAction => {
  return async (dispatch: ThunkyDispatch) => {
    return dispatch(asyncAction(InvoicesActions.GET_INVOICES, apiGetInvoices));
  };
};

export const apiGetInvoices = async (): Promise<
  IHttpResponse<IAPIResponse<IAPIInvoice[]>> | undefined
> => {
  const response = await get<IAPIResponse<IAPIInvoice[]>>(
    `${API_BASE_URL}/invoices`,
  );

  return response;
};

export const getInvoice = (invoiceId: number): ThunkyAction => {
  return async (dispatch: ThunkyDispatch) => {
    return dispatch(
      asyncAction(InvoicesActions.GET_INVOICE, apiGetInvoice, invoiceId),
    );
  };
};

export const apiGetInvoice = async (
  invoiceId: number,
): Promise<IHttpResponse<IAPIResponse<IAPIInvoice>> | undefined> => {
  const response = await get<IAPIResponse<IAPIInvoice>>(
    `${API_BASE_URL}/invoices/${invoiceId}`,
  );

  return response;
};

export const getUpcomingInvoice = (): ThunkyAction => {
  return async (dispatch: ThunkyDispatch) => {
    return dispatch(
      asyncAction(InvoicesActions.GET_UPCOMING_INVOICE, apiGetUpcomingInvoice),
    );
  };
};

export const apiGetUpcomingInvoice = async (): Promise<
  IHttpResponse<IAPIResponse<IAPIInvoice>> | undefined
> => {
  const response = await get<IAPIResponse<IAPIInvoice>>(
    `${API_BASE_URL}/upcoming_invoice`,
  );

  return response;
};
