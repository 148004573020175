import { IAPIInvoice, IAPIResponse } from 'store/api';
import { AsyncAction } from 'store/asyncActions';
import { IHttpResponse } from 'utils/fetch';

export interface Invoice {
  id?: string;
  pdfLink?: string;
  amountDueInCents?: number | null;
  amountPaidInCents?: number | null;
  subtotalInCents?: number;
  taxInCents?: number | null;
  totalInCents?: number;
  periodEnd?: number;
  currency?: string;
  number?: string;
  paid?: boolean;
  created?: number;
}

export enum InvoicesActions {
  GET_INVOICES = 'GET_INVOICES',
  GET_INVOICE = 'GET_INVOICE',
  GET_UPCOMING_INVOICE = 'GET_UPCOMING_INVOICE',
}

export type getInvoicesAsyncAction = AsyncAction<
  InvoicesActions.GET_INVOICES,
  IHttpResponse<IAPIResponse<IAPIInvoice[]>>
>;

export type getInvoiceAsyncAction = AsyncAction<
  InvoicesActions.GET_INVOICE,
  IHttpResponse<IAPIResponse<IAPIInvoice>>
>;

export type getUpcomingInvoiceAsyncAction = AsyncAction<
  InvoicesActions.GET_UPCOMING_INVOICE,
  IHttpResponse<IAPIResponse<IAPIInvoice>>
>;

export type InvoicesTypes =
  | getInvoicesAsyncAction
  | getInvoiceAsyncAction
  | getUpcomingInvoiceAsyncAction;
