export const getHeadTag = () => {
  return window?.document.getElementsByTagName('head')[0];
};

export const injectScript = (id: string, src: string, onload?: () => void) => {
  const headTag = getHeadTag();
  if (headTag) {
    const scriptTag = window.document.createElement('script');
    scriptTag.id = id;
    scriptTag.src = src;

    if (onload) {
      scriptTag.onload = onload;
    }

    if ('async' in scriptTag) {
      scriptTag.async = true;
    }

    if ('defer' in scriptTag) {
      scriptTag.defer = true;
    }
    headTag.appendChild(scriptTag);
    return true;
  }
  return false;
};

export const removeScript = (id: string) => {
  const scriptTag = window.document.getElementById(id);
  if (scriptTag) {
    scriptTag.remove();
    return true;
  }

  return false;
};
