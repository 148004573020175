import { Dispatch, combineReducers } from 'redux';
import { CustomerActions } from './customer/types';
import { PaymentWizardActions } from './paymentWizard/types';
import { InvoicesActions } from './invoices/types';
import { PlansActions } from './plans/types';
import { SubscriptionActions } from './subscription/types';

// Inspired by: https://spin.atomicobject.com/2018/11/19/redux-thunk-async-actions/

export enum AsyncActionStatus {
  UNSTARTED = 'UNSTARTED',
  STARTED = 'STARTED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

interface StartedAsyncAction<T> {
  type: T;
  status: AsyncActionStatus.STARTED;
}

interface SucceededAsyncAction<T, P = any> {
  type: T;
  status: AsyncActionStatus.SUCCEEDED;
  payload: P;
}

interface FailedAsyncAction<T, P = any> {
  type: T;
  status: AsyncActionStatus.FAILED;
  error: P;
}

export type AsyncAction<T, P = any> =
  | StartedAsyncAction<T>
  | SucceededAsyncAction<T, P>
  | FailedAsyncAction<T, P>;

export function startedAsyncAction<T>(type: T): StartedAsyncAction<T> {
  return {
    type,
    status: AsyncActionStatus.STARTED,
  };
}

export function succeededAsyncAction<T, P>(
  type: T,
  payload: P,
): SucceededAsyncAction<T, P> {
  return {
    type,
    status: AsyncActionStatus.SUCCEEDED,
    payload,
  };
}

export function failedAsyncAction<T, P>(
  type: T,
  error: P,
): FailedAsyncAction<T, P> {
  return {
    type,
    status: AsyncActionStatus.FAILED,
    error,
  };
}

export function asyncAction<T, P>(
  type: T,
  f: (...args: any[]) => Promise<P>,
  ...args: any[]
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startedAsyncAction(type));
    try {
      const payload = await f(...args);
      return dispatch(succeededAsyncAction(type, payload));
    } catch (error) {
      console.error('Error', error);
      return dispatch(failedAsyncAction(type, error));
    }
  };
}

export const initialState = AsyncActionStatus.UNSTARTED;

export function reduceAsyncActionStatusOf<T extends string>(type: T) {
  return (
    state: AsyncActionStatus = initialState,
    action: AsyncAction<T>,
  ): AsyncActionStatus => {
    if (action.type === type) {
      return action.status;
    }
    return state;
  };
}

export const reduceAsyncActionStatusByDomain = combineReducers({
  billingAddressForm: reduceAsyncActionStatusOf(CustomerActions.PUT_CUSTOMER),
  customer: reduceAsyncActionStatusOf(CustomerActions.GET_CUSTOMER),
  coupon: reduceAsyncActionStatusOf(PaymentWizardActions.APPLY_COUPON_CODE),
  invoices: reduceAsyncActionStatusOf(InvoicesActions.GET_INVOICES),
  plans: reduceAsyncActionStatusOf(PlansActions.GET_PLANS),
  subscription: reduceAsyncActionStatusOf(SubscriptionActions.GET_SUBSCRIPTION),
  putSubscription: reduceAsyncActionStatusOf(
    SubscriptionActions.PUT_SUBSCRIPTION,
  ),
  postCreditCard: reduceAsyncActionStatusOf(CustomerActions.POST_CREDIT_CARD),
  previewSubscription: reduceAsyncActionStatusOf(
    SubscriptionActions.POST_SUBSCRIPTION_PREVIEW,
  ),
  payAmountOwing: reduceAsyncActionStatusOf(
    SubscriptionActions.PAY_AMOUNT_OWING,
  ),
});

export const asyncActionStatusBool = (
  actionStatus: AsyncActionStatus,
): boolean => {
  return (
    actionStatus === AsyncActionStatus.STARTED ||
    actionStatus === AsyncActionStatus.UNSTARTED
  );
};
