import React from 'react';

import CurrencyNumber from 'components/CurrencyNumber';
import { Currency } from 'utils/countries';

import quoterIcon from 'assets/icon.svg';
import plusIcon from 'assets/plus-icon.svg';

import style from './YourSubscription.module.scss';

export type ComponentType = 'plan' | 'addon';
export type ComponentInterval = 'monthly' | 'yearly';

interface Props {
  type: ComponentType;
  name: string;
  monthlyPrice: number;
  interval: ComponentInterval;
  currency: Currency;
  multiTenantUsers?: number;
  enterpriseQuotes?: number;
}

const SubscriptionItem = (props: Props) => {
  const { type, name, monthlyPrice, interval, currency } = props;
  let componentIcon;
  if (type === 'plan') {
    componentIcon = (
      <img src={quoterIcon} className="d-inline-block" height="34" alt="icon" />
    );
  } else {
    componentIcon = (
      <img src={plusIcon} className="d-inline-block" height="34" alt="icon" />
    );
  }

  let billedAnnually = interval === 'yearly' ? ', billed annually' : '';

  return (
    <div className="row mb-3">
      <div className="col col-auto pl-4 pr-1">{componentIcon}</div>
      <div className="col">
        <h6 className={`mb-0 text-uppercase ${style.componentIcon}`}>{name}</h6>
        <small className="text-muted">
          {props.multiTenantUsers && (
            <>
              {props.multiTenantUsers} Users <br />
            </>
          )}
          {props.enterpriseQuotes && (
            <>
              {props.enterpriseQuotes} Quotes <br />
            </>
          )}
          <CurrencyNumber value={monthlyPrice} currency={currency} />
          /mo{billedAnnually}
        </small>
      </div>
    </div>
  );
};

export default SubscriptionItem;
