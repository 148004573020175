import createCachedSelector from 're-reselect';
import { AppState } from 'store';
import { PaymentWizard } from 'store/paymentWizard/types';
import { AddonID, Addons, Plan } from 'store/plans/types';
import { Subscription } from 'store/subscription/types';
import selectPlanById from './selectPlanById';
import selectPlansAddons from './selectPlansAddons';

import {
  isChangedMultiTenant,
  isChangedEnterpriseQuotes,
  isChangedSubscription,
} from 'store/subscription/utility';

import {
  isAddonSelected,
  isValidEnterpriseQuotesCount,
  isValidMultiTenantUserCount,
} from 'store/plans/utility';

const getPaymentWizard = (state: AppState): PaymentWizard =>
  state.paymentWizard;

const getSubscription = (state: AppState): Subscription => state.subscription;

const selectIsPaymentDisabled = (
  paymentWizard: PaymentWizard,
  subscription: Subscription,
  selectedPlan?: Plan,
  selectedAddons?: Addons,
): boolean => {
  let isPaymentDisabled = true;

  const { multiTenantUsers, enterpriseQuotes, planInterval } = paymentWizard;

  const hasValidMultiTenantAddon =
    isAddonSelected(selectedAddons, AddonID.MULTI_TENANT_USERS) &&
    isValidMultiTenantUserCount(multiTenantUsers) &&
    isChangedMultiTenant(multiTenantUsers, subscription);

  const hasValidEnterpriseQuotes =
    isAddonSelected(selectedAddons, AddonID.ENTERPRISE_QUOTES) &&
    isValidEnterpriseQuotesCount(enterpriseQuotes) &&
    isChangedEnterpriseQuotes(enterpriseQuotes, subscription);

  const hasChangedSubscription = isChangedSubscription(
    selectedPlan,
    selectedAddons,
    planInterval,
    subscription,
  );

  if (
    hasValidMultiTenantAddon ||
    hasValidEnterpriseQuotes ||
    hasChangedSubscription
  ) {
    isPaymentDisabled = false;
  }

  return isPaymentDisabled;
};

export default createCachedSelector(
  getPaymentWizard,
  getSubscription,
  selectPlanById,
  selectPlansAddons,
  selectIsPaymentDisabled,
)(state => 'isPaymentDisabled');
