import React from 'react';
import style from './IntervalSwitch.module.scss';
import { PaymentInterval } from 'store/payment';

interface Props {
  interval: PaymentInterval;
  onSwitch: (interval: PaymentInterval) => void;
}

const IntervalSwitch = (props: Props) => {
  const handleSwitch = (interval: PaymentInterval) => {
    props.onSwitch(interval);
  };

  const handleClickMonthly = () => {
    handleSwitch(PaymentInterval.MONTHLY);
  };

  const handleClickAnnual = () => {
    handleSwitch(PaymentInterval.YEARLY);
  };

  const handleClickSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSwitch(
      e.target.checked ? PaymentInterval.YEARLY : PaymentInterval.MONTHLY,
    );
  };

  return (
    <div className={style.root}>
      <div className="text-right" onClick={handleClickMonthly}>
        <div className={style.switchOption}>Monthly Billing</div>
        <div className={style.switchHelper}>No Contract</div>
      </div>
      <div
        className={`custom-control custom-switch custom-switch-lg ${style.switch}`}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id="customSwitch1"
          checked={props.interval === PaymentInterval.YEARLY}
          onChange={handleClickSwitch}
        />
        <label className="custom-control-label" htmlFor="customSwitch1"></label>
      </div>
      <div onClick={handleClickAnnual}>
        <div className={style.switchOption}>Yearly Billing</div>
        <div className={style.switchHelper}>Save ~20%</div>
      </div>
    </div>
  );
};

export default IntervalSwitch;
