import { PaymentInterval, PlanCoupon } from 'store/payment';
import { PlanID, AddonID } from 'store/plans/types';
import { AsyncAction } from 'store/asyncActions';
import { IHttpResponse } from 'utils/fetch';
import { IAPIResponse, IAPICouponValidation } from 'store/api';

export interface PaymentWizard {
  addons: AddonID[];
  couponCode: string | null;
  planCoupon: PlanCoupon | null;
  couponErrorMessage: string;
  errorMessage: string;
  successMessage: string;
  multiTenantUsers: number;
  enterpriseQuotes: number;
  planId?: PlanID;
  planInterval: PaymentInterval;
}

export enum PaymentWizardActions {
  SET_INTERVAL = 'SET_INTERVAL',
  SET_PLAN_ID = 'SET_PLAN_ID',
  ADD_ADDON = 'ADD_ADDON',
  REMOVE_ADDON = 'REMOVE_ADDON',
  SET_MULTI_TENANT = 'SET_MULTI_TENANT',
  SET_ENTERPRISE_QUOTES = 'SET_ENTERPRISE_QUOTES',
  INCREMENT_MULTI_TENANT = 'INCREMENT_MULTI_TENANT',
  DECREMENT_MULTI_TENANT = 'DECREMENT_MULTI_TENANT',
  INCREMENT_ENTERPRISE_QUOTES = 'INCREMENT_ENTERPRISE_QUOTES',
  DECREMENT_ENTERPRISE_QUOTES = 'DECREMENT_ENTERPRISE_QUOTES',
  SUBMIT_COUPON_CODE = 'SUBMIT_COUPON_CODE',
  APPLY_COUPON_CODE = 'APPLY_COUPON_CODE',
  CLEAR_MESSAGES = 'CLEAR_MESSAGES',
  CONTINUE_TO_ADDONS = 'CONTINUE_TO_ADDONS',
}

export type setIntervalAction = {
  type: PaymentWizardActions.SET_INTERVAL;
  payload: PaymentInterval;
};

export type setPlanIdAction = {
  type: PaymentWizardActions.SET_PLAN_ID;
  payload: PlanID;
};

export type continueToAddonsAction = {
  type: PaymentWizardActions.CONTINUE_TO_ADDONS;
  redirect: string;
};

export type addAddonAction = {
  type: PaymentWizardActions.ADD_ADDON;
  payload: AddonID;
};

export type removeAddonAction = {
  type: PaymentWizardActions.REMOVE_ADDON;
  payload: AddonID;
};

export type setMultiTenantAction = {
  type: PaymentWizardActions.SET_MULTI_TENANT;
  payload: string;
};

export type incrementMultiTenantAction = {
  type: PaymentWizardActions.INCREMENT_MULTI_TENANT;
};

export type decrementMultiTenantAction = {
  type: PaymentWizardActions.DECREMENT_MULTI_TENANT;
};

export type setMultiEnterpriseQuotesAction = {
  type: PaymentWizardActions.SET_ENTERPRISE_QUOTES;
  payload: string;
};

export type incrementEnterpriseQuotesAction = {
  type: PaymentWizardActions.INCREMENT_ENTERPRISE_QUOTES;
};

export type decrementEnterpriseQuotesAction = {
  type: PaymentWizardActions.DECREMENT_ENTERPRISE_QUOTES;
};

export type submitCouponCodeAction = {
  type: PaymentWizardActions.SUBMIT_COUPON_CODE;
  payload: string;
};

export type applyCouponCodeAction = AsyncAction<
  typeof PaymentWizardActions.APPLY_COUPON_CODE,
  IHttpResponse<IAPIResponse<IAPICouponValidation>>
>;

export type clearMessagesAction = {
  type: PaymentWizardActions.CLEAR_MESSAGES;
};

export type PaymentWizardTypes =
  | setIntervalAction
  | setPlanIdAction
  | addAddonAction
  | removeAddonAction
  | setMultiTenantAction
  | incrementMultiTenantAction
  | decrementMultiTenantAction
  | submitCouponCodeAction
  | applyCouponCodeAction
  | incrementEnterpriseQuotesAction
  | setMultiEnterpriseQuotesAction
  | decrementEnterpriseQuotesAction
  | clearMessagesAction;
