import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Subscription } from 'store/subscription/types';
import { Currency } from 'utils/countries';

import Card from 'containers/Billing/components/Card';
import ComponentSpinner from 'containers/Billing/components/ComponentSpinner';
import CurrencyNumber from 'components/CurrencyNumber';

interface Props {
  currency: Currency;
  isLoading: boolean;
  subscription: Subscription | undefined;
}

const YourPlan = (props: Props) => {
  let cardBody: React.ReactNode;

  if (props.isLoading) {
    cardBody = <ComponentSpinner />;
  } else if (
    !props.subscription?.paymentInterval ||
    !props.subscription?.plan?.name ||
    !props.subscription?.priceInCents
  ) {
    cardBody = (
      <div className="text-muted text-center">Unable to retrieve Plan</div>
    );
  } else {
    cardBody = (
      <Fragment>
        <div className="row">
          <div className="col">
            <h4>{props.subscription.plan.name}</h4>
            <Link to="/plans" className="text-muted text-underline">
              Plan and Add-On Details
            </Link>
          </div>
          <div className="col-auto text-right">
            <h4 className="text-primary">
              <CurrencyNumber
                currency={props.currency}
                value={props.subscription.priceInCents / 100}
              />
            </h4>
            <div>Billed {props.subscription.paymentInterval}</div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <Link to="/plans">
              <button className="btn btn-primary">Change Plan</button>
            </Link>
          </div>
        </div>
      </Fragment>
    );
  }

  return <Card title="Your Plan">{cardBody}</Card>;
};

export default YourPlan;
