import { connect } from 'react-redux';
import { AppState } from 'store';
import { getInvoices, getUpcomingInvoice } from 'store/invoices/actions';
import { getCustomer } from 'store/customer/actions';
import { getPlans } from 'store/plans/actions';
import { getSubscription } from 'store/subscription/actions';

import Layout from './Layout';

const mapStateToProps = (state: AppState) => ({
  customer: state.customer,
  subscription: state.subscription,
});

const mapDispatchToProps = {
  getCustomer: getCustomer,
  getInvoices: getInvoices,
  getPlans: getPlans,
  getSubscription: getSubscription,
  getUpcomingInvoice: getUpcomingInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
