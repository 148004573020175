import { Addon, PlansTypes, PlansActions } from './types';
import { AsyncActionStatus } from 'store/asyncActions';
import { convertAPIAddonId, convertAPIPricingInterval } from './utility';
import { getAPIResource } from 'store/api';

type Addons = { [id: string]: Addon };

const initialState: Addons = {};

export function addons(
  state: Addons = initialState,
  action: PlansTypes,
): Addons {
  let newState: Addons;

  switch (action.type) {
    case PlansActions.GET_PLANS:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        let apiAddons = getAPIResource(action.payload);

        if (apiAddons) {
          let addons: Addons = {};

          for (const apiAddonId in apiAddons.addons) {
            const addonId = convertAPIAddonId(apiAddonId);

            if (addonId) {
              const addon = apiAddons.addons[addonId];
              const pricing = convertAPIPricingInterval(
                apiAddons.addons[addonId].pricing,
              );

              addons[addonId] = {
                description: addon.description,
                features: addon.features,
                icon: addon.icon,
                id: addonId,
                name: addon.name,
                pricing: pricing,
              };
            }
          }

          return addons;
        }

        newState = state;
      } else {
        newState = state;
      }
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
