import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Invoice } from 'store/invoices/types';

import InvoiceList from 'containers/Billing/components/InvoiceList';
import ComponentSpinner from 'containers/Billing/components/ComponentSpinner';

interface Props {
  invoices: Invoice[];
  isLoading: boolean;
}

const LatestInvoices = (props: Props) => (
  <Fragment>
    <div className="row my-4">
      <div className="col">
        <h4>Latest Invoices</h4>
      </div>
      <div className="col text-right">
        <Link to="/invoices" className="text-muted text-underline">
          All Invoices
        </Link>
      </div>
    </div>
    {props.isLoading ? (
      <ComponentSpinner />
    ) : (
      <InvoiceList invoices={props.invoices} />
    )}
  </Fragment>
);

export default LatestInvoices;
