import React, { useRef, useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import useQueryString from '../../hooks/useQueryString';
import useForm, { Form } from '../../hooks/useForm';
import zxcvbn from 'zxcvbn';
import Input, { Ref } from '../../components/Form/Input';
import ProgressBar from '../../components/ProgressBar';
import SubmitButton from '../../components/Form/SubmitButton';
import validate from '../../utils/validate';
import { setPlan, setInterval, setCurrency } from '../../store/signup/actions';
import * as H from 'history';
import './CreateAccountView.scss';
import Agreement from 'components/Agreement';
import { BillingForm } from 'store/forms';
import Icon from 'components/Icon';
import { PaymentInterval } from 'store/payment';
import { Plan } from 'store/plans/types';
import { Currency } from 'utils/countries';

type Props = {
  routePlanID?: string;
  plan?: Plan;
  planSet: boolean;
  routeInterval?: string;
  interval?: PaymentInterval;
  intervalSet: boolean;
  routeCurrencyISO?: string;
  currency?: Currency;
  currencySet: boolean;
  form: BillingForm;
  formIsValid: boolean;
  emailInvalid: boolean;
  emailErrorMessage?: string | null;
  subdomainInvalid: boolean;
  subdomainErrorMessage?: string | null;
  isLoading: boolean;
  setPlan: typeof setPlan;
  setInterval: typeof setInterval;
  setCurrency: typeof setCurrency;
  applyCouponCode: (
    currencyISO: string,
    interval: PaymentInterval,
    couponCode: string,
  ) => any;
  validateEmail: (email: string) => Promise<any>;
  validateSubdomain: (subdomain: string) => Promise<any>;
  submitAccount: (form: BillingForm) => Promise<any>;
  history: H.History;
};

export default function CreateAccountView(props: Props) {
  // Emulate componentDidMount behaviour with React Hooks.
  const {
    routePlanID,
    plan,
    planSet,
    routeInterval,
    interval,
    intervalSet,
    routeCurrencyISO,
    currency,
    currencySet,
    form: formState,
    formIsValid,
    emailInvalid,
    emailErrorMessage,
    subdomainInvalid,
    subdomainErrorMessage,
    isLoading,
    setPlan,
    setInterval,
    setCurrency,
    applyCouponCode,
    validateEmail,
    validateSubdomain,
    submitAccount,
    history,
  } = props;

  // Initialization, e.g. componentDidMount
  const [initialized, setInitialized] = useState(false);
  const firstNameRef = useRef<Ref>(null);
  useEffect(() => {
    if (!initialized) {
      setPlan(routePlanID);
      setInterval(routeInterval);
      setCurrency(routeCurrencyISO);

      if (firstNameRef.current) {
        firstNameRef.current.focus();
      }
      setInitialized(true);
    }
  }, [
    initialized,
    routePlanID,
    setPlan,
    routeInterval,
    setInterval,
    routeCurrencyISO,
    setCurrency,
    setInitialized,
  ]);

  // Apply coupon code from querystring param once we know plan, interval, and currency
  let queryString = useQueryString();
  const [autoCouponCodeApplied, setAutoCouponCodeApplied] = useState(false);
  useEffect(() => {
    if (autoCouponCodeApplied) return;

    if (
      !planSet ||
      !plan ||
      !intervalSet ||
      !interval ||
      !currencySet ||
      !currency
    )
      return;

    let couponCode = queryString.get('couponCode');
    if (!couponCode || couponCode === '') return;

    applyCouponCode(currency.iso, interval, couponCode);

    setAutoCouponCodeApplied(true);
  }, [
    autoCouponCodeApplied,
    plan,
    planSet,
    interval,
    intervalSet,
    currency,
    currencySet,
    queryString,
    applyCouponCode,
    setAutoCouponCodeApplied,
  ]);

  // Initialize form state
  const initialFormState: Form = {
    isValid: formIsValid ? true : undefined,
    formControls: {
      firstName: {
        value: formState.firstName || '',
        validationRules: {
          isRequired: true,
          maxLength: 50,
        },
        isValid: formState.firstName ? true : undefined,
      },
      lastName: {
        value: formState.lastName || '',
        validationRules: {
          isRequired: true,
          maxLength: 50,
        },
        isValid: formState.lastName ? true : undefined,
      },
      companyName: {
        value: formState.companyName || '',
        validationRules: {
          isRequired: true,
          maxLength: 100,
        },
        isValid: formState.companyName ? true : undefined,
      },
      subdomain: {
        value: formState.subdomain || '',
        validationRules: {
          isRequired: true,
          minLength: 3,
          maxLength: 50,
          regex: /^[A-Za-z0-9-]+$/,
        },
        isValid: formState.subdomain ? true : undefined,
      },
      email: {
        value: formState.email || '',
        validationRules: {
          isRequired: true,
          maxLength: 100,
          isEmail: true,
        },
        isValid: formState.email ? true : undefined,
      },
      password: {
        value: formState.password || '',
        validationRules: {
          isRequired: true,
          minLength: 8,
          maxLength: 30,
          password: 2,
        },
        isValid: formState.password ? true : undefined,
      },
      phone: {
        value: formState.phone || '',
        validationRules: {
          isRequired: true,
          isPhoneNumber: true,
          minLength: 10,
          maxLength: 20,
        },
        isValid: formState.phone ? true : undefined,
      },
    },
  };
  const [continuePressed, setContinuePressed] = useState(false);
  const onFormSubmit = () => {
    const signupForm: BillingForm = {
      ...formState,
      firstName: form.formControls.firstName.value,
      lastName: form.formControls.lastName.value,
      companyName: form.formControls.companyName.value,
      subdomain: form.formControls.subdomain.value,
      email: form.formControls.email.value,
      password: form.formControls.password.value,
      phone: form.formControls.phone.value,
    };
    submitAccount(signupForm);
    setContinuePressed(true);
  };
  const { form, setForm, handleFormChange, handleFormSubmit } = useForm(
    initialFormState,
    validate,
    onFormSubmit,
  );
  const { formControls } = form;

  // Handle automatic subdomain from company name
  const formatSubdomain = (name: string) =>
    name
      .trim()
      .toLowerCase()
      .replace(/&/g, ' and ')
      .replace(/[^0-9a-z\s]/g, '')
      .replace(/(\s)+/g, '-')
      .replace(/-$/, '');
  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFormChange(e);

    let subdomainValue = formatSubdomain(e.target.value);

    setForm((form: Form) => ({
      ...form,
      formControls: {
        ...form.formControls,
        subdomain: {
          ...form.formControls.subdomain,
          value: subdomainValue,
          isValid: validate(
            subdomainValue,
            form.formControls.subdomain.validationRules || {},
          ),
        },
      },
    }));
    setSubdomain(subdomainValue);
  };

  // Validate subdomain on change (after 500ms)
  const [subdomain, setSubdomain] = useState('');
  const debouncedSubdomain = useDebounce(subdomain, 500);
  useEffect(() => {
    if (
      form.formControls.subdomain.isValid === true &&
      debouncedSubdomain !== ''
    ) {
      validateSubdomain(debouncedSubdomain);
    }
  }, [
    form.formControls.subdomain.isValid,
    debouncedSubdomain,
    validateSubdomain,
  ]);
  const handleSubdomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFormChange(e);
    setSubdomain(e.target.value);
  };

  // Validate email on change (after 500ms)
  const [email, setEmail] = useState('');
  const debouncedEmail = useDebounce(email, 500);
  useEffect(() => {
    if (form.formControls.email.isValid === true && debouncedEmail !== '') {
      validateEmail(debouncedEmail);
    }
  }, [form.formControls.email.isValid, debouncedEmail, validateEmail]);
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFormChange(e);
    setEmail(e.target.value);
  };

  // Handle redirect after form submission
  useEffect(() => {
    if (continuePressed && formIsValid && !isLoading) {
      history.push('/addons');
    }
  }, [continuePressed, formIsValid, isLoading, history]);

  return (
    <div className="CreateAccountView">
      <h1 className="text-center">Create your account</h1>

      <form className="pt-5" noValidate onSubmit={handleFormSubmit}>
        <div className="row">
          <Input
            id="firstName"
            ref={firstNameRef}
            type="text"
            label="First name"
            placeholder="First name"
            onChange={handleFormChange}
            isInvalid={formControls.firstName.isValid === false}
            isValid={formControls.firstName.isValid === true}
            formGroupClassName="col-sm-6"
            value={formControls.firstName.value || ''}
          />

          <Input
            id="lastName"
            type="text"
            label="Last name"
            placeholder="Last name"
            onChange={handleFormChange}
            isInvalid={formControls.lastName.isValid === false}
            isValid={formControls.lastName.isValid === true}
            formGroupClassName="col-sm-6"
            value={formControls.lastName.value || ''}
          />
        </div>

        <div className="row">
          <Input
            id="companyName"
            type="text"
            label="Company name"
            placeholder="Company name"
            onChange={handleCompanyNameChange}
            isInvalid={formControls.companyName.isValid === false}
            isValid={formControls.companyName.isValid === true}
            formGroupClassName="col-12"
            value={formControls.companyName.value || ''}
          />
        </div>

        <div className="row">
          <Input
            id="subdomain"
            type="text"
            label="Quoter address"
            placeholder="company-name"
            inputPrepend="https://"
            inputAppend=".quoter.com"
            helperText="This will be your Quoter URL"
            onChange={handleSubdomainChange}
            isInvalid={
              subdomainInvalid === true ||
              formControls.subdomain.isValid === false
            }
            invalidFeedback={subdomainErrorMessage}
            isValid={formControls.subdomain.isValid === true}
            formGroupClassName="col-12"
            value={formControls.subdomain.value || ''}
          />
        </div>

        <div className="row">
          <Input
            id="email"
            type="email"
            label="Work email"
            placeholder="Work email"
            helperText="This will be your Quoter username"
            onChange={handleEmailChange}
            isInvalid={
              emailInvalid === true || formControls.email.isValid === false
            }
            invalidFeedback={emailErrorMessage}
            isValid={formControls.email.isValid === true}
            formGroupClassName="col-12"
            value={formControls.email.value || ''}
          />
        </div>

        <div className="row">
          <Input
            id="password"
            type="password"
            label="Password"
            placeholder="Password"
            helperText={
              formControls.password.isTouched && formControls.password.value ? (
                <ProgressBar
                  valueMin={10}
                  valueNow={
                    (zxcvbn(formControls.password.value).score / 4) * 100
                  }
                  valueThresholds={{
                    0: 'bg-danger',
                    50: 'bg-warning',
                    75: 'bg-info',
                    100: 'bg-success',
                  }}
                />
              ) : (
                'Must be a strong password'
              )
            }
            onChange={handleFormChange}
            isInvalid={formControls.password.isValid === false}
            isValid={formControls.password.isValid === true}
            formGroupClassName="col-12"
            value={formControls.password.value || ''}
          />
        </div>

        <div className="row">
          <Input
            id="phone"
            type="tel"
            label="Phone number"
            placeholder="Phone number"
            onChange={handleFormChange}
            isInvalid={formControls.phone.isValid === false}
            isValid={formControls.phone.isValid === true}
            formGroupClassName="col-12"
            value={formControls.phone.value || ''}
          />
        </div>

        <div className="pt-3 pb-5">
          <SubmitButton
            isLoading={isLoading}
            disabled={!form.isValid || subdomainInvalid}
            onClick={handleFormSubmit}
          >
            Continue{' '}
            <Icon name="long-arrow-alt-right" size="sm" className="ml-2" />
          </SubmitButton>
          {form.isValid && <Agreement />}
        </div>
      </form>
    </div>
  );
}
