export interface BillingAddressForm {
  successMessage?: string;
  errorMessage?: string;
}

export enum BillingAddressFormActions {
  CLEAR_MESSAGES = 'CLEAR_MESSAGES',
}

export type clearMessagesAction = {
  type: BillingAddressFormActions.CLEAR_MESSAGES;
};

export type BillingAddressFormTypes = clearMessagesAction;
