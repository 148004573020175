import { AsyncAction } from '../asyncActions';
import { Currency } from '../../utils/countries';
import { IHttpResponse } from '../../utils/fetch';
import { IAPIResponse, IAPICouponValidation, IAPISignup } from '../api';
import {
  OnboardingCoupon,
  PlanCoupon,
  PaymentInterval,
  PaymentAmounts,
} from 'store/payment';
import { BillingForm } from 'store/forms';
import { Plan, Addon } from 'store/plans/types';

export const SIGNUP_DETECT_CURRENCY = 'SIGNUP_DETECT_CURRENCY';
export const SIGNUP_SET_CURRENCY = 'SIGNUP_SET_CURRENCY';
export const SIGNUP_SET_PLAN = 'SIGNUP_SET_PLAN';
export const SIGNUP_SET_INTERVAL = 'SIGNUP_SET_INTERVAL';
export const SIGNUP_SUBMIT_ACCOUNT = 'SIGNUP_SUBMIT_ACCOUNT';
export const SIGNUP_SELECT_ADDON = 'SIGNUP_SELECT_ADDON';
export const SIGNUP_REMOVE_ADDON = 'SIGNUP_REMOVE_ADDON';
export const SIGNUP_SUBMIT_ADDONS = 'SIGNUP_SUBMIT_ADDONS';
export const SIGNUP_INCREMENT_MULTI_TENANT = 'SIGNUP_INCREMENT_MULTI_TENANT';
export const SIGNUP_DECREMENT_MULTI_TENANT = 'SIGNUP_DECREMENT_MULTI_TENANT';
export const SIGNUP_INCREMENT_ENTERPRISE_QUOTES =
  'SIGNUP_INCREMENT_ENTERPRISE_QUOTES';
export const SIGNUP_DECREMENT_ENTERPRISE_QUOTES =
  'SIGNUP_DECREMENT_ENTERPRISE_QUOTES';
export const SIGNUP_VALIDATE_EMAIL = 'SIGNUP_VALIDATE_EMAIL';
export const SIGNUP_VALIDATE_SUBDOMAIN = 'SIGNUP_VALIDATE_SUBDOMAIN';
export const SIGNUP_SUBMIT_COUPON_CODE = 'SIGNUP_SUBMIT_COUPON_CODE';
export const SIGNUP_APPLY_COUPON_CODE = 'SIGNUP_APPLY_COUPON_CODE';
export const SIGNUP_SET_BILLING_COUNTRY = 'SIGNUP_SET_BILLING_COUNTRY';
export const SIGNUP_SET_BILLING_REGION = 'SIGNUP_SET_BILLING_REGION';
export const SIGNUP_SUBMIT_PAYMENT = 'SIGNUP_SUBMIT_PAYMENT';
export const SIGNUP_CREATE_SUBSCRIPTION = 'SIGNUP_CREATE_SUBSCRIPTION';

export interface SignupState {
  form: BillingForm;
  plan: Plan;
  planSet: boolean;
  addons: { [id: string]: Addon };
  detectedCountryISO?: string;
  currency: Currency;
  currencySet: boolean;
  interval: PaymentInterval;
  intervalSet: boolean;
  couponCode?: string | null;
  onboardingCoupon?: OnboardingCoupon | null;
  planCoupon?: PlanCoupon | null;
  amounts: PaymentAmounts;
  createAccountView: {
    formIsValid: boolean;
    errorMessage?: string | null;
    isLoading: boolean;
    emailInvalid: boolean;
    emailErrorMessage?: string | null;
    subdomainInvalid: boolean;
    subdomainErrorMessage?: string | null;
  };
  chooseAddonsView: {
    multiTenantUsers: number;
    enterpriseQuotes: number;
    isLoading: boolean;
  };
  couponView: {
    isLoading: boolean;
    couponCodeInvalid: boolean;
    errorMessage?: string | null;
  };
  paymentView: {
    isLoading: boolean;
    errorMessage?: string | null;
  };
  subscriptionID?: string | null;
  signupComplete: boolean;
}

export type SignupDetectCurrencyAction = AsyncAction<
  typeof SIGNUP_DETECT_CURRENCY,
  any
>;

export interface SignupSetPlanAction {
  type: typeof SIGNUP_SET_PLAN;
  payload: string | undefined;
}

export interface SignupSetIntervalAction {
  type: typeof SIGNUP_SET_INTERVAL;
  payload: string | undefined;
}

export interface SignupSetCurrencyAction {
  type: typeof SIGNUP_SET_CURRENCY;
  payload: string;
}

export type SignupSubmitAccountAction = AsyncAction<
  typeof SIGNUP_SUBMIT_ACCOUNT,
  IHttpResponse<IAPIResponse<BillingForm>>
>;

export interface SignupSelectAddonAction {
  type: typeof SIGNUP_SELECT_ADDON;
  payload: string;
}

export interface SignupRemoveAddonAction {
  type: typeof SIGNUP_REMOVE_ADDON;
  payload: string;
}

export type SignupSubmitAddonsAction = AsyncAction<
  typeof SIGNUP_SUBMIT_ADDONS,
  Addon[]
>;

export type SignupIncrementMultiTenantAction = {
  type: typeof SIGNUP_INCREMENT_MULTI_TENANT;
};

export type SignupDecrementMultiTenantAction = {
  type: typeof SIGNUP_DECREMENT_MULTI_TENANT;
};

export type SignupIncrementEnterpriseQuotesAction = {
  type: typeof SIGNUP_INCREMENT_ENTERPRISE_QUOTES;
};

export type SignupDecrementEnterpriseQuotesAction = {
  type: typeof SIGNUP_DECREMENT_ENTERPRISE_QUOTES;
};

export interface SignupSetBillingCountryAction {
  type: typeof SIGNUP_SET_BILLING_COUNTRY;
  payload: string;
}

export interface SignupSetBillingRegionAction {
  type: typeof SIGNUP_SET_BILLING_REGION;
  payload: string;
}

export interface SignupSubmitCouponCodeAction {
  type: typeof SIGNUP_SUBMIT_COUPON_CODE;
  payload: string;
}

export type SignupApplyCouponCodeAction = AsyncAction<
  typeof SIGNUP_APPLY_COUPON_CODE,
  IHttpResponse<IAPIResponse<IAPICouponValidation>>
>;

export type SignupValidateEmailAction = AsyncAction<
  typeof SIGNUP_VALIDATE_EMAIL,
  IHttpResponse<IAPIResponse<null>>
>;

export type SignupValidateSubdomainAction = AsyncAction<
  typeof SIGNUP_VALIDATE_SUBDOMAIN,
  IHttpResponse<IAPIResponse<null>>
>;

export interface SignupSubmitPaymentAction {
  type: typeof SIGNUP_SUBMIT_PAYMENT;
  payload: BillingForm;
}

export type SignupCreateSubscriptionAction = AsyncAction<
  typeof SIGNUP_CREATE_SUBSCRIPTION,
  IHttpResponse<IAPIResponse<IAPISignup>>
>;

export type SignupActionTypes =
  | SignupDetectCurrencyAction
  | SignupSetPlanAction
  | SignupSetIntervalAction
  | SignupSetCurrencyAction
  | SignupSubmitAccountAction
  | SignupSelectAddonAction
  | SignupRemoveAddonAction
  | SignupSubmitAddonsAction
  | SignupIncrementMultiTenantAction
  | SignupDecrementMultiTenantAction
  | SignupIncrementEnterpriseQuotesAction
  | SignupDecrementEnterpriseQuotesAction
  | SignupSubmitCouponCodeAction
  | SignupApplyCouponCodeAction
  | SignupValidateEmailAction
  | SignupValidateSubdomainAction
  | SignupSetBillingCountryAction
  | SignupSetBillingRegionAction
  | SignupSubmitPaymentAction
  | SignupCreateSubscriptionAction;
