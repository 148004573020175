import React from 'react';

interface Props {
  buttonText?: string;
  show?: boolean;
}

const Agreement = (props: Props) => {
  const buttonText = props.buttonText ? props.buttonText : 'Continue';

  return (
    <div className="text-muted small mt-3">
      By clicking "{buttonText}", you agree to Quoter’s{' '}
      <a
        href="https://www.quoter.com/terms-of-service"
        className="text-underline text-muted"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>{' '}
      and{' '}
      <a
        href="https://www.quoter.com/privacy"
        className="text-underline text-muted"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      .
    </div>
  );
};

export default Agreement;
