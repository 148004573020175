import React, { Fragment, useState } from 'react';
import CountUp from 'react-countup';
import { PaymentInterval } from 'store/payment';
import { Currency } from 'utils/countries';
import { Plan, PlanID } from 'store/plans/types';
import { pluralize } from 'utils/strings';
import { toMonthlyPrice } from 'store/plans/utility';
import Card from 'containers/Billing/components/Card';
import style from './Plan.module.scss';

interface Props {
  currency: Currency;
  plan: Plan;
  interval: PaymentInterval;
  isCurrentPlan: boolean;
  isLocked: boolean;
  onClick: (planId: PlanID) => void;
}

const PlanComponent = (props: Props) => {
  let monthlyPrice = toMonthlyPrice(
    props.interval,
    props.plan.pricing[props.interval].unitPriceInCents,
  );

  let [prevPrice] = useState<number>(monthlyPrice);
  let newPrice = monthlyPrice;

  const countUpProps = {
    start: prevPrice,
    end: newPrice,
    duration: 0.25,
    decimals: 0,
    decimal: props.currency.decimal,
    prefix: props.currency.before ? props.currency.before : undefined,
    suffix: props.currency.after ? props.currency.after : undefined,
    preserveValue: true,
  };

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onClick(props.plan.id);
  };

  const renderFooterButton = () => {
    if (props.isCurrentPlan) {
      return (
        <button className="btn btn-link" disabled>
          Current Plan
        </button>
      );
    } else if (props.isLocked) {
      return (
        <a
          className="btn btn-outline-primary px-3"
          href="mailto:support@quoter.com"
        >
          Contact Support
        </a>
      );
    }

    return (
      <button className="btn btn-primary px-3" onClick={handleOnClick}>
        Choose Plan
      </button>
    );
  };

  return (
    <Card
      className={`${style.root} mb-3 mb-lg-0`}
      footer={renderFooterButton()}
    >
      <h5>{props.plan.name}</h5>
      <div className={style.priceContainer}>
        <span className={style.priceDollar}>
          <CountUp {...countUpProps}>
            {({ countUpRef }) => <span ref={countUpRef}></span>}
          </CountUp>
        </span>
        <span className={style.pricePeriod}>/month</span>
      </div>
      <ul className={style.features}>
        {props.plan.unlimitedUsers ? (
          <li className={style.featureItem}>Unlimited Users</li>
        ) : (
          <li className={style.featureItem}>
            {props.plan.numUsersIncluded}{' '}
            {pluralize(props.plan.numUsersIncluded, 'User')}
          </li>
        )}
        {props.plan.unlimitedQuotes ? (
          <li className={style.featureItem}>Unlimited Quotes</li>
        ) : (
          <li className={style.featureItem}>
            {props.plan.numQuotesIncluded}{' '}
            {pluralize(props.plan.numQuotesIncluded, 'Quote')}/Month
          </li>
        )}
        <li className={style.featureItem}>Dedicated Support Specialist</li>
        <li className={style.featureItem}>Cost & Margin Tracking</li>
        <li className={style.featureItem}>Online Acceptance</li>
        <li className={style.featureItem}>Online Payments</li>
        <li className={style.featureItem}>PSA & CRM Integration</li>
        <li className={style.featureItem}>Distributor Integrations</li>
        <li className={style.featureItem}>Multi-factor Authentication</li>
        <li className={style.featureItem}>Quote Sections</li>
        <li className={style.featureItem}>Product Cloud (Etilize)</li>
        {/* uncomment this one once it's not in beta anymore */}
        {/* <li className={style.featureItem}>Line Item Price Modifiers</li> */}
        {props.plan.featuresIncluded['bundles'] && (
          <li className={style.featureItem}>Bundles</li>
        )}
        {props.plan.featuresIncluded['line-item-bulk-edit'] && (
          <li className={style.featureItem}>Line Item Bulk Edit</li>
        )}
        {props.plan.featuresIncluded['saml'] && (
          <li className={style.featureItem}>SAML SSO</li>
        )}
        {props.plan.featuresIncluded['reporting'] && (
          <li className={style.featureItem}>Reporting</li>
        )}
        {props.plan.featuresIncluded['manager-approvals'] && (
          <li className={style.featureItem}>Manager Approvals</li>
        )}
        {props.plan.featuresIncluded['avalara-tax-integration'] && (
          <li className={style.featureItem}>Avalara Integration</li>
        )}
        {props.plan.featuresIncluded['single-select-groups'] && (
          <li className={style.featureItem}>Single Select Groups</li>
        )}
        {props.plan.featuresIncluded['line-item-import'] && (
          <li className={style.featureItem}>Line Item Import</li>
        )}
        {props.plan.featuresIncluded['multi-tenant-users'] && (
          <li className={style.featureItem}>Multi-Tenant Support</li>
        )}
        {props.plan.featuresIncluded['enterprise-integrations'] && (
          <Fragment>
            <li className={style.featureItem}>Salesforce Integration</li>
            <li className={style.featureItem}>Hubspot Integration</li>
          </Fragment>
        )}
        {props.plan.featuresIncluded['quote-reminders'] && (
          <li className={style.featureItem}>Quote Reminders</li>
        )}
      </ul>
    </Card>
  );
};

export default PlanComponent;
