import React, { Fragment, useEffect } from 'react';
import { History } from 'history';
import { withRouter } from 'react-router-dom';

import ComponentSpinner from './ComponentSpinner';

const NoPlanRedirect = (props: { history: History }) => {
  const { history } = props;

  useEffect(() => {
    history.push('/plans');
    // setTimeout(() => {
    //   history.push('/plans');
    // }, REDIRECT_DELAY_MS);
  }, [history]);

  return (
    <Fragment>
      <ComponentSpinner />
      <h5 className="text-center mt-4">No plan selected</h5>
      <div className="text-muted text-center">Redirecting...</div>
    </Fragment>
  );
};

export default withRouter(NoPlanRedirect);
