import React from 'react';

import style from './Layout.module.scss';

const LayoutSpinner = () => (
  <div className={`${style.authLayout} fadein`}>
    <div className={style.authCenter}>
      <span className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </span>
    </div>
  </div>
);

export default LayoutSpinner;
