import { CustomerActions, CustomerTypes } from 'store/customer/types';
import { AsyncActionStatus } from 'store/asyncActions';
import { getAPIErrors, apiErrorMessage } from '../api';

import {
  PAYMENT_METHOD_FORM_SUCCESS,
  PAYMENT_METHOD_FORM_ERROR,
} from 'store/constants';

import {
  PaymentMethodForm,
  PaymentMethodFormTypes,
  PaymentMethodFormActions,
} from './types';

const initialState: PaymentMethodForm = {};

export function paymentMethodForm(
  state: PaymentMethodForm = initialState,
  action: PaymentMethodFormTypes | CustomerTypes,
): PaymentMethodForm {
  let newState: PaymentMethodForm;

  switch (action.type) {
    case CustomerActions.POST_CREDIT_CARD:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        return {
          successMessage: PAYMENT_METHOD_FORM_SUCCESS,
        };
      } else if (action.status === AsyncActionStatus.FAILED) {
        let apiErrors = getAPIErrors(action.error);
        if (apiErrors) {
          return {
            errorMessage: apiErrorMessage(apiErrors),
          };
        }
        return {
          errorMessage: PAYMENT_METHOD_FORM_ERROR,
        };
      } else {
        newState = state;
      }

      break;
    case PaymentMethodFormActions.CLEAR_MESSAGES:
      newState = {
        errorMessage: '',
        successMessage: '',
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
}
