import React, { Fragment } from 'react';

import style from './Card.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  footer?: React.ReactNode;
  removePadding?: boolean;
  title?: string;
}

const Card = (props: Props) => {
  const CardTitle = () => {
    if (props.title) {
      return <div className={style.title}>{props.title}</div>;
    }

    return null;
  };

  const CardFooter = () => {
    if (props.footer) {
      return <div className={style.customFooter}>{props.footer}</div>;
    }

    return null;
  };

  const paddingClassName = props.removePadding ? 'p-0' : '';

  return (
    <Fragment>
      <CardTitle />
      <div className={`card ${props.className}`}>
        <div className={`card-body ${paddingClassName}`}>{props.children}</div>
        <CardFooter />
      </div>
    </Fragment>
  );
};

export default Card;
