import React, { useEffect, useRef, useState, Fragment } from 'react';
import classNames from 'classnames';
import validate from 'utils/validate';
import useForm, { Form } from 'hooks/useForm';
import { OnboardingCoupon, PlanCoupon } from 'store/payment';

interface Props {
  onApply: (couponCode: string) => void;
  isLoading: boolean;
  isInvalid: boolean;
  errorMessage?: string | null;
  appliedCouponCode?: string | null;
  appliedOnboardingCoupon?: OnboardingCoupon | null;
  appliedPlanCoupon?: PlanCoupon | null;
}

const CouponCode = (props: Props) => {
  const {
    onApply,
    isLoading,
    isInvalid,
    errorMessage,
    appliedCouponCode,
    appliedOnboardingCoupon,
    appliedPlanCoupon,
  } = props;

  const couponCodeRef = useRef<HTMLInputElement>(null);

  const [inputShowing, setInputShowing] = useState(isInvalid === true);

  const handeToggleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setInputShowing(inputShowing => !inputShowing);
  };

  // Focus the coupon code field when inputShowing is changed
  useEffect(() => {
    if (inputShowing && couponCodeRef.current) {
      couponCodeRef.current.focus();
    }
  }, [inputShowing, couponCodeRef]);

  // Hide the input field after the coupon is applied
  useEffect(() => {
    if (appliedOnboardingCoupon || appliedPlanCoupon) {
      setInputShowing(false);
    }
  }, [appliedOnboardingCoupon, appliedPlanCoupon, setInputShowing]);

  const initialFormState: Form = {
    formControls: {
      couponCode: {
        value: appliedCouponCode ? appliedCouponCode : '',
        validationRules: {
          isRequired: true,
          maxLength: 50,
        },
      },
    },
  };

  const onFormSubmit = () => {
    if (form.formControls.couponCode.value) {
      onApply(form.formControls.couponCode.value);
    }
  };

  const { form, setForm, handleFormChange, handleFormSubmit } = useForm(
    initialFormState,
    validate,
    onFormSubmit,
  );

  // Set the form value if appliedCouponCode is changed externally (e.g. via URL)
  useEffect(() => {
    if (appliedCouponCode && form.formControls.couponCode.value === '') {
      setForm((previousForm: Form) => ({
        ...previousForm,
        formControls: {
          ...form.formControls,
          couponCode: {
            ...form.formControls.couponCode,
            value: appliedCouponCode,
          },
        },
      }));
      setInputShowing(true);
    }
  }, [appliedCouponCode, setForm, form]);

  let appliedCouponName: string = '';
  if (appliedCouponCode) {
    if (appliedOnboardingCoupon && appliedPlanCoupon)
      appliedCouponName = `${appliedOnboardingCoupon.name} + ${appliedPlanCoupon.name}`;
    else if (appliedOnboardingCoupon)
      appliedCouponName = appliedOnboardingCoupon.name;
    else if (appliedPlanCoupon) appliedCouponName = appliedPlanCoupon.name;
  }

  return (
    <Fragment>
      {(appliedOnboardingCoupon || appliedPlanCoupon) && !inputShowing && (
        <span className="text-muted small">
          <span className="font-italic">"{appliedCouponName}" &nbsp;</span>
          coupon applied. &nbsp;
          <a href="/payment" onClick={handeToggleButtonClick}>
            Edit
          </a>
        </span>
      )}
      <form
        className={classNames('form-row mb-2', {
          'd-none': !inputShowing,
        })}
        onSubmit={handleFormSubmit}
      >
        <div className="col">
          <label htmlFor="couponCode" className="sr-only">
            Coupon Code
          </label>
          <input
            id="couponCode"
            ref={couponCodeRef}
            className={classNames('form-control form-control-sm', {
              'is-invalid':
                isInvalid === true ||
                form.formControls.couponCode.isValid === false,
            })}
            type="text"
            placeholder="Coupon code"
            onChange={handleFormChange}
            value={form.formControls.couponCode.value}
          />
          {isInvalid === true && errorMessage && (
            <div className="invalid-feedback">{errorMessage}</div>
          )}
          {isInvalid === true && !errorMessage && (
            <div className="invalid-feedback">Coupon is not valid.</div>
          )}
        </div>
        <div className="col-auto">
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            disabled={isLoading || !form.formControls.couponCode.value}
            onSubmit={handleFormSubmit}
          >
            {isLoading ? 'Loading...' : 'Apply'}
          </button>
        </div>
      </form>
      {((!appliedOnboardingCoupon && !appliedPlanCoupon) || inputShowing) && (
        <a
          href="/payment"
          className="text-muted text-underline"
          onClick={handeToggleButtonClick}
        >
          {inputShowing ? 'Cancel' : 'Apply coupon code'}
        </a>
      )}
    </Fragment>
  );
};

export default CouponCode;
