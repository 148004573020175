import React from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';

interface ProtectedRouteProps extends RouteProps {
  allowAccess: boolean;
  deniedAccessRedirectPath: string;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const {
    component: Component,
    allowAccess,
    deniedAccessRedirectPath,
    ...rest
  } = props;

  let render;
  if (!allowAccess) {
    render = (routeProps: RouteComponentProps<any>) => (
      <Redirect
        to={{
          pathname: deniedAccessRedirectPath,
          state: { from: routeProps.location },
        }}
      />
    );
  } else {
    if (Component) {
      render = (routeProps: RouteComponentProps<any>) => (
        <Component {...routeProps} />
      );
    } else if (props.render) {
      render = props.render;
    } else {
      throw new Error('component or render prop is required');
    }
  }

  return <Route {...rest} render={render} />;
};

export default ProtectedRoute;
