import { connect } from 'react-redux';
import { AppState } from 'store';

import Invoices from './Invoices';

const mapStateToProps = (state: AppState) => ({
  invoices: state.invoices.allInvoices,
  invoicesLoading: state.loading.invoices,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
