import { Invoice, InvoicesTypes, InvoicesActions } from './types';
import { AsyncActionStatus } from 'store/asyncActions';
import { getAPIResource } from 'store/api';

const initialState: Invoice[] = [];

export function allInvoices(
  state: Invoice[] = initialState,
  action: InvoicesTypes,
): Invoice[] {
  let newState: Invoice[];

  switch (action.type) {
    case InvoicesActions.GET_INVOICES:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        const apiInvoices = getAPIResource(action.payload);

        if (apiInvoices) {
          return apiInvoices.map(invoice => ({
            id: invoice.id,
            pdfLink: invoice.pdf_link,
            amountDueInCents: invoice.amount_due_in_cents,
            amountPaidInCents: invoice.amount_paid_in_cents,
            subtotalInCents: invoice.subtotal_in_cents,
            taxInCents: invoice.tax_in_cents,
            totalInCents: invoice.total_in_cents,
            periodEnd: invoice.period_end,
            currency: invoice.currency,
            number: invoice.number,
            paid: invoice.paid,
            created: invoice.created,
          }));
        }

        newState = state;
      } else {
        newState = state;
      }
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
