import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { STRIPE_PUSHABLE_KEY } from 'store/stripe';

export default function withStripe<P extends object>(
  WrappedComponent: React.ComponentType<P>,
) {
  return class extends React.Component<P> {
    render() {
      return (
        <StripeProvider apiKey={STRIPE_PUSHABLE_KEY}>
          <Elements
            fonts={[
              {
                cssSrc:
                  'https://fonts.googleapis.com/css?family=Lato:400,400i,700',
              },
            ]}
          >
            <WrappedComponent {...this.props} />
          </Elements>
        </StripeProvider>
      );
    }
  };
}
