import { useEffect, useState } from 'react';

export interface Form {
  isValid?: boolean;
  formControls: FormControls;
}

export interface FormControls {
  [key: string]: FormControl;
}

export interface FormControl {
  value?: string;
  validationRules?: {
    [key: string]: any;
  };
  isTouched?: boolean;
  isDirty?: boolean;
  isValid?: boolean;
}

export interface ValidateFunc {
  (rule: string, value: any): boolean;
}

const useForm = (
  initialFormState: Form,
  validate: ValidateFunc,
  onSubmit: React.FormEventHandler<HTMLFormElement>,
) => {
  const [form, setForm] = useState(initialFormState);

  const handleFormSubmit = (event: React.FormEvent<any>) => {
    if (event) event.preventDefault();
    if (form.isValid) onSubmit(event);
  };

  const handleFormChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    event.persist();

    let formControlIsValid = validate(
      event.target.value,
      form.formControls[event.target.id].validationRules,
    );

    let newFormControlsState = {
      ...form.formControls,
      [event.target.id]: {
        ...form.formControls[event.target.id],
        value: event.target.value,
        isTouched: true,
        isValid: formControlIsValid,
      },
    };

    setForm((previousForm: Form) => ({
      ...previousForm,
      formControls: newFormControlsState,
    }));
  };

  // Validate the form as a "side effect" of the form state changing. This allows us to re-validate
  // in the even the form state is changed outside of handleFormChange, e.g. via setForm.
  useEffect(() => {
    let formIsValid = true;
    for (let formControlKey in form.formControls) {
      formIsValid =
        form.formControls[formControlKey].isValid === true &&
        formIsValid === true;
    }

    setForm((previousForm: Form) => ({
      ...previousForm,
      isValid: formIsValid,
    }));
  }, [form.formControls]);

  return {
    form,
    setForm,
    handleFormChange,
    handleFormSubmit,
  };
};

export default useForm;
