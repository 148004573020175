import createCachedSelector from 're-reselect';
import { AppState } from 'store';
import { Plan, Plans, PlanID } from 'store/plans/types';

const getPlans = (state: AppState): Plans => state.plans.plans;

const getPlanId = (state: AppState): PlanID | undefined =>
  state.paymentWizard.planId;

const selectPlanById = (
  plans: Plans,
  planId: PlanID | undefined,
): Plan | undefined => {
  if (planId) {
    return plans[planId];
  }

  return undefined;
};

export default createCachedSelector(
  getPlans,
  getPlanId,
  selectPlanById,
)(state => 'planById');
