import { IAPIPriceInterval } from 'store/api';
import {
  PaymentInterval,
  OnboardingCoupon,
  PlanCoupon,
  PaymentAmounts,
} from 'store/payment';
import { OnboardingAmount } from 'store/signup/plans';
import { SubscriptionPlan, SubscriptionAddon } from 'store/subscription/types';

import {
  ENTERPRISE_QUOTES_MAX,
  ENTERPRISE_QUOTES_MIN,
  MULTI_TENTANT_MAX_USERS,
  MULTI_TENTANT_MIN_USERS,
} from 'store/constants';

import {
  PlanID,
  AddonID,
  PriceInterval,
  PriceTier,
  Addons,
  Plan,
  Addon,
} from './types';

export const convertAPIPlanId = (apiPlanId: string): PlanID => {
  let planId: PlanID;

  switch (apiPlanId) {
    case 'basic':
      planId = PlanID.BASIC;
      break;
    case 'standard':
      planId = PlanID.STANDARD;
      break;
    case 'pro':
      planId = PlanID.PRO;
      break;
    case 'enterprise':
      planId = PlanID.ENTERPRISE;
      break;
    case 'custom':
      planId = PlanID.CUSTOM;
      break;
    default:
      throw new Error('Cannot convert API Plan Id to type PlanId.');
  }

  return planId;
};

export const convertAPIAddonId = (apiAddonId: string): AddonID => {
  let addonId: AddonID | null;

  switch (apiAddonId) {
    case 'avalara-tax-integration':
      addonId = AddonID.AVALARA_TAX_INTEGRATION;
      break;
    case 'bundles':
      addonId = AddonID.BUNDLES;
      break;
    case 'contracts':
      addonId = AddonID.CONTRACTS;
      break;
    case 'enterprise-integrations':
      addonId = AddonID.ENTERPRISE_INTEGRATIONS;
      break;
    case 'enterprise-quotes':
      addonId = AddonID.ENTERPRISE_QUOTES;
      break;
    case 'line-item-import':
      addonId = AddonID.LINE_ITEM_IMPORT;
      break;
    case 'manager-approvals':
      addonId = AddonID.MANAGER_APPROVALS;
      break;
    case 'multi-tenant-users':
      addonId = AddonID.MULTI_TENANT_USERS;
      break;
    case 'quote-reminders':
      addonId = AddonID.QUOTE_REMINDERS;
      break;
    case 'reporting':
      addonId = AddonID.REPORTING;
      break;
    case 'saml':
      addonId = AddonID.SAML;
      break;
    case 'single-select-groups':
      addonId = AddonID.SINGLE_SELECT_GROUPS;
      break;
    default:
      throw new Error('Cannot convert API AddonID to type AddonId.');
  }

  return addonId;
};

export const convertAPIPaymentInterval = (
  apiInterval: string,
): PaymentInterval => {
  let interval: PaymentInterval;

  switch (apiInterval) {
    case 'monthly':
      interval = PaymentInterval.MONTHLY;
      break;
    case 'yearly':
      interval = PaymentInterval.YEARLY;
      break;
    default:
      throw new Error(
        'Cannot convert API Payment Interval to type PaymentInterval.',
      );
  }

  return interval;
};

export const convertAPIPricingInterval = (apiPricing: {
  [id: string]: IAPIPriceInterval;
}): { [id: string]: PriceInterval } => {
  let pricing: { [id: string]: PriceInterval } = {};

  for (const key in apiPricing) {
    const price = apiPricing[key];
    const priceTiers: PriceTier[] = price.price_tiers.map(tier => ({
      upToQuantity: tier.up_to_quantity,
      upToInfinity: tier.up_to_infinity,
      flatPrice: tier.flat_price,
      unitPrice: tier.unit_price,
    }));

    pricing[key] = {
      unitPriceInCents: price.unit_price_in_cents,
      priceTiers: priceTiers,
    };
  }

  return pricing;
};

export const getIsCurrentPlan = (
  plan: Plan,
  selectedPlan?: Plan,
  subscriptionPlan?: SubscriptionPlan,
): boolean => {
  let isCurrentPlan = false;

  if (selectedPlan) {
    isCurrentPlan = selectedPlan.id === plan.id;
  } else if (subscriptionPlan) {
    if (subscriptionPlan.version === 'v2') {
      isCurrentPlan = subscriptionPlan.id === plan.id;
    } else {
      isCurrentPlan = false;
    }
  }

  return isCurrentPlan;
};

export const getIsPlanLocked = (
  plan: Plan,
  subscriptionPlan?: SubscriptionPlan,
  subscriptionInterval?: PaymentInterval,
) => {
  if (!subscriptionPlan || !subscriptionInterval) {
    return true;
  }

  if (subscriptionPlan.id === plan.id) {
    return false;
  }

  const planIsFlatOrUpgrade = isPlanFlatOrUpgrade(subscriptionPlan.id, plan.id);
  const planPrice = plan.pricing[subscriptionInterval].unitPriceInCents;
  // if the plan is flat or upgrade we need to check the current price of the new sub.
  if (planIsFlatOrUpgrade) {
    // if that current price is the same, lock it. nothing has changed
    if (planPrice === subscriptionPlan.priceInCents) {
      return true;
    }
    // otherwise the plan is the same or an upgrade and
    // the price has changed,  ie: from the interval being changed from
    // monthly to yearly, so its unlocked.
    return false;
  } else {
    if (plan.id === subscriptionPlan.id) {
      // if the plan is not flat or upgraded, we need to check the current price.
      return planPrice < subscriptionPlan.priceInCents;
    } else {
      return true;
    }
  }
};

export const isPlanFlatOrUpgrade = (
  currentPlanID: string,
  newPlanID: string,
): boolean => {
  switch (currentPlanID) {
    case PlanID.BASIC:
      return true;

    case PlanID.STANDARD:
      switch (newPlanID) {
        case PlanID.BASIC:
          return false;
        default:
          return true;
      }

    case PlanID.PRO:
      switch (newPlanID) {
        case PlanID.BASIC:
          return false;
        case PlanID.STANDARD:
          return false;
        default:
          return true;
      }

    case PlanID.ENTERPRISE:
      if (newPlanID === PlanID.ENTERPRISE) {
        return true;
      }
      return false;

    default:
      return false;
  }
};

export const noPlanSelected = (
  selectedPlan?: Plan,
  subscriptionPlan?: SubscriptionPlan,
): subscriptionPlan is SubscriptionPlan => {
  return !selectedPlan && typeof subscriptionPlan !== 'undefined';
};

export const getAddonIcon = (addonId: string) => {
  switch (addonId) {
    case AddonID.AVALARA_TAX_INTEGRATION:
      return 'percent';
    case AddonID.BUNDLES:
      return 'box';
    case AddonID.CONTRACTS:
      return 'file-contract';
    case AddonID.ENTERPRISE_INTEGRATIONS:
      return 'bolt';
    case AddonID.ENTERPRISE_QUOTES:
      return 'plus';
    case AddonID.LINE_ITEM_IMPORT:
      return 'file-import';
    case AddonID.MANAGER_APPROVALS:
      return 'gavel';
    case AddonID.MULTI_TENANT_USERS:
      return 'user-friends';
    case AddonID.QUOTE_REMINDERS:
      return 'ribbon';
    case AddonID.REPORTING:
      return 'chart-pie';
    case AddonID.SAML:
      return 'lock';
    case AddonID.SINGLE_SELECT_GROUPS:
      return 'check-circle';
    default:
      return 'plus-circle';
  }
};

export const isAddonSelected = (
  addons: Addons | undefined,
  addonId: string,
): boolean => {
  if (!addons) {
    return false;
  }

  return Object.keys(addons).includes(addonId);
};

export const isSubscriptionAddonSelected = (
  addons: SubscriptionAddon[] | undefined,
  addonId: string,
): boolean => {
  if (!addons) {
    return false;
  }

  for (let i = 0; i < addons.length; i++) {
    if (addons[i].id === addonId) {
      return true;
    }
  }

  return false;
};

export const anyAddonsSelected = (
  selectedAddons: Addons | undefined,
  subscriptionAddons?: SubscriptionAddon[],
): boolean => {
  if (!selectedAddons) {
    return false;
  }

  const selectedAddonIds = Object.keys(selectedAddons);
  const subscriptionAddonIds = subscriptionAddons?.map(
    subscriptionAddon => subscriptionAddon.id,
  );

  // Check that new addons were selected
  if (subscriptionAddonIds) {
    return !selectedAddonIds.every(x => subscriptionAddonIds.includes(x));
  }

  if (selectedAddonIds.length) {
    return true;
  }

  return false;
};

export function isValidMultiTenantUserCount(users: number): boolean {
  return (
    users >= MULTI_TENTANT_MIN_USERS &&
    users <= MULTI_TENTANT_MAX_USERS &&
    users % 10 === 0
  );
}

export function isValidEnterpriseQuotesCount(users: number): boolean {
  return (
    users >= ENTERPRISE_QUOTES_MIN &&
    users <= ENTERPRISE_QUOTES_MAX &&
    users % 50 === 0
  );
}

export const getPriceInterval = (
  interval: PaymentInterval,
  plan: Plan | Addon,
) => {
  return interval === PaymentInterval.YEARLY
    ? plan.pricing[PaymentInterval.YEARLY]
    : plan.pricing[PaymentInterval.MONTHLY];
};

export const toMonthlyPrice = (
  interval: PaymentInterval,
  priceInCents: number,
) => {
  return interval === PaymentInterval.YEARLY
    ? priceInCents / 12 / 100
    : priceInCents / 100;
};

export const calcMonthlyPrice = (
  interval: PaymentInterval,
  plan: Plan | Addon,
  count?: number | null,
): number => {
  const price = getPriceInterval(interval, plan);
  let divider = 10;

  if (plan.id === AddonID.ENTERPRISE_QUOTES) {
    divider = 50;
  }

  if (price.priceTiers?.length && count) {
    return toMonthlyPrice(
      interval,
      calculateTieredSteppedPriceInCents(count, price.priceTiers) / divider,
    );
  }

  return toMonthlyPrice(interval, price.unitPriceInCents);
};

// Iterates over each quantity, accumulating its tier's flat price.
export const calculateTieredSteppedPriceInCents = (
  quantity: number,
  priceTiers: PriceTier[],
): number => {
  let priceInCents = 0;
  let quantityAccountedFor = 1;

  while (quantityAccountedFor <= quantity) {
    let tier = priceTiers.find(findPriceTier(quantityAccountedFor));

    if (tier) {
      quantityAccountedFor = ++quantityAccountedFor;
      priceInCents = priceInCents + tier.flatPrice;
    } else {
      console.error('Could not calculate Multi Tenant price', priceTiers);
      break;
    }
  }
  return priceInCents;
};

export const findPriceTier = (quantity: number) => (priceTier: PriceTier) => {
  if (quantity <= priceTier.upToQuantity) {
    return priceTier;
  }

  if (priceTier.upToInfinity) {
    return priceTier;
  }

  return false;
};

export function calculatePaymentAmounts(
  plan: Plan,
  addons: Addon[] | undefined | null,
  onboardingCoupon: OnboardingCoupon | undefined | null,
  planCoupon: PlanCoupon | undefined | null,
  interval: PaymentInterval,
  countryISO: string | undefined | null,
  regionISO: string | undefined | null,
  quantity?: number,
): PaymentAmounts {
  let planRecurring =
    interval === PaymentInterval.YEARLY
      ? plan.pricing[PaymentInterval.YEARLY].unitPriceInCents / 100
      : plan.pricing[PaymentInterval.MONTHLY].unitPriceInCents / 100;

  let addonRecurringPrices = (addons || []).map(addon => {
    const addonPricing = addon.pricing[interval];

    let divider = 10;

    if (addon.id === AddonID.ENTERPRISE_QUOTES) {
      divider = 50;
    }

    if (addonPricing.priceTiers && quantity) {
      const tieredPriceInCents =
        calculateTieredSteppedPriceInCents(quantity, addonPricing.priceTiers) /
        divider;

      const monthlyPrice = toMonthlyPrice(interval, tieredPriceInCents);

      return interval === PaymentInterval.YEARLY
        ? monthlyPrice * 12
        : monthlyPrice;
    }

    return addonPricing.unitPriceInCents / 100;
  });
  let addonRecurring = addonRecurringPrices.reduce(
    (total, price) => total + price,
    0,
  );
  let onboardingAmount = OnboardingAmount;
  let recurring = planRecurring + addonRecurring;
  let onboardingDiscount = onboardingCoupon
    ? calculateOnboardingDiscount(onboardingCoupon, onboardingAmount)
    : 0;
  let planDiscount = planCoupon
    ? calculatePlanDiscount(planCoupon, recurring)
    : 0;

  let subtotal =
    OnboardingAmount + recurring - onboardingDiscount - planDiscount;
  if (subtotal < 0) subtotal = 0;
  // See: https://canadabusiness.ca/government/taxes-gst-hst/federal-tax-information/overview-of-charging-and-collecting-sales-tax/
  let hst = 0,
    gst = 0,
    pst = 0;
  if (countryISO === 'CA') {
    if (regionISO && ['NB', 'NL', 'NS', 'PEI'].includes(regionISO)) {
      hst = Number((subtotal * 0.15).toFixed(2));
    } else if (regionISO === 'ON') {
      hst = Number((subtotal * 0.13).toFixed(2));
    } else {
      gst = Number((subtotal * 0.05).toFixed(2));
      if (regionISO === 'BC') {
        pst = Number((subtotal * 0.07).toFixed(2));
      }
    }
  }
  let total = subtotal + hst + gst + pst;

  return {
    onboarding: onboardingAmount,
    recurring: recurring,
    onboardingDiscount: onboardingDiscount,
    planDiscount: planDiscount,
    subtotal: subtotal,
    hst: hst,
    gst: gst,
    pst: pst,
    total: total,
  };
}

export function calculateOnboardingDiscount(
  coupon: OnboardingCoupon,
  onboardingAmount: number,
): number {
  let discount = 0;

  if (coupon.percentageOff) {
    discount = onboardingAmount * (coupon.percentageOff / 100);
  } else if (coupon.amountOffInCents) {
    discount = coupon.amountOffInCents / 100;
  }

  return discount;
}

export function calculatePlanDiscount(
  coupon: PlanCoupon,
  recurring: number,
): number {
  let discount = 0;

  if (coupon.percentageOff) {
    discount = recurring * (coupon.percentageOff / 100);
  } else if (coupon.amountOffInCents) {
    discount = coupon.amountOffInCents / 100;
  }

  return discount;
}
