import createCachedSelector from 're-reselect';
import { AppState } from 'store';
import { PlanID, Addons, AddonID, Plans } from 'store/plans/types';
import { convertAPIAddonId } from 'store/plans/utility';

const getPlans = (state: AppState): Plans => state.plans.plans;

const getAddons = (state: AppState): Addons => state.plans.addons;

const getSelectedAddons = (state: AppState): AddonID[] =>
  state.paymentWizard.addons;

const getSelectedPlanId = (state: AppState): PlanID | undefined =>
  state.paymentWizard.planId;

const selectPlansAddons = (
  plans: Plans,
  addons: Addons,
  selectedAddons: AddonID[],
  planId: PlanID | undefined,
): Addons | undefined => {
  if (planId && Object.keys(plans).length) {
    let addonsWithData: Addons = {};
    let addonIds = [...selectedAddons];

    // We want to show the subscription's addons and allow them to be
    // added and removed, but only if they're available for that addon.
    // So in the case of Basic + SAML -> Pro, don't show the SAML addon.
    const availableAddons = Object.keys(
      plans[planId].availableAddons,
    ).map(addonId => convertAPIAddonId(addonId));

    const filteredAddonIds = addonIds.filter(addonId =>
      availableAddons.includes(addonId),
    );

    for (let addonId of filteredAddonIds) {
      addonsWithData = {
        ...addonsWithData,
        [addonId]: { ...addons[addonId] },
      };
    }

    return addonsWithData;
  }

  return undefined;
};

export default createCachedSelector(
  getPlans,
  getAddons,
  getSelectedAddons,
  getSelectedPlanId,
  selectPlansAddons,
)(state => 'plansAddons');
