import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Customer } from 'store/customer/types';
import { history } from 'store/index';

import {
  Subscription,
  SubscriptionStatusTypes,
} from 'store/subscription/types';

import Addons from '../Addons';
import Billing from '../Billing';
import CancelledAccount from './CancelledAccount';
import ChoosePlan from '../ChoosePlan';
import Icon from 'components/Icon';
import Invoices from '../Invoices';
import LayoutBanner from './LayoutBanner';
import LayoutError from './LayoutError';
import LayoutSpinner from './LayoutSpinner';
import Navigation from '../../components/Navigation';
import Overview from '../Overview';
import PayAmountOwing from '../PayAmountOwing';
import Payment from '../Payment';
import UpdateBillingAddress from '../UpdateBillingAddress';
import UpdatePaymentMethod from '../UpdatePaymentMethod';

import logo from 'assets/logo.svg';
import style from './Layout.module.scss';

interface ConnectedProps {
  customer: Customer;
  subscription: Subscription;
  getCustomer: Function;
  getPlans: Function;
  getSubscription: Function;
  getInvoices: Function;
  getUpcomingInvoice: Function;
}

const Layout = ({
  customer,
  subscription,
  getCustomer,
  getPlans,
  getSubscription,
  getInvoices,
  getUpcomingInvoice,
}: ConnectedProps) => {
  const [isRenderable, renderableState] = useState<boolean>(false);

  useEffect(() => {
    async function getRenderData() {
      await getCustomer();
      await getSubscription();

      getUpcomingInvoice();
      getInvoices();
      getPlans();

      renderableState(true);
    }

    getRenderData();
  }, [getCustomer, getSubscription, getInvoices, getUpcomingInvoice, getPlans]);

  const [isSidebarOpen, openSideBar] = useState(false);

  const handleClickSidebarIcon = () => {
    openSideBar(!isSidebarOpen);
  };

  const handleClickOutside = () => {
    openSideBar(false);
  };

  if (isRenderable === false) {
    return <LayoutSpinner />;
  }

  if (!customer.id) {
    return <LayoutError />;
  }

  return (
    <ConnectedRouter history={history}>
      <LayoutBanner />
      <div className="px-3 px-md-5 fadein">
        <div className={`row ${style.headerRow}`}>
          <Icon
            name="bars"
            className={style.sidebarButton}
            onClick={handleClickSidebarIcon}
          />
          <img src={logo} alt="Quoter Software Inc." width="auto" height="38" />
        </div>
        <div className="row">
          <OutsideClickHandler onOutsideClick={handleClickOutside}>
            <div
              className={cx(style.sidebar, {
                [style.sidebarOpen]: isSidebarOpen,
              })}
            >
              <Icon
                name="bars"
                className={style.sidebarButtonOpen}
                onClick={handleClickSidebarIcon}
              />
              <h5 className={style.sidebarTitle}>{customer.company}</h5>
              <div className={style.sidebarNavigation}>
                <Navigation />
              </div>
            </div>
          </OutsideClickHandler>
          <div className="col">
            <div className="container-fluid mb-5">
              {subscription.status === SubscriptionStatusTypes.cancelled ? (
                <CancelledAccount subscription={subscription} />
              ) : (
                <Switch>
                  <Route path="/" component={Overview} exact />
                  <Route path="/plans" component={ChoosePlan} exact />
                  <Route path="/plans/addons" component={Addons} exact />
                  <Route path="/plans/payment" component={Payment} exact />
                  <Route path="/billing" component={Billing} exact />
                  <Route
                    path="/billing/update-payment-method"
                    component={UpdatePaymentMethod}
                    exact
                  />
                  <Route
                    path="/billing/update-billing-address"
                    component={UpdateBillingAddress}
                    exact
                  />
                  <Route path="/invoices" component={Invoices} exact />
                  <Route
                    path="/pay-amount-owing"
                    component={PayAmountOwing}
                    exact
                  />
                </Switch>
              )}
            </div>
          </div>
        </div>
      </div>
    </ConnectedRouter>
  );
};

export default Layout;
