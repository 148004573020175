import React, { forwardRef } from 'react';
import classNames from 'classnames';

type Props = {
  id: string;
  value: string;
  label?: string;
  helperText?: React.ReactElement | string;
  isValid?: boolean;
  validFeedback?: string;
  isInvalid?: boolean;
  invalidFeedback?: string;
  formGroupClassName?: string;
  inputClassName?: string;
  onChange?: React.ChangeEventHandler;
  children?: React.ReactNode;
  disabled?: boolean;
};

export type Ref = HTMLSelectElement;

const Select = forwardRef<Ref, Props>((props, ref) => {
  const {
    id,
    value,
    label,
    helperText,
    isValid,
    validFeedback,
    isInvalid,
    invalidFeedback,
    formGroupClassName,
    inputClassName,
    onChange,
    children,
    disabled,
  } = props;

  return (
    <div className={classNames('form-group', formGroupClassName)}>
      {label && <label htmlFor={id}>{label}</label>}
      <select
        id={id}
        ref={ref}
        className={classNames('custom-select', inputClassName, {
          'is-valid': isValid,
          'is-invalid': isInvalid,
        })}
        aria-describedby={`${id}Help`}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {children}
      </select>
      {isValid && validFeedback && (
        <div className="valid-feedback">{validFeedback}</div>
      )}
      {isInvalid && invalidFeedback && (
        <div className="invalid-feedback">{invalidFeedback}</div>
      )}
      {helperText && (
        <small id={`${id}Help`} className="form-text text-muted">
          {helperText}
        </small>
      )}
    </div>
  );
});

export default Select;
