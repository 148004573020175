import style from './ChoosePlan.module.scss';

import React, { Fragment } from 'react';
import { PaymentInterval } from 'store/payment';
import { Plans, PlanID, Plan as PlanType } from 'store/plans/types';
import { AsyncActionStatus, asyncActionStatusBool } from 'store/asyncActions';
import { SubscriptionPlan } from 'store/subscription/types';
import { Currency } from 'utils/countries';

import {
  getIsCurrentPlan,
  getIsPlanLocked,
  noPlanSelected,
} from 'store/plans/utility';

import Plan from './Plan';
import Wizard from '../../components/Wizard';
import IntervalSwitch from './IntervalSwitch';
import ComponentSpinner from 'containers/Billing/components/ComponentSpinner';

interface ConnectedProps {
  continueToAddons: () => void;
  currency: Currency;
  isPaymentDisabled: boolean;
  plans: Plans;
  plansLoading: AsyncActionStatus;
  selectedPlan: PlanType | undefined;
  selectedPlanInterval: PaymentInterval;
  setInterval: (interval: PaymentInterval) => void;
  setPlanId: (planId: PlanID) => void;
  subscriptionLoading: AsyncActionStatus;
  subscriptionPlan: SubscriptionPlan | undefined;
}

const ChoosePlan = (props: ConnectedProps) => {
  const selectablePlans = Object.values(PlanID).filter(
    planId => planId !== PlanID.CUSTOM,
  );
  const hasPlans = Object.values(props.plans).length > 0;

  const isLoading =
    asyncActionStatusBool(props.plansLoading) ||
    asyncActionStatusBool(props.subscriptionLoading);

  const handleContinueToAddons = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (noPlanSelected(props.selectedPlan, props.subscriptionPlan)) {
      props.setPlanId(props.subscriptionPlan.id);
    }

    props.continueToAddons();
  };

  let subView;

  if (isLoading) {
    subView = <ComponentSpinner />;
  } else if (hasPlans === false) {
    subView = (
      <div className="text-muted text-center mt-5">
        Plans could not be loaded. Please contact support@quoter.com
      </div>
    );
  } else {
    subView = (
      <>
        <div className={style.plans}>
          {selectablePlans.map(planId => (
            <div key={planId} className={style.plan}>
              <Plan
                interval={props.selectedPlanInterval}
                plan={props.plans[planId]}
                onClick={props.setPlanId}
                isCurrentPlan={getIsCurrentPlan(
                  props.plans[planId],
                  props.selectedPlan,
                  props.subscriptionPlan,
                )}
                isLocked={getIsPlanLocked(
                  props.plans[planId],
                  props.subscriptionPlan,
                  props.selectedPlanInterval,
                )}
                currency={props.currency}
              />
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-12 px-2">
            <button
              className="btn btn-primary btn-xl btn-block mt-3"
              onClick={handleContinueToAddons}
            >
              Continue
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <Fragment>
      <div className="row row-spacer">
        <div className="col">
          <h2>Plan and Add-Ons</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Wizard step="chooseplan" paymentLocked={props.isPaymentDisabled} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <IntervalSwitch
            interval={props.selectedPlanInterval}
            onSwitch={props.setInterval}
          />
        </div>
      </div>
      {subView}
    </Fragment>
  );
};

export default ChoosePlan;
