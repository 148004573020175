import { AsyncActionStatus } from 'store/asyncActions';
import { getAPIErrors, apiErrorMessage } from '../api';
import { CustomerActions, CustomerTypes } from 'store/customer/types';

import {
  PayAmountOwingForm,
  PayAmountOwingFormActions,
  PayAmountOwingFormTypes,
} from './types';

import {
  SubscriptionActions,
  SubscriptionTypes,
} from 'store/subscription/types';

import {
  PAY_AMOUNT_OWING_FORM_ERROR,
  PAY_AMOUNT_OWING_FORM_SUCCESS,
} from 'store/constants';

const initialState: PayAmountOwingForm = {};

export function payAmountOwingForm(
  state: PayAmountOwingForm = initialState,
  action: PayAmountOwingFormTypes | SubscriptionTypes | CustomerTypes,
): PayAmountOwingForm {
  let newState: PayAmountOwingForm;

  switch (action.type) {
    case CustomerActions.POST_CREDIT_CARD:
      if (action.status === AsyncActionStatus.FAILED) {
        let apiErrors = getAPIErrors(action.error);
        if (apiErrors) {
          return {
            errorMessage: apiErrorMessage(apiErrors),
          };
        }
        return {
          errorMessage: PAY_AMOUNT_OWING_FORM_ERROR,
        };
      } else {
        newState = state;
      }
      break;
    case SubscriptionActions.PAY_AMOUNT_OWING:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        return {
          successMessage: PAY_AMOUNT_OWING_FORM_SUCCESS,
        };
      } else if (action.status === AsyncActionStatus.FAILED) {
        let apiErrors = getAPIErrors(action.error);
        if (apiErrors) {
          return {
            errorMessage: apiErrorMessage(apiErrors),
          };
        }
        return {
          errorMessage: PAY_AMOUNT_OWING_FORM_ERROR,
        };
      }
      return state;
    case PayAmountOwingFormActions.CLEAR_MESSAGES:
      return {
        errorMessage: '',
        successMessage: '',
      };
    default:
      newState = state;
      break;
  }
  return newState;
}
