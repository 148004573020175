import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Customer } from 'store/customer/types';
import { PaymentSource } from 'store/payment';
import { FormattedBillingAddress } from 'selectors/selectFormattedBillingAddress';
import { asyncActionStatusBool, AsyncActionStatus } from 'store/asyncActions';

import Card from 'containers/Billing/components/Card';
import CreditCard from 'containers/Billing/components/CreditCard';

import style from './Billing.module.scss';
import ComponentSpinner from 'containers/Billing/components/ComponentSpinner';

interface ConnectedProps {
  customer: Customer;
  customerLoading: AsyncActionStatus;
  billingAddress: FormattedBillingAddress;
  paymentSource: PaymentSource | undefined;
}

const Billing = (props: ConnectedProps) => {
  const isLoading = asyncActionStatusBool(props.customerLoading);

  return (
    <Fragment>
      <div className="row row-spacer">
        <div className="col">
          <h2>Billing</h2>
        </div>
      </div>
      <div className="row">
        {props.paymentSource && (
          <div className="col-12 col-lg-6 mb-3">
            <Card
              className={style.card}
              footer={
                <Link to="/billing/update-payment-method">
                  Update Payment Method
                </Link>
              }
            >
              <div className="row">
                <div className="col">
                  <h5 className={style.header}>Payment Method</h5>
                  <div className={style.obfuscatedCardNumber}>
                    <span>XXXX</span>
                    <span>XXXX</span>
                    <span>XXXX</span>
                    <span>{props.paymentSource.creditCardLast4}</span>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mt-2">
                <div className="col">
                  <div className="expiry">
                    Expiry
                    <span className="text-muted pl-2">
                      {props.paymentSource.creditCardExpiryMonth}/
                      {props.paymentSource.creditCardExpiryYear
                        .toFixed()
                        .slice(-2)}
                    </span>
                  </div>
                </div>
                <div className="col text-right">
                  <CreditCard brand={props.paymentSource.brand} size="44" />
                </div>
              </div>
            </Card>
          </div>
        )}
        <div className="col-12 col-lg-6 mb-3">
          <Card
            className={style.card}
            footer={
              <Link to="/billing/update-billing-address">
                Update Billing Address
              </Link>
            }
          >
            <div className="row">
              <div className="col">
                <h5 className={style.header}>Billing Address</h5>
                {isLoading ? (
                  <ComponentSpinner />
                ) : (
                  <div className="text-muted">
                    {props.billingAddress.fullName}
                    <br />
                    {props.billingAddress.billingAddress}
                    <br />
                    {props.billingAddress.billingStreet}
                    <br />
                    {props.billingAddress.billingCountry}
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
        <div className="col-12 col-lg-6 mb-3">
          <Card
            className={style.card}
            footer={<a href="mailto:support@quoter.com">Contact Support</a>}
          >
            <h5 className={style.header}>Contact Support</h5>
            <p className="text-muted mb-0">
              If you have any questions about your account, visit our{' '}
              <a
                href="http://help.quoter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                knowledge base
              </a>{' '}
              or contact us at any time.
            </p>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default Billing;
