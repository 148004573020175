import React from 'react';

const ComponentSpinner = () => (
  <div className="text-center">
    <span
      className="spinner-border spinner-border-sm text-primary"
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </span>
  </div>
);

export default ComponentSpinner;
