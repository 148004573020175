import React, { Fragment } from 'react';
import cx from 'classnames';
import { Addon } from 'store/plans/types';

import {
  MULTI_TENTANT_MAX_USERS,
  MULTI_TENTANT_MIN_USERS,
} from 'store/constants';

import Input from 'components/Form/Input';
import Icon from 'components/Icon';
import style from './Addon.module.scss';

interface Props {
  addon: Addon;
  current?: boolean;
  onDecrement: () => void;
  onIncrement: () => void;
  onSet: (value: string) => void;
  selected?: boolean;
  value: number;
}

const MultiTenant = (props: Props) => {
  const disableInputs = props.selected === false && props.current === false;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    props.onSet(e.target.value);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col">
          <label htmlFor="multiTenant" className={style.label}>
            Number of Users
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col pr-2" style={{ maxWidth: '100px' }}>
          <Input
            id="multiTenant"
            placeholder="#"
            onChange={handleChange}
            value={props.value.toString()}
            disabled={disableInputs}
            inputClassName={cx(style.disabledInput, style.input)}
          />
        </div>
        <div className="col align-items-end pl-0">
          <button
            className="btn btn-white"
            onClick={props.onDecrement}
            disabled={props.value === MULTI_TENTANT_MIN_USERS || disableInputs}
          >
            <Icon name="minus" />
          </button>
          <button
            className="btn btn-white ml-1"
            onClick={props.onIncrement}
            disabled={props.value === MULTI_TENTANT_MAX_USERS || disableInputs}
          >
            <Icon name="plus" />
          </button>
        </div>
      </div>
      <p>{props.addon.description}</p>
      <ul>
        {props.addon.features.map((featureString, i) => (
          <li key={i}>{featureString}</li>
        ))}
      </ul>
    </Fragment>
  );
};

export default MultiTenant;
