import { connect } from 'react-redux';
import { AppState } from 'store';
import UpdateBillingAddress from './UpdateBillingAddress';
import selectBillingFormData from 'selectors/selectBillingFormData';
import { putCustomer } from 'store/customer/actions';
import { clearMessages } from 'store/billingAddressForm/actions';

const mapStateToProps = (state: AppState) => ({
  errorMessage: state.billingAddressForm.errorMessage,
  form: selectBillingFormData(state),
  isLoading: state.loading.billingAddressForm,
  successMessage: state.billingAddressForm.successMessage,
});

const mapDispatchToProps = {
  clearMessages: clearMessages,
  onSubmit: putCustomer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateBillingAddress);
