import React, { Fragment } from 'react';
import isEmpty from 'lodash.isempty';
import { Link } from 'react-router-dom';
import { Invoice } from 'store/invoices/types';
import { PaymentSource } from 'store/payment';
import { selectCurrency } from 'utils/countries';

import Card from 'containers/Billing/components/Card';
import CreditCard from 'containers/Billing/components/CreditCard';
import ComponentSpinner from 'containers/Billing/components/ComponentSpinner';
import DateNumber from 'components/DateNumber';
import CurrencyNumber from 'components/CurrencyNumber';

interface Props {
  invoice: Invoice;
  isLoading: boolean;
  paymentSource: PaymentSource | undefined;
}

const UpcomingInvoice = (props: Props) => {
  let cardBody: React.ReactNode;

  if (props.isLoading) {
    cardBody = <ComponentSpinner />;
  } else if (isEmpty(props.invoice)) {
    cardBody = (
      <div className="text-muted text-center">
        Unable to retrieve upcoming invoice.
      </div>
    );
  } else if (isEmpty(props.paymentSource)) {
    cardBody = (
      <div className="text-muted text-center">
        Unable to retrieve payment sources.
      </div>
    );
  } else {
    let timestamp = Date.now();
    let twoWeeksTimestamp = timestamp + 12096e5;

    // Indicates upcoming invoice is: Due Soon / Overdue, or show due date
    let dueDateString: string = 'Due date';
    let dueDateClassName: string = 'text-muted';

    if (props.invoice.periodEnd) {
      let invoiceTimestamp = Date.parse(props.invoice.periodEnd.toString());

      if (timestamp > invoiceTimestamp) {
        dueDateString = 'Overdue';
        dueDateClassName = 'text-danger font-weight-bold';
      } else if (twoWeeksTimestamp > invoiceTimestamp) {
        dueDateString = 'Due soon';
        dueDateClassName = 'text-warning font-weight-bold';
      }
    }

    let dueString: string = 'Amount';
    let dueClassName: string = 'text-muted';

    // Indicates upcoming invoice is "Paid" (this is unlikely to show)
    if (props.invoice.paid) {
      dueString = 'Paid';
      dueClassName = 'text-success';
    }

    cardBody = (
      <Fragment>
        <div className="row">
          <div className="col-auto">
            {props.invoice.periodEnd && (
              <>
                <h4>
                  <DateNumber value={props.invoice.periodEnd} />
                </h4>
                <div className={dueDateClassName}>{dueDateString}</div>
              </>
            )}
          </div>
          {props.invoice.amountDueInCents && (
            <div className="col text-right">
              <h4>
                <CurrencyNumber
                  className="pr-1"
                  currency={selectCurrency(props.invoice.currency)}
                  value={props.invoice.amountDueInCents / 100}
                />
                {props.invoice.currency && props.invoice.currency.toUpperCase()}
              </h4>
              <span className={dueClassName}>{dueString}</span>
            </div>
          )}
        </div>
        <div className="row mt-5">
          <div className="col-auto">
            <div className="row align-items-center no-gutters">
              <div className="col-auto">
                <CreditCard brand={props.paymentSource?.brand} />
              </div>
              <div className="col-auto pl-2">
                **** {props.paymentSource?.creditCardLast4}
              </div>
            </div>
          </div>
          <div className="col align-self-center text-right">
            <Link
              to="/billing/update-payment-method"
              className="text-muted text-underline"
            >
              Update Payment Method
            </Link>
          </div>
        </div>
      </Fragment>
    );
  }

  return <Card title="Upcoming Invoice">{cardBody}</Card>;
};

export default UpcomingInvoice;
