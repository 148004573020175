import CurrencyNumber from 'components/CurrencyNumber';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from 'store';
import { Customer } from 'store/customer/types';
import { Currency } from 'utils/countries';

import selectSubscriptionCurrency from 'selectors/selectSubscriptionCurrency';
import {
  customerHasBalance,
  customerHasBalanceDue,
} from 'store/customer/utility';

type Props = {
  customer: Customer;
  currency: Currency;
};

const LayoutBanner = (props: Props) => {
  let history = useHistory();

  const handleClickPayNow = () => {
    history.push('/pay-amount-owing');
  };

  if (customerHasBalanceDue(props.customer.balanceInCents)) {
    return (
      <div
        className="alert alert-warning alert-sticky d-flex justify-content-between align-items-center px-4"
        role="alert"
      >
        <div>
          Your account is past due with a balance due{' '}
          <CurrencyNumber
            value={props.customer.balanceInCents / 100}
            currency={props.currency}
            decimals={2}
          />{' '}
          owing.
        </div>
        <button className="btn btn-warning px-3" onClick={handleClickPayNow}>
          Pay Now
        </button>
      </div>
    );
  } else if (customerHasBalance(props.customer.balanceInCents)) {
    return (
      <div className="alert alert-info alert-sticky py-3" role="alert">
        You have a credit of{' '}
        <CurrencyNumber
          value={Math.abs(props.customer.balanceInCents / 100)}
          currency={props.currency}
          decimals={2}
        />{' '}
        which will be applied to your next invoice.
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state: AppState) => ({
  customer: state.customer,
  currency: selectSubscriptionCurrency(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutBanner);
