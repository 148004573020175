import React from 'react';
import AnimateHeight from 'react-animate-height';
import BillingForm from 'containers/Billing/components/BillingForm';
import Card from 'containers/Billing/components/Card';
import { Form } from 'hooks/useForm';
import style from './PaymentMethod.module.scss';

interface Props {
  id: string;
  form: Form;
  height: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onFormChange: (e: React.ChangeEvent<any>) => void;
  setForm: React.Dispatch<React.SetStateAction<Form>>;
}

const PaymentMethodNew = (props: Props) => (
  <Card removePadding className="mb-4">
    <label className={style.rowLabel} htmlFor={props.id}>
      <div className={style.colRadioButton}>
        <div className="custom-control custom-radio d-inline-block">
          <input
            type="radio"
            id={props.id}
            name="paymentMethod"
            className="custom-control-input"
            onChange={props.onChange}
          />
          <div className="custom-control-label"></div>
        </div>
      </div>
      <div className="col">
        <h5 className={style.paymentTitle}>New Card</h5>
      </div>
    </label>
    <AnimateHeight height={props.height} className="AnimateHeight">
      <div className="px-4 pb-3">
        <BillingForm
          form={props.form}
          onSubmit={props.onSubmit}
          onFormChange={props.onFormChange}
          setForm={props.setForm}
        />
      </div>
    </AnimateHeight>
  </Card>
);

export default PaymentMethodNew;
