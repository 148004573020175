import { CustomerActions } from './types';
import { ThunkyDispatch, ThunkyAction } from 'store/types';
import { asyncAction } from 'store/asyncActions';
import { get, put, IHttpResponse, post } from 'utils/fetch';
import { BillingForm } from 'store/forms';
import { ReactStripeElements } from 'react-stripe-elements';

import {
  IAPIResponse,
  IAPICustomer,
  IAPICreditCard,
  API_BASE_URL,
} from 'store/api';

export const getCustomer = (): ThunkyAction => {
  return async (dispatch: ThunkyDispatch) => {
    return dispatch(asyncAction(CustomerActions.GET_CUSTOMER, apiGetCustomer));
  };
};

export const apiGetCustomer = async (): Promise<IHttpResponse<
  IAPIResponse<IAPICustomer>
>> => {
  const response = await get<IAPIResponse<IAPICustomer>>(
    `${API_BASE_URL}/customer`,
  );

  return response;
};

export const putCustomer = (formData: BillingForm): ThunkyAction => {
  return async (dispatch: ThunkyDispatch) => {
    await dispatch(
      asyncAction(CustomerActions.PUT_CUSTOMER, apiPutCustomer, formData),
    );

    return dispatch(asyncAction(CustomerActions.GET_CUSTOMER, apiGetCustomer));
  };
};

export const apiPutCustomer = async (
  formData: BillingForm,
): Promise<IHttpResponse<IAPIResponse<IAPICustomer>>> => {
  const response = await put<IAPIResponse<IAPICustomer>>(
    `${API_BASE_URL}/customer`,
    {
      company: formData.companyName,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      billing_address1: formData.billingAddress1,
      billing_address2: formData.billingAddress2,
      billing_city: formData.billingCity,
      billing_country_iso: formData.billingCountryISO,
      billing_region_iso: formData.billingRegionISO,
      billing_postal_code: formData.billingPostalCode,
    },
  );

  return response;
};

export const postCreditCard = (
  stripe: ReactStripeElements.StripeProps,
  formData: BillingForm,
) => {
  return async (dispatch: ThunkyDispatch) => {
    return await dispatch(
      asyncAction(
        CustomerActions.POST_CREDIT_CARD,
        apiPostCreditCard,
        stripe,
        formData,
      ),
    );
  };
};

export const apiPostCreditCard = async (
  stripe: ReactStripeElements.StripeProps,
  formData: BillingForm,
): Promise<IHttpResponse<IAPIResponse<IAPICreditCard>> | undefined> => {
  const token = await stripeCreateToken(stripe, formData);

  if (token) {
    const response = await post<IAPIResponse<IAPICreditCard>>(
      `${API_BASE_URL}/customer/credit_cards`,
      {
        token: token.id,
        id: token.card?.id,
        billing_cardholder: token.card?.name,
        billing_address1: formData.billingAddress1,
        billing_address2: formData.billingAddress2,
        billing_city: formData.billingCity,
        billing_country_iso: formData.billingCountryISO,
        billing_region_iso: formData.billingRegionISO,
        billing_postal_code: formData.billingPostalCode,
        credit_card_last_4: token.card?.last4,
        credit_card_expiry_month: token.card?.exp_month,
        credit_card_expiry_year: token.card?.exp_year,
        brand: token.card?.brand,
      },
    );

    return response;
  }
};

export const stripeCreateToken = async (
  stripe: ReactStripeElements.StripeProps,
  formData: BillingForm,
) => {
  let tokenOptions: stripe.TokenOptions = {
    name: formData.billingCardHolder,
    address_line1: formData.billingAddress1,
    address_line2: formData.billingAddress2,
    address_city: formData.billingCity,
    address_zip: formData.billingPostalCode,
    address_country: formData.billingCountryISO,
    address_state: formData.billingRegionISO
      ? formData.billingRegionISO
      : undefined,
  };

  let { token, error } = await stripe.createToken(tokenOptions);

  if (error) {
    throw error;
  }

  if (token) {
    return token;
  }
};
