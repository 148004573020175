import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import configureStore from './store';

import Billing from './containers/Billing';
import Signup from './containers/Signup';

import * as serviceWorker from './serviceWorker';

// Bootstrap - custom import per bootstrap/js/dist/index.js to reduce bundle size
import 'jquery/dist/jquery.slim.min.js';
// import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown'
// import 'bootstrap/js/dist/modal'
// import 'bootstrap/js/dist/popover'
// import 'bootstrap/js/dist/scrollspy'
// import 'bootstrap/js/dist/tab'
// import 'bootstrap/js/dist/toast'
// import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/util';

import './styles/index.scss';

// Google Tag Manager
import TagManager from 'react-gtm-module';

if (process.env.NODE_ENV !== 'development') {
  TagManager.initialize({
    gtmId: 'GTM-TLRGBQ3',
  });
}

const store = configureStore();

if (process.env.REACT_APP_BUILD_TARGET === 'billing') {
  let Root = () => (
    <Provider store={store}>
      <Billing />
    </Provider>
  );
  ReactDOM.render(<Root />, document.getElementById('root'));
} else {
  let Root = () => (
    <Provider store={store}>
      <Signup />
    </Provider>
  );
  ReactDOM.render(<Root />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
