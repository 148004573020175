import React, { Fragment } from 'react';
import { Form } from 'hooks/useForm';
import { setBillingRegionForm, setStripeElementForm } from 'store/forms';

import Input from 'components/Form/Input';
import Select from 'components/Form/Select';

import {
  ReactStripeElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';

import {
  Countries,
  countryHasRegions,
  getCountrysRegions,
} from 'utils/countries';

interface Props {
  form: Form;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onFormChange: (e: React.ChangeEvent<any>) => void;
  setForm: React.Dispatch<React.SetStateAction<Form>>;
  errorMessage?: string;
}

const stripeElementStyle = {
  base: {
    lineHeight: '1.5',
    fontSize: '16px',
    '::placeholder': {
      color: '#6C757D',
    },
    color: '#495057',
    fontFamily: '"Lato"',
    fontSmoothing: 'antialiased',
    fontWeight: 400,
  },
  invalid: {
    color: '#495057',
  },
};

const BillingForm = (props: Props) => {
  const { formControls } = props.form;

  const handleBillingCountryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    props.onFormChange(e);
    setBillingRegionForm(e.target.value, Countries, props.setForm);
  };

  const handleBillingRegionChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    props.onFormChange(e);
  };

  const handleStripeElementChange = (
    e: ReactStripeElements.ElementChangeResponse,
  ) => {
    setStripeElementForm(e, props.setForm);
  };

  return (
    <form noValidate onSubmit={props.onSubmit}>
      {props.errorMessage && (
        <div className="alert alert-danger" role="alert">
          {props.errorMessage}
        </div>
      )}
      <div className="row">
        <div className="col-12 col-xl-6">
          <Input
            id="cardNumber"
            type="text"
            label="Card number"
            inputPrepend={
              <Fragment>
                <i className="fab fa-cc-visa" />
                &nbsp;
                <i className="fab fa-cc-mastercard" />
                &nbsp;
                <i className="fab fa-cc-amex" />
              </Fragment>
            }
            helperText={
              <Fragment>
                <i className="fas fa-lock pr-1" /> Handled securely by Stripe
              </Fragment>
            }
          >
            <CardNumberElement
              id="cardNumber"
              className="form-control"
              placeholder="Card number"
              style={stripeElementStyle}
              onChange={handleStripeElementChange}
            />
          </Input>
        </div>
        <div className="col-12 col-xl-6">
          <div className="row">
            <Input
              id="cardExpiry"
              formGroupClassName="col-6"
              type="text"
              label="Expiry Date"
            >
              <CardExpiryElement
                id="cardExpiry"
                className="form-control"
                style={stripeElementStyle}
                onChange={handleStripeElementChange}
                placeholder="MM / YY"
              />
            </Input>
            <Input
              id="cardCvc"
              formGroupClassName="col-6"
              type="text"
              label="CVC"
              inputAppend={<i className="fas fa-credit-card" />}
            >
              <CardCvcElement
                id="cardCVC"
                className="form-control"
                style={stripeElementStyle}
                onChange={handleStripeElementChange}
                placeholder="CVC"
              />
            </Input>
          </div>
        </div>
      </div>
      <div className="row">
        <Input
          id="billingCardHolder"
          value={formControls.billingCardHolder.value || ''}
          onChange={props.onFormChange}
          isInvalid={formControls.billingCardHolder.isValid === false}
          formGroupClassName="col-12 col-lg-6"
          type="text"
          label="Card holder"
          placeholder="Card holder"
        />
        <Input
          id="billingAddress1"
          value={formControls.billingAddress1.value || ''}
          onChange={props.onFormChange}
          isInvalid={formControls.billingAddress1.isValid === false}
          type="text"
          label="Billing address"
          formGroupClassName="col-12 col-lg-6"
          placeholder="Billing address"
        />
        <Input
          id="billingAddress2"
          value={formControls.billingAddress2.value || ''}
          onChange={props.onFormChange}
          isInvalid={formControls.billingAddress2.isValid === false}
          type="text"
          label="Billing address 2"
          formGroupClassName="col-12 col-lg-6"
          placeholder="Billing address 2"
        />
        <Input
          id="billingCity"
          value={formControls.billingCity.value || ''}
          onChange={props.onFormChange}
          isInvalid={formControls.billingCity.isValid === false}
          formGroupClassName="col-12 col-lg-6"
          type="text"
          label="City"
          placeholder="City"
        />
        <Select
          id="billingCountry"
          value={formControls.billingCountry.value || ''}
          label="Country"
          onChange={handleBillingCountryChange}
          isInvalid={formControls.billingCountry.isValid === false}
          formGroupClassName="col-12 col-lg-6"
        >
          <option value="">- Please Select -</option>
          {Object.entries(Countries).map(([iso, country]) => (
            <option key={iso} value={iso}>
              {country.name}
            </option>
          ))}
        </Select>
        {countryHasRegions(formControls.billingCountry.value, Countries) && (
          <Select
            id="billingRegion"
            value={formControls.billingRegion.value || ''}
            label="State/province"
            onChange={handleBillingRegionChange}
            isInvalid={formControls.billingRegion.isValid === false}
            formGroupClassName="col-12 col-lg-6"
          >
            <option value="">- Please Select -</option>
            {Object.entries(
              getCountrysRegions(formControls.billingCountry.value, Countries),
            ).map(([iso, region]) => (
              <option key={iso} value={iso}>
                {region.name}
              </option>
            ))}
          </Select>
        )}
        <Input
          id="billingPostalCode"
          value={formControls.billingPostalCode.value || ''}
          onChange={props.onFormChange}
          isInvalid={formControls.billingPostalCode.isValid === false}
          formGroupClassName="col-12 col-lg-6"
          type="text"
          label="Zip/postal code"
          placeholder="Zip/postal code"
        />
      </div>
    </form>
  );
};

export default BillingForm;
