import React from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import './Navigation.scss';

interface Props extends RouteComponentProps {
  enableAddons: boolean;
  enablePayment: boolean;
}

export default function Navigation(props: Props) {
  let addonsNavLink;

  if (props.enableAddons) {
    addonsNavLink = (
      <NavLink
        to="/addons"
        exact
        className="breadcrumb-link"
        href="/"
        activeClassName="active"
      >
        3. Add-ons
      </NavLink>
    );
  } else {
    addonsNavLink = <span className="breadcrumb-link">3. Add-ons</span>;
  }

  let paymentStepNumber = 4;
  let paymentLink;
  if (props.enablePayment) {
    paymentLink = (
      <NavLink
        to="/payment"
        exact
        className="breadcrumb-link"
        activeClassName="active"
      >
        {paymentStepNumber}. Payment
      </NavLink>
    );
  } else {
    paymentLink = (
      <span className="breadcrumb-link">{paymentStepNumber}. Payment</span>
    );
  }

  return (
    <ul className="Navigation">
      <li className="breadcrumb-item">
        <a href="https://www.quoter.com/pricing" className="breadcrumb-link">
          1. Choose plan
        </a>
      </li>
      <li className="breadcrumb-item">
        <NavLink to="/a/" className="breadcrumb-link" activeClassName="active">
          2. Create account
          <span className="sr-only">(current)</span>
        </NavLink>
      </li>
      <li className="breadcrumb-item">{addonsNavLink}</li>
      <li className="breadcrumb-item">{paymentLink}</li>
    </ul>
  );
}
