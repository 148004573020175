import { PaymentInterval, convertAPIPlanCoupon } from 'store/payment';
import { AsyncActionStatus } from 'store/asyncActions';
import { getAPIErrors, getAPIResource, apiErrorMessage } from 'store/api';
import {
  convertAPIAddonId,
  isValidEnterpriseQuotesCount,
  isValidMultiTenantUserCount,
} from 'store/plans/utility';
import { CustomerActions, CustomerTypes } from 'store/customer/types';

import {
  convertAPISubscription,
  getSubscriptionEnterpriseQuotes,
  getSubscriptionMultiTenantUsers,
} from 'store/subscription/utility';

import {
  COUPON_ERROR,
  MULTI_TENENT_INITIAL,
  PAYMENT_WIZARD_FORM_ERROR,
  PAYMENT_WIZARD_FORM_SUCCESS_CARD_DECLINED,
  PAYMENT_WIZARD_FORM_SUCCESS,
  ENTERPRISE_QUOTES_INITIAL,
} from 'store/constants';

import {
  SubscriptionTypes,
  SubscriptionActions,
} from 'store/subscription/types';

import {
  PaymentWizard,
  PaymentWizardTypes,
  PaymentWizardActions,
} from './types';

const initialState: PaymentWizard = {
  addons: [],
  couponCode: null,
  planCoupon: null,
  couponErrorMessage: '',
  errorMessage: '',
  successMessage: '',
  multiTenantUsers: MULTI_TENENT_INITIAL,
  enterpriseQuotes: ENTERPRISE_QUOTES_INITIAL,
  planInterval: PaymentInterval.MONTHLY,
};

export function paymentWizard(
  state: PaymentWizard = initialState,
  action: CustomerTypes | PaymentWizardTypes | SubscriptionTypes,
): PaymentWizard {
  let newState: PaymentWizard;

  switch (action.type) {
    case SubscriptionActions.GET_SUBSCRIPTION:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        const apiSubscription = getAPIResource(action.payload);
        if (apiSubscription) {
          const subscription = convertAPISubscription(apiSubscription);
          newState = {
            ...state,
            planInterval: subscription.paymentInterval || state.planInterval,
            multiTenantUsers: getSubscriptionMultiTenantUsers(subscription),
            enterpriseQuotes: getSubscriptionEnterpriseQuotes(subscription),
          };

          if (subscription.plan) {
            newState = {
              ...newState,
            };
          }

          if (subscription.addons) {
            newState = {
              ...newState,
              addons: subscription.addons.map(addon =>
                convertAPIAddonId(addon.id),
              ),
            };
          }
        } else {
          newState = state;
        }
      } else {
        newState = state;
      }
      break;
    case CustomerActions.POST_CREDIT_CARD:
      if (action.status === AsyncActionStatus.FAILED) {
        let apiErrors = getAPIErrors(action.error);
        if (apiErrors) {
          newState = {
            ...state,
            errorMessage: apiErrorMessage(apiErrors),
          };
        } else {
          newState = {
            ...state,
            errorMessage: PAYMENT_WIZARD_FORM_ERROR,
          };
        }
      } else {
        newState = state;
      }
      break;
    case PaymentWizardActions.SET_INTERVAL:
      newState = {
        ...state,
        couponCode: null,
        planCoupon: null,
        planInterval: action.payload,
      };
      break;
    case PaymentWizardActions.SET_PLAN_ID:
      newState = {
        ...state,
        couponCode: null,
        planCoupon: null,
        planId: action.payload,
      };
      break;
    case PaymentWizardActions.ADD_ADDON:
      newState = {
        ...state,
        addons: [...state.addons, action.payload],
      };
      break;
    case PaymentWizardActions.REMOVE_ADDON:
      newState = {
        ...state,
        addons: state.addons.filter(id => id !== action.payload),
      };
      break;
    case PaymentWizardActions.SET_MULTI_TENANT:
      let inputValue = parseInt(action.payload);

      if (isNaN(inputValue)) {
        newState = {
          ...state,
          multiTenantUsers: MULTI_TENENT_INITIAL,
        };
      } else {
        newState = {
          ...state,
          multiTenantUsers: inputValue,
        };
      }
      break;
    case PaymentWizardActions.INCREMENT_MULTI_TENANT:
      let incrementSize = 10;

      if (isValidMultiTenantUserCount(state.multiTenantUsers + incrementSize)) {
        newState = {
          ...state,
          multiTenantUsers: state.multiTenantUsers + incrementSize,
        };
      } else {
        newState = {
          ...state,
          multiTenantUsers:
            (state.multiTenantUsers % 10) + state.multiTenantUsers,
        };
      }
      break;
    case PaymentWizardActions.DECREMENT_MULTI_TENANT:
      let decrementSize = 10;

      if (isValidMultiTenantUserCount(state.multiTenantUsers - decrementSize)) {
        newState = {
          ...state,
          multiTenantUsers: state.multiTenantUsers - decrementSize,
        };
      } else {
        newState = {
          ...state,
          multiTenantUsers:
            state.multiTenantUsers - (state.multiTenantUsers % 10),
        };
      }
      break;
    case PaymentWizardActions.SET_ENTERPRISE_QUOTES: {
      const inputValue = parseInt(action.payload);

      if (isNaN(inputValue)) {
        newState = {
          ...state,
          enterpriseQuotes: ENTERPRISE_QUOTES_INITIAL,
        };
      } else {
        newState = {
          ...state,
          enterpriseQuotes: inputValue,
        };
      }
      break;
    }
    case PaymentWizardActions.INCREMENT_ENTERPRISE_QUOTES: {
      const incrementSize = 50;

      if (
        isValidEnterpriseQuotesCount(state.enterpriseQuotes + incrementSize)
      ) {
        newState = {
          ...state,
          enterpriseQuotes: state.enterpriseQuotes + incrementSize,
        };
      } else {
        newState = {
          ...state,
          enterpriseQuotes:
            (state.enterpriseQuotes % 50) + state.enterpriseQuotes,
        };
      }
      break;
    }
    case PaymentWizardActions.DECREMENT_ENTERPRISE_QUOTES: {
      const decrementSize = 50;

      if (
        isValidEnterpriseQuotesCount(state.enterpriseQuotes - decrementSize)
      ) {
        newState = {
          ...state,
          enterpriseQuotes: state.enterpriseQuotes - decrementSize,
        };
      } else {
        newState = {
          ...state,
          enterpriseQuotes:
            state.enterpriseQuotes - (state.enterpriseQuotes % 50),
        };
      }
      break;
    }
    case PaymentWizardActions.SUBMIT_COUPON_CODE:
      return {
        ...state,
        couponCode: action.payload,
      };
    case PaymentWizardActions.APPLY_COUPON_CODE:
      if (action.status === AsyncActionStatus.STARTED) {
        return {
          ...state,
          planCoupon: null,
          couponErrorMessage: '',
        };
      } else if (action.status === AsyncActionStatus.SUCCEEDED) {
        let apiCouponValidation = getAPIResource(action.payload);

        if (apiCouponValidation) {
          let planCoupon = apiCouponValidation.plan
            ? convertAPIPlanCoupon(apiCouponValidation.plan)
            : null;
          return {
            ...state,
            planCoupon: planCoupon,
            couponErrorMessage: '',
          };
        }
      } else if (action.status === AsyncActionStatus.FAILED) {
        let apiErrors = getAPIErrors(action.error);
        if (apiErrors) {
          return {
            ...state,
            planCoupon: null,
            couponErrorMessage: apiErrorMessage(apiErrors),
          };
        }
      }

      return {
        ...state,
        planCoupon: null,
        couponErrorMessage: COUPON_ERROR,
      };
    case SubscriptionActions.PUT_SUBSCRIPTION:
      if (action.status === AsyncActionStatus.STARTED) {
        return {
          ...state,
          errorMessage: '',
          successMessage: '',
        };
      } else if (action.status === AsyncActionStatus.SUCCEEDED) {
        let apiSubscription = getAPIResource(action.payload);
        if (apiSubscription) {
          if (apiSubscription.payment_error_message) {
            return {
              ...state,
              ...initialState,
              planId: undefined,
              successMessage: PAYMENT_WIZARD_FORM_SUCCESS_CARD_DECLINED,
              errorMessage: apiSubscription.payment_error_message,
            };
          } else {
            return {
              ...state,
              ...initialState,
              planId: undefined,
              successMessage: PAYMENT_WIZARD_FORM_SUCCESS,
            };
          }
        }
      } else if (action.status === AsyncActionStatus.FAILED) {
        let apiErrors = getAPIErrors(action.error);
        if (apiErrors) {
          return {
            ...state,
            errorMessage: apiErrorMessage(apiErrors),
          };
        }
      }
      return {
        ...state,
        errorMessage: PAYMENT_WIZARD_FORM_ERROR,
      };
    case PaymentWizardActions.CLEAR_MESSAGES:
      newState = {
        ...state,
        errorMessage: '',
        successMessage: '',
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
