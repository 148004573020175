import { connect } from 'react-redux';
import { AppState } from 'store';
import UpdatePaymentMethod from './UpdatePaymentMethod';
import selectBillingFormData from 'selectors/selectBillingFormData';
import { clearMessages } from 'store/billingAddressForm/actions';
import { onSubmitUpdatePaymentMethod } from 'store/paymentMethodForm/actions';

const mapStateToProps = (state: AppState) => ({
  errorMessage: state.paymentMethodForm.errorMessage,
  form: selectBillingFormData(state),
  isLoading: state.loading.postCreditCard,
  successMessage: state.paymentMethodForm.successMessage,
});

const mapDispatchToProps = {
  clearMessages: clearMessages,
  onSubmitUpdatePaymentMethod: onSubmitUpdatePaymentMethod,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdatePaymentMethod);
