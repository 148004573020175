import React, { MouseEvent } from 'react';
import classNames from 'classnames';

import { Addon, AddonID } from 'store/plans/types';
import { Currency } from 'utils/countries';
import { PaymentInterval } from 'store/payment';
import { calcMonthlyPrice } from 'store/plans/utility';

import CurrencyNumber from 'components/CurrencyNumber';
import Card from 'containers/Billing/components/Card';
import Icon from 'components/Icon';

import style from './Addon.module.scss';

interface Props {
  addon: Addon;
  children: React.ReactNode;
  count?: number;
  currency: Currency;
  current?: boolean;
  icon: string;
  interval: PaymentInterval;
  onRemove: (id: AddonID) => void;
  onSelect: (id: AddonID) => void;
  selected?: boolean;
}

const AddonComponent = (props: Props) => {
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();

    if (props.selected === true) {
      props.onRemove(props.addon.id);
    } else {
      props.onSelect(props.addon.id);
    }
  };

  const AddonFooterButton = () => {
    if (props.current) {
      return (
        <button className="btn btn-text" disabled={props.current}>
          Already Subscribed
        </button>
      );
    }

    return (
      <button
        className={classNames('btn', {
          'btn-outline-danger': props.selected,
          'btn-outline-primary': !props.selected,
        })}
        onClick={handleClick}
      >
        {props.selected ? 'Remove Add-on' : 'Add to Subscription'}
      </button>
    );
  };

  return (
    <Card className="mb-4">
      <div className="row">
        <div className="col-2 d-none d-xl-flex">
          <Icon className={style.icon} name={props.icon} />
        </div>
        <div className="col-12 col-xl-10">
          <h4>{props.addon.name}</h4>
          <span className="text-muted">
            <CurrencyNumber
              value={calcMonthlyPrice(props.interval, props.addon, props.count)}
              currency={props.currency}
              decimals={2}
            />
            /mo
          </span>
          <div className={style.description}>{props.children}</div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col text-center">
          <AddonFooterButton />
        </div>
      </div>
    </Card>
  );
};

export default AddonComponent;
