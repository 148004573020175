export interface PaymentMethodForm {
  successMessage?: string;
  errorMessage?: string;
}

export enum PaymentMethodFormActions {
  CLEAR_MESSAGES = 'CLEAR_MESSAGES',
}

export type clearMessagesAction = {
  type: PaymentMethodFormActions.CLEAR_MESSAGES;
};

export type PaymentMethodFormTypes = clearMessagesAction;
