import React from 'react';
// import icon from '../../assets/icon.svg';
import donAnnas from '../../assets/don-annas.jpg';
import './Testimonial.scss';

type Props = {
  className?: string;
};

export default function Testimonial({ className }: Props) {
  return (
    <div className="Testimonial">
      <p className="text-muted font-italic">
        "I have worked with numerous quoting platforms over the years. Each
        quoting package had a few features that I really liked but never have I
        experienced a product as well rounded as Quoter. The functionality and
        impeccable support have confirmed this to be the best decision we could
        have made."
      </p>
      <div className="Source row pt-1">
        <div className="Avatar col-auto pr-0 m-auto">
          <img
            src={donAnnas}
            className="d-inline-block rounded-circle"
            width="50"
            alt="Quoter Testimonial"
          />
        </div>
        <div className="Name col">
          <span className="d-block text-muted font-weight-bold">Don Annas</span>
          <span className="d-block text-muted">TechSitters</span>
        </div>
      </div>
    </div>
  );
}
