// import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Currency } from 'utils/countries';
import { PaymentInterval } from 'store/payment';
import { PaymentWizard } from 'store/paymentWizard/types';
import { Plan, Addon as AddonType, Addons, AddonID } from 'store/plans/types';
import { Subscription } from 'store/subscription/types';

import {
  getAddonIcon,
  isAddonSelected,
  isSubscriptionAddonSelected,
} from 'store/plans/utility';

import Addon from 'components/Addon';
import Wizard from 'containers/Billing/components/Wizard';
import YourSubscription from 'components/YourSubscription';
import NoPlanRedirect from 'containers/Billing/components/NoPlanRedirect';
import MultiTenant from 'components/Addon/MultiTenant';
import EnterpriseQuote from 'components/Addon/EnterpriseQuote';

import style from './Addons.module.scss';

interface ConnectedProps {
  addAddon: (addonId: AddonID) => void;
  addons: { [id: string]: AddonType };
  currency: Currency;
  decrementEnterpriseQuotes: () => void;
  decrementMultiTenant: () => void;
  incrementEnterpriseQuotes: () => void;
  incrementMultiTenant: () => void;
  isPaymentDisabled: boolean;
  paymentWizard: PaymentWizard;
  removeAddon: (addonId: AddonID) => void;
  selectedPlan: Plan | undefined;
  selectedPlanAddons: Addons | undefined;
  selectedPlanInterval: PaymentInterval;
  setMultiTenant: (value: string) => void;
  setEnterpriseQuote: (value: string) => void;
  subscription: Subscription;
}

const AddonsComponent = (props: ConnectedProps) => {
  const {
    addAddon,
    addons,
    currency,
    decrementMultiTenant,
    incrementMultiTenant,
    decrementEnterpriseQuotes,
    incrementEnterpriseQuotes,
    isPaymentDisabled,
    paymentWizard,
    removeAddon,
    selectedPlan,
    selectedPlanAddons,
    setMultiTenant,
    setEnterpriseQuote,
    subscription,
  } = props;

  let history = useHistory();

  const handleClickContinue = () => {
    history.push('/plans/payment');
  };

  // MW Removed 2024-06-29
  // This duplicates the multi-tenant-users paymentWizard.addons state and does not seem to be necessary
  // useEffect(() => {
  //   if (
  //     isSubscriptionAddonSelected(
  //       subscription.addons,
  //       AddonID.MULTI_TENANT_USERS,
  //     )
  //   ) {
  //     addAddon(AddonID.MULTI_TENANT_USERS);
  //   }
  // }, [subscription.addons, addAddon]);

  return (
    <>
      <div className="row row-spacer">
        <div className="col">
          <h2>Plan and Add-Ons</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Wizard step="add-ons" paymentLocked={isPaymentDisabled} />
        </div>
      </div>
      {!selectedPlan || !selectedPlanAddons ? (
        <NoPlanRedirect />
      ) : (
        <div className="row row-spacer">
          <div className="col-12 order-last col-lg order-lg-first">
            {Object.keys(selectedPlan.availableAddons)?.map(addonId => {
              switch (addonId) {
                case AddonID.MULTI_TENANT_USERS:
                  return (
                    <Addon
                      key={AddonID.MULTI_TENANT_USERS}
                      icon={getAddonIcon(AddonID.MULTI_TENANT_USERS)}
                      addon={addons[AddonID.MULTI_TENANT_USERS]}
                      currency={currency}
                      interval={paymentWizard.planInterval}
                      onSelect={addAddon}
                      onRemove={removeAddon}
                      selected={isAddonSelected(
                        selectedPlanAddons,
                        AddonID.MULTI_TENANT_USERS,
                      )}
                      current={isSubscriptionAddonSelected(
                        subscription.addons,
                        AddonID.MULTI_TENANT_USERS,
                      )}
                      count={paymentWizard.multiTenantUsers}
                    >
                      <MultiTenant
                        addon={addons[AddonID.MULTI_TENANT_USERS]}
                        current={isSubscriptionAddonSelected(
                          subscription.addons,
                          AddonID.MULTI_TENANT_USERS,
                        )}
                        value={paymentWizard.multiTenantUsers}
                        onSet={setMultiTenant}
                        onIncrement={incrementMultiTenant}
                        onDecrement={decrementMultiTenant}
                        selected={isAddonSelected(
                          selectedPlanAddons,
                          AddonID.MULTI_TENANT_USERS,
                        )}
                      />
                    </Addon>
                  );

                case AddonID.ENTERPRISE_QUOTES:
                  return (
                    <Addon
                      key={AddonID.ENTERPRISE_QUOTES}
                      icon={getAddonIcon(AddonID.ENTERPRISE_QUOTES)}
                      addon={addons[AddonID.ENTERPRISE_QUOTES]}
                      currency={currency}
                      interval={paymentWizard.planInterval}
                      onSelect={addAddon}
                      onRemove={removeAddon}
                      selected={isAddonSelected(
                        selectedPlanAddons,
                        AddonID.ENTERPRISE_QUOTES,
                      )}
                      current={isSubscriptionAddonSelected(
                        subscription.addons,
                        AddonID.ENTERPRISE_QUOTES,
                      )}
                      count={paymentWizard.enterpriseQuotes}
                    >
                      <EnterpriseQuote
                        addon={addons[AddonID.ENTERPRISE_QUOTES]}
                        current={isSubscriptionAddonSelected(
                          subscription.addons,
                          AddonID.ENTERPRISE_QUOTES,
                        )}
                        value={paymentWizard.enterpriseQuotes}
                        onSet={setEnterpriseQuote}
                        onIncrement={incrementEnterpriseQuotes}
                        onDecrement={decrementEnterpriseQuotes}
                        selected={isAddonSelected(
                          selectedPlanAddons,
                          AddonID.ENTERPRISE_QUOTES,
                        )}
                      />
                    </Addon>
                  );

                default:
                  return (
                    <Addon
                      key={addonId}
                      icon={getAddonIcon(addonId)}
                      addon={addons[addonId]}
                      currency={currency}
                      interval={paymentWizard.planInterval}
                      onSelect={addAddon}
                      onRemove={removeAddon}
                      selected={isAddonSelected(selectedPlanAddons, addonId)}
                      current={isSubscriptionAddonSelected(
                        subscription.addons,
                        addonId,
                      )}
                    >
                      <p>{addons[addonId].description}</p>
                      <ul>
                        {addons[addonId].features?.map((featureString, i) => (
                          <li key={i}>{featureString}</li>
                        ))}
                      </ul>
                    </Addon>
                  );
              }
            })}
            <button
              onClick={handleClickContinue}
              className="btn btn-primary btn-xl btn-block"
              disabled={isPaymentDisabled}
            >
              Continue
            </button>
          </div>
          <div className={style.colYourSubscription}>
            <YourSubscription
              plan={selectedPlan}
              addons={selectedPlanAddons}
              interval={paymentWizard.planInterval}
              multiTenantUsers={paymentWizard.multiTenantUsers}
              enterpriseQuotes={paymentWizard.enterpriseQuotes}
              currency={currency}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AddonsComponent;
