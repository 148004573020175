import { connect } from 'react-redux';
import { AppState } from 'store';
import selectPlanById from 'selectors/selectPlanById';
import selectPlansAddons from 'selectors/selectPlansAddons';
import selectSubscriptionCurrency from 'selectors/selectSubscriptionCurrency';
import selectIsPaymentDisabled from 'selectors/selectIsPaymentDisabled';

import {
  addAddon,
  removeAddon,
  setMultiTenant,
  incrementMultiTenant,
  decrementMultiTenant,
  incrementEnterpriseQuotes,
  decrementEnterpriseQuotes,
} from 'store/paymentWizard/actions';

import Addons from './Addons';

const mapStateToProps = (state: AppState) => ({
  addons: state.plans.addons,
  currency: selectSubscriptionCurrency(state),
  isPaymentDisabled: selectIsPaymentDisabled(state),
  paymentWizard: state.paymentWizard,
  selectedPlan: selectPlanById(state),
  selectedPlanAddons: selectPlansAddons(state),
  subscription: state.subscription,
});

const mapDispatchToProps = {
  addAddon: addAddon,
  decrementEnterpriseQuotes: decrementEnterpriseQuotes,
  decrementMultiTenant: decrementMultiTenant,
  incrementEnterpriseQuotes: incrementEnterpriseQuotes,
  incrementMultiTenant: incrementMultiTenant,
  removeAddon: removeAddon,
  setMultiTenant: setMultiTenant,
};

export default connect(mapStateToProps, mapDispatchToProps)(Addons);
