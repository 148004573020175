import { SignupPlans, Addons } from './plans';
import { PlanID, Addon, AddonID } from 'store/plans/types';

import {
  SIGNUP_APPLY_COUPON_CODE,
  SIGNUP_CREATE_SUBSCRIPTION,
  SIGNUP_DECREMENT_ENTERPRISE_QUOTES,
  SIGNUP_DECREMENT_MULTI_TENANT,
  SIGNUP_DETECT_CURRENCY,
  SIGNUP_INCREMENT_ENTERPRISE_QUOTES,
  SIGNUP_INCREMENT_MULTI_TENANT,
  SIGNUP_REMOVE_ADDON,
  SIGNUP_SELECT_ADDON,
  SIGNUP_SET_BILLING_COUNTRY,
  SIGNUP_SET_BILLING_REGION,
  SIGNUP_SET_CURRENCY,
  SIGNUP_SET_INTERVAL,
  SIGNUP_SET_PLAN,
  SIGNUP_SUBMIT_ACCOUNT,
  SIGNUP_SUBMIT_ADDONS,
  SIGNUP_SUBMIT_COUPON_CODE,
  SIGNUP_SUBMIT_PAYMENT,
  SIGNUP_VALIDATE_EMAIL,
  SIGNUP_VALIDATE_SUBDOMAIN,
  SignupActionTypes,
  SignupApplyCouponCodeAction,
  SignupCreateSubscriptionAction,
  SignupDecrementEnterpriseQuotesAction,
  SignupDecrementMultiTenantAction,
  SignupDetectCurrencyAction,
  SignupIncrementEnterpriseQuotesAction,
  SignupIncrementMultiTenantAction,
  SignupRemoveAddonAction,
  SignupSelectAddonAction,
  SignupSetBillingCountryAction,
  SignupSetBillingRegionAction,
  SignupSetCurrencyAction,
  SignupSetIntervalAction,
  SignupSetPlanAction,
  SignupState,
  SignupSubmitAccountAction,
  SignupSubmitAddonsAction,
  SignupSubmitCouponCodeAction,
  SignupSubmitPaymentAction,
  SignupValidateEmailAction,
  SignupValidateSubdomainAction,
} from './types';

import {
  calculatePaymentAmounts,
  isValidEnterpriseQuotesCount,
  isValidMultiTenantUserCount,
} from 'store/plans/utility';

import {
  PaymentInterval,
  convertAPIOnboardingCoupon,
  convertAPIPlanCoupon,
} from 'store/payment';
import { BillingForm } from 'store/forms';
import { AsyncActionStatus } from '../asyncActions';
import { Countries, Currencies } from '../../utils/countries';
import { debugError } from '../../utils/logging';
import { getAPIErrors, getAPIResource, apiErrorMessage } from '../api';
import {
  ENTERPRISE_QUOTES_INITIAL,
  MULTI_TENENT_INITIAL,
} from 'store/constants';

const initialForm: BillingForm = {};
const initialPlan = SignupPlans[PlanID.STANDARD];
const initialAddons: { [id: string]: Addon } = {};
const initialInterval = PaymentInterval.MONTHLY;

const initialState: SignupState = {
  form: initialForm,
  plan: initialPlan,
  planSet: false,
  addons: initialAddons,
  currency: Currencies.USD,
  currencySet: false,
  interval: initialInterval,
  intervalSet: false,
  onboardingCoupon: null,
  planCoupon: null,
  amounts: calculatePaymentAmounts(
    initialPlan,
    Object.values(initialAddons),
    null,
    null,
    initialInterval,
    initialForm.billingCountryISO,
    initialForm.billingRegionISO,
  ),
  createAccountView: {
    formIsValid: false,
    isLoading: false,
    emailInvalid: false,
    subdomainInvalid: false,
  },
  chooseAddonsView: {
    multiTenantUsers: MULTI_TENENT_INITIAL,
    enterpriseQuotes: ENTERPRISE_QUOTES_INITIAL,
    isLoading: false,
  },
  couponView: {
    isLoading: false,
    couponCodeInvalid: false,
  },
  paymentView: {
    isLoading: false,
  },
  signupComplete: false,
};

export function signupReducer(
  state: SignupState = initialState,
  action: SignupActionTypes,
): SignupState {
  let newState: SignupState;

  switch (action.type) {
    case SIGNUP_DETECT_CURRENCY:
      newState = reduceDetectCurrency(state, action);
      break;

    case SIGNUP_SET_PLAN:
      newState = reduceSetPlan(state, action);
      break;

    case SIGNUP_SET_INTERVAL:
      newState = reduceSetInterval(state, action);
      break;

    case SIGNUP_SET_CURRENCY:
      newState = reduceSetCurrency(state, action);
      break;

    case SIGNUP_SUBMIT_ACCOUNT:
      newState = reduceSubmitAccount(state, action);
      break;

    case SIGNUP_SELECT_ADDON:
      newState = reduceSelectAddon(state, action);
      break;

    case SIGNUP_REMOVE_ADDON:
      newState = reduceRemoveAddon(state, action);
      break;

    case SIGNUP_SUBMIT_ADDONS:
      newState = reduceSubmitAddons(state, action);
      break;

    case SIGNUP_INCREMENT_MULTI_TENANT:
      newState = reduceIncrementMultiTenant(state, action);
      break;

    case SIGNUP_DECREMENT_MULTI_TENANT:
      newState = reduceDecrementMultiTenant(state, action);
      break;

    case SIGNUP_INCREMENT_ENTERPRISE_QUOTES: {
      newState = reduceIncrementEnterpriseQuotes(state, action);
      break;
    }

    case SIGNUP_DECREMENT_ENTERPRISE_QUOTES: {
      newState = reduceDecrementEnterpriseQuotes(state, action);
      break;
    }

    case SIGNUP_SUBMIT_COUPON_CODE:
      newState = reduceSubmitCouponCode(state, action);
      break;

    case SIGNUP_APPLY_COUPON_CODE:
      newState = reduceApplyCouponCode(state, action);
      break;

    case SIGNUP_VALIDATE_EMAIL:
      newState = reduceValidateEmail(state, action);
      break;

    case SIGNUP_VALIDATE_SUBDOMAIN:
      newState = reduceValidateSubdomain(state, action);
      break;

    case SIGNUP_SET_BILLING_COUNTRY:
      newState = reduceSetBillingCountry(state, action);
      break;

    case SIGNUP_SET_BILLING_REGION:
      newState = reduceSetBillingRegion(state, action);
      break;

    case SIGNUP_SUBMIT_PAYMENT:
      newState = reduceSubmitPayment(state, action);
      break;

    case SIGNUP_CREATE_SUBSCRIPTION:
      newState = reduceCreateSubscription(state, action);
      break;

    default:
      return state;
  }

  // debugLog(
  //   `Reduced ${action.type}:`,
  //   action,
  //   "Old State:",
  //   state,
  //   "New State:",
  //   newState
  // );

  return newState;
}

function reduceDetectCurrency(
  state: SignupState,
  action: SignupDetectCurrencyAction,
): SignupState {
  if (action.status === AsyncActionStatus.SUCCEEDED) {
    if (
      action.payload.parsedBody &&
      'country_code' in action.payload.parsedBody
    ) {
      let currency = state.currency;
      let detectedCountryCode = action.payload.parsedBody.country_code;

      if (!state.currencySet) {
        if (detectedCountryCode in Countries) {
          if ('currency' in Countries[detectedCountryCode]) {
            const cur = Countries[detectedCountryCode].currency;
            if (cur) currency = cur;
          }
        }
      }

      return {
        ...state,
        detectedCountryISO: detectedCountryCode,
        currency: currency,
        currencySet: true,
      };
    }
  }

  return {
    ...state,
  };
}

function reduceSetPlan(
  state: SignupState,
  action: SignupSetPlanAction,
): SignupState {
  let plan = SignupPlans.standard;
  if (action.payload) {
    let planID = action.payload.toLowerCase();
    if (planID in SignupPlans) plan = plan = SignupPlans[planID];
  }
  return {
    ...state,
    plan: plan,
    planSet: true,
    addons: {},
    amounts: calculatePaymentAmounts(
      plan,
      null,
      state.onboardingCoupon,
      state.planCoupon,
      state.interval,
      state.form.billingCountryISO,
      state.form.billingRegionISO,
      state.chooseAddonsView.multiTenantUsers,
    ),
  };
}

function reduceSetInterval(
  state: SignupState,
  action: SignupSetIntervalAction,
): SignupState {
  let interval = PaymentInterval.MONTHLY;
  if (action.payload) {
    let intervalID = action.payload.toLowerCase();
    if (intervalID === PaymentInterval.YEARLY)
      interval = PaymentInterval.YEARLY;
  }

  return {
    ...state,
    interval: interval,
    intervalSet: true,
    couponCode: null,
    onboardingCoupon: null,
    planCoupon: null,
    amounts: calculatePaymentAmounts(
      state.plan,
      Object.values(state.addons),
      state.onboardingCoupon,
      state.planCoupon,
      interval,
      state.form.billingCountryISO,
      state.form.billingRegionISO,
      state.chooseAddonsView.multiTenantUsers,
    ),
  };
}

function reduceSetCurrency(
  state: SignupState,
  action: SignupSetCurrencyAction,
): SignupState {
  if (!action.payload || action.payload === '') return state;
  let currency = Currencies.USD;
  if (action.payload) {
    const iso = action.payload.toUpperCase();
    if (iso in Currencies) {
      currency = Currencies[iso];
    }
  }
  return {
    ...state,
    currency: currency,
    currencySet: true,
  };
}

function reduceSubmitAccount(
  state: SignupState,
  action: SignupSubmitAccountAction,
): SignupState {
  if (action.status === AsyncActionStatus.STARTED) {
    return {
      ...state,
      createAccountView: {
        ...state.createAccountView,
        isLoading: true,
      },
    };
  } else if (action.status === AsyncActionStatus.SUCCEEDED) {
    return {
      ...state,
      form: {
        ...state.form,
        ...action.payload,
      },
      createAccountView: {
        ...state.createAccountView,
        formIsValid: true,
        errorMessage: null,
        isLoading: false,
      },
    };
  } else if (action.status === AsyncActionStatus.FAILED) {
    let apiErrors = getAPIErrors(action.error);

    if (apiErrors) {
      debugError(action);
      return {
        ...state,
        createAccountView: {
          ...state.createAccountView,
          errorMessage: apiErrorMessage(apiErrors),
          isLoading: false,
        },
      };
    } else {
      return {
        ...state,
        createAccountView: {
          ...state.createAccountView,
          errorMessage:
            'Request failed; please try again or contact support@quoter.com if the problem persists.',
          isLoading: false,
        },
      };
    }
  } else {
    return state;
  }
}

function reduceSelectAddon(
  state: SignupState,
  action: SignupSelectAddonAction,
): SignupState {
  if (action.payload in Addons) {
    let addons = {
      ...state.addons,
    };

    if (action.payload in Addons) {
      addons[action.payload] = Addons[action.payload];
    }

    let quantity = state.chooseAddonsView.multiTenantUsers;

    if (action.payload === AddonID.ENTERPRISE_QUOTES) {
      quantity = state.chooseAddonsView.enterpriseQuotes;
    }

    return {
      ...state,
      addons: addons,
      amounts: calculatePaymentAmounts(
        state.plan,
        Object.values(addons),
        state.onboardingCoupon,
        state.planCoupon,
        state.interval,
        state.form.billingCountryISO,
        state.form.billingRegionISO,
        quantity,
      ),
      subscriptionID: null,
    };
  } else {
    return state;
  }
}

function reduceRemoveAddon(
  state: SignupState,
  action: SignupRemoveAddonAction,
): SignupState {
  let addons = { ...state.addons };
  delete addons[action.payload];

  let quantity = state.chooseAddonsView.multiTenantUsers;
  if (action.payload === AddonID.MULTI_TENANT_USERS) {
    quantity = state.chooseAddonsView.enterpriseQuotes;
  }

  return {
    ...state,
    addons: addons,
    amounts: calculatePaymentAmounts(
      state.plan,
      Object.values(addons),
      state.onboardingCoupon,
      state.planCoupon,
      state.interval,
      state.form.billingCountryISO,
      state.form.billingRegionISO,
      quantity,
    ),
    subscriptionID: null,
  };
}

function reduceSubmitAddons(
  state: SignupState,
  action: SignupSubmitAddonsAction,
): SignupState {
  if (action.status === AsyncActionStatus.STARTED) {
    return {
      ...state,
      chooseAddonsView: {
        ...state.chooseAddonsView,
        isLoading: true,
      },
      amounts: calculatePaymentAmounts(
        state.plan,
        Object.values(state.addons),
        state.onboardingCoupon,
        state.planCoupon,
        state.interval,
        state.form.billingCountryISO,
        state.form.billingRegionISO,
        state.chooseAddonsView.multiTenantUsers,
      ),
    };
  } else {
    return {
      ...state,
      chooseAddonsView: {
        ...state.chooseAddonsView,
        isLoading: false,
      },
    };
  }
}

function reduceIncrementMultiTenant(
  state: SignupState,
  action: SignupIncrementMultiTenantAction,
): SignupState {
  let incrementSize = 10;

  if (
    isValidMultiTenantUserCount(
      state.chooseAddonsView.multiTenantUsers + incrementSize,
    )
  ) {
    return {
      ...state,
      amounts: calculatePaymentAmounts(
        state.plan,
        Object.values(state.addons),
        state.onboardingCoupon,
        state.planCoupon,
        state.interval,
        state.form.billingCountryISO,
        state.form.billingRegionISO,
        state.chooseAddonsView.multiTenantUsers + incrementSize,
      ),
      chooseAddonsView: {
        ...state.chooseAddonsView,
        multiTenantUsers:
          state.chooseAddonsView.multiTenantUsers + incrementSize,
      },
    };
  } else {
    return {
      ...state,
      amounts: calculatePaymentAmounts(
        state.plan,
        Object.values(state.addons),
        state.onboardingCoupon,
        state.planCoupon,
        state.interval,
        state.form.billingCountryISO,
        state.form.billingRegionISO,
        (state.chooseAddonsView.multiTenantUsers % 10) +
          state.chooseAddonsView.multiTenantUsers,
      ),
      chooseAddonsView: {
        ...state.chooseAddonsView,
        multiTenantUsers:
          (state.chooseAddonsView.multiTenantUsers % 10) +
          state.chooseAddonsView.multiTenantUsers,
      },
    };
  }
}

function reduceDecrementMultiTenant(
  state: SignupState,
  action: SignupDecrementMultiTenantAction,
): SignupState {
  let decrementSize = 10;

  if (
    isValidMultiTenantUserCount(
      state.chooseAddonsView.multiTenantUsers - decrementSize,
    )
  ) {
    return {
      ...state,
      amounts: calculatePaymentAmounts(
        state.plan,
        Object.values(state.addons),
        state.onboardingCoupon,
        state.planCoupon,
        state.interval,
        state.form.billingCountryISO,
        state.form.billingRegionISO,
        state.chooseAddonsView.multiTenantUsers - decrementSize,
      ),
      chooseAddonsView: {
        ...state.chooseAddonsView,
        multiTenantUsers:
          state.chooseAddonsView.multiTenantUsers - decrementSize,
      },
    };
  } else {
    return {
      ...state,
      amounts: calculatePaymentAmounts(
        state.plan,
        Object.values(state.addons),
        state.onboardingCoupon,
        state.planCoupon,
        state.interval,
        state.form.billingCountryISO,
        state.form.billingRegionISO,
        state.chooseAddonsView.multiTenantUsers -
          (state.chooseAddonsView.multiTenantUsers % 10),
      ),
      chooseAddonsView: {
        ...state.chooseAddonsView,
        multiTenantUsers:
          state.chooseAddonsView.multiTenantUsers -
          (state.chooseAddonsView.multiTenantUsers % 10),
      },
    };
  }
}
function reduceIncrementEnterpriseQuotes(
  state: SignupState,
  action: SignupIncrementEnterpriseQuotesAction,
): SignupState {
  const incrementSize = 50;

  if (
    isValidEnterpriseQuotesCount(
      state.chooseAddonsView.enterpriseQuotes + incrementSize,
    )
  ) {
    return {
      ...state,
      amounts: calculatePaymentAmounts(
        state.plan,
        Object.values(state.addons),
        state.onboardingCoupon,
        state.planCoupon,
        state.interval,
        state.form.billingCountryISO,
        state.form.billingRegionISO,
        state.chooseAddonsView.enterpriseQuotes + incrementSize,
      ),
      chooseAddonsView: {
        ...state.chooseAddonsView,
        enterpriseQuotes:
          state.chooseAddonsView.enterpriseQuotes + incrementSize,
      },
    };
  } else {
    return {
      ...state,
      amounts: calculatePaymentAmounts(
        state.plan,
        Object.values(state.addons),
        state.onboardingCoupon,
        state.planCoupon,
        state.interval,
        state.form.billingCountryISO,
        state.form.billingRegionISO,
        (state.chooseAddonsView.enterpriseQuotes % 50) +
          state.chooseAddonsView.enterpriseQuotes,
      ),
      chooseAddonsView: {
        ...state.chooseAddonsView,
        enterpriseQuotes:
          (state.chooseAddonsView.enterpriseQuotes % 50) +
          state.chooseAddonsView.enterpriseQuotes,
      },
    };
  }
}

function reduceDecrementEnterpriseQuotes(
  state: SignupState,
  action: SignupDecrementEnterpriseQuotesAction,
): SignupState {
  const decrementSize = 50;

  if (
    isValidEnterpriseQuotesCount(
      state.chooseAddonsView.enterpriseQuotes - decrementSize,
    )
  ) {
    return {
      ...state,
      amounts: calculatePaymentAmounts(
        state.plan,
        Object.values(state.addons),
        state.onboardingCoupon,
        state.planCoupon,
        state.interval,
        state.form.billingCountryISO,
        state.form.billingRegionISO,
        state.chooseAddonsView.enterpriseQuotes - decrementSize,
      ),
      chooseAddonsView: {
        ...state.chooseAddonsView,
        enterpriseQuotes:
          state.chooseAddonsView.enterpriseQuotes - decrementSize,
      },
    };
  } else {
    return {
      ...state,
      amounts: calculatePaymentAmounts(
        state.plan,
        Object.values(state.addons),
        state.onboardingCoupon,
        state.planCoupon,
        state.interval,
        state.form.billingCountryISO,
        state.form.billingRegionISO,
        state.chooseAddonsView.enterpriseQuotes -
          (state.chooseAddonsView.enterpriseQuotes % 50),
      ),
      chooseAddonsView: {
        ...state.chooseAddonsView,
        enterpriseQuotes:
          state.chooseAddonsView.enterpriseQuotes -
          (state.chooseAddonsView.enterpriseQuotes % 50),
      },
    };
  }
}

function reduceSubmitCouponCode(
  state: SignupState,
  action: SignupSubmitCouponCodeAction,
): SignupState {
  return {
    ...state,
    couponCode: action.payload,
  };
}

function reduceApplyCouponCode(
  state: SignupState,
  action: SignupApplyCouponCodeAction,
): SignupState {
  if (action.status === AsyncActionStatus.STARTED) {
    return {
      ...state,
      amounts: calculatePaymentAmounts(
        state.plan,
        Object.values(state.addons),
        null,
        null,
        state.interval,
        state.form.billingCountryISO,
        state.form.billingRegionISO,
        state.chooseAddonsView.multiTenantUsers,
      ),
      couponView: {
        ...state.couponView,
        isLoading: true,
        couponCodeInvalid: false,
        errorMessage: null,
      },
    };
  } else if (action.status === AsyncActionStatus.SUCCEEDED) {
    let apiCouponValidation = getAPIResource(action.payload);

    if (apiCouponValidation) {
      let onboardingCoupon = convertAPIOnboardingCoupon(
        apiCouponValidation.onboarding,
      );
      let planCoupon = convertAPIPlanCoupon(apiCouponValidation.plan);
      return {
        ...state,
        onboardingCoupon: onboardingCoupon,
        planCoupon: planCoupon,
        amounts: calculatePaymentAmounts(
          state.plan,
          Object.values(state.addons),
          onboardingCoupon,
          planCoupon,
          state.interval,
          state.form.billingCountryISO,
          state.form.billingRegionISO,
          state.chooseAddonsView.multiTenantUsers,
        ),
        couponView: {
          ...state.couponView,
          isLoading: false,
          couponCodeInvalid: false,
          errorMessage: null,
        },
        subscriptionID: null,
      };
    }

    // Unexpected payload; no errors or resources
    return {
      ...state,
      couponView: {
        ...state.couponView,
        isLoading: false,
        couponCodeInvalid: true,
        errorMessage: 'Invalid response from server',
      },
    };
  } else if (action.status === AsyncActionStatus.FAILED) {
    let apiErrors = getAPIErrors(action.error);

    if (apiErrors) {
      return {
        ...state,
        couponCode: null,
        couponView: {
          ...state.couponView,
          isLoading: false,
          couponCodeInvalid: true,
          errorMessage: apiErrorMessage(apiErrors),
        },
      };
    } else {
      return {
        ...state,
        couponCode: null,
        onboardingCoupon: null,
        planCoupon: null,
        amounts: calculatePaymentAmounts(
          state.plan,
          Object.values(state.addons),
          null,
          null,
          state.interval,
          state.form.billingCountryISO,
          state.form.billingRegionISO,
          state.chooseAddonsView.multiTenantUsers,
        ),
        couponView: {
          ...state.couponView,
          isLoading: false,
          couponCodeInvalid: true,
          errorMessage:
            'Request failed; please try again or contact support@quoter.com if the problem persists.',
        },
      };
    }
  } else {
    return state;
  }
}

function reduceValidateEmail(
  state: SignupState,
  action: SignupValidateEmailAction,
): SignupState {
  if (action.status === AsyncActionStatus.STARTED) {
    return {
      ...state,
      createAccountView: {
        ...state.createAccountView,
        isLoading: true,
        emailInvalid: false,
        emailErrorMessage: null,
      },
    };
  } else if (action.status === AsyncActionStatus.SUCCEEDED) {
    return {
      ...state,
      createAccountView: {
        ...state.createAccountView,
        isLoading: false,
        emailInvalid: false,
        emailErrorMessage: null,
      },
    };
  } else if (action.status === AsyncActionStatus.FAILED) {
    let apiErrors = getAPIErrors(action.error);

    if (apiErrors) {
      return {
        ...state,
        createAccountView: {
          ...state.createAccountView,
          isLoading: false,
          emailInvalid: true,
          emailErrorMessage: apiErrorMessage(apiErrors),
        },
      };
    }

    return {
      ...state,
      createAccountView: {
        ...state.createAccountView,
        isLoading: false,
        emailInvalid: true,
        emailErrorMessage:
          'Request failed; please try again or contact support@quoter.com if the problem persists.',
      },
    };
  }
  return state;
}

function reduceValidateSubdomain(
  state: SignupState,
  action: SignupValidateSubdomainAction,
): SignupState {
  if (action.status === AsyncActionStatus.STARTED) {
    return {
      ...state,
      createAccountView: {
        ...state.createAccountView,
        isLoading: true,
        subdomainInvalid: false,
        subdomainErrorMessage: null,
      },
    };
  } else if (action.status === AsyncActionStatus.SUCCEEDED) {
    return {
      ...state,
      createAccountView: {
        ...state.createAccountView,
        isLoading: false,
        subdomainInvalid: false,
        subdomainErrorMessage: null,
      },
    };
  } else if (action.status === AsyncActionStatus.FAILED) {
    let apiErrors = getAPIErrors(action.error);

    if (apiErrors) {
      return {
        ...state,
        createAccountView: {
          ...state.createAccountView,
          isLoading: false,
          subdomainInvalid: true,
          subdomainErrorMessage: apiErrorMessage(apiErrors),
        },
      };
    }

    return {
      ...state,
      createAccountView: {
        ...state.createAccountView,
        isLoading: false,
        subdomainInvalid: true,
        subdomainErrorMessage:
          'Request failed; please try again or contact support@quoter.com if the problem persists.',
      },
    };
  }
  return state;
}

function reduceSetBillingCountry(
  state: SignupState,
  action: SignupSetBillingCountryAction,
): SignupState {
  let currency = state.currency;
  // If the currency was not set (e.g. via auto-detection or URL), and the specified country has an
  // assigned currency, use it.
  if (!state.currencySet) {
    if (action.payload in Countries) {
      if ('currency' in Countries[action.payload]) {
        const cur = Countries[action.payload].currency;
        if (cur) currency = cur;
      }
    }
  }

  return {
    ...state,
    form: {
      ...state.form,
      billingCountryISO: action.payload,
      billingRegionISO: null,
    },
    currency: currency,
    amounts: calculatePaymentAmounts(
      state.plan,
      Object.values(state.addons),
      state.onboardingCoupon,
      state.planCoupon,
      state.interval,
      action.payload,
      null,
      state.chooseAddonsView.multiTenantUsers,
    ),
  };
}

function reduceSetBillingRegion(
  state: SignupState,
  action: SignupSetBillingRegionAction,
): SignupState {
  return {
    ...state,
    form: {
      ...state.form,
      billingRegionISO: action.payload,
    },
    amounts: calculatePaymentAmounts(
      state.plan,
      Object.values(state.addons),
      state.onboardingCoupon,
      state.planCoupon,
      state.interval,
      state.form.billingCountryISO,
      action.payload,
      state.chooseAddonsView.multiTenantUsers,
    ),
  };
}

function reduceSubmitPayment(
  state: SignupState,
  action: SignupSubmitPaymentAction,
): SignupState {
  return {
    ...state,
    form: {
      ...state.form,
      ...action.payload,
    },
  };
}

function reduceCreateSubscription(
  state: SignupState,
  action: SignupCreateSubscriptionAction,
): SignupState {
  if (action.status === AsyncActionStatus.STARTED) {
    return {
      ...state,
      paymentView: {
        ...state.paymentView,
        isLoading: true,
        errorMessage: null,
      },
    };
  } else if (action.status === AsyncActionStatus.SUCCEEDED) {
    let apiSignup = getAPIResource(action.payload);

    if (apiSignup) {
      if (apiSignup.status === 'active') {
        return {
          ...state,
          paymentView: {
            ...state.paymentView,
            isLoading: false,
            errorMessage: null,
          },
          subscriptionID: apiSignup.subscription_id,
          signupComplete: true,
        };
      } else {
        return {
          ...state,
          paymentView: {
            ...state.paymentView,
            isLoading: false,
            errorMessage: apiSignup.payment_error_message,
          },
          subscriptionID: apiSignup.subscription_id,
        };
      }
    }

    // Unexpected payload; no errors or resources
    return {
      ...state,
      paymentView: {
        ...state.paymentView,
        isLoading: false,
        errorMessage: 'Invalid response from server',
      },
    };
  } else if (action.status === AsyncActionStatus.FAILED) {
    let apiErrors = getAPIErrors(action.error);

    if (apiErrors) {
      return {
        ...state,
        paymentView: {
          ...state.paymentView,
          isLoading: false,
          errorMessage: apiErrorMessage(apiErrors),
        },
        signupComplete: false,
      };
    } else {
      return {
        ...state,
        paymentView: {
          ...state.paymentView,
          isLoading: false,
          errorMessage:
            'Request failed; please try again or contact support@quoter.com if the problem persists.',
        },
        signupComplete: false,
      };
    }
  } else {
    return state;
  }
}
