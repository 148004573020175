import { ReactStripeElements } from 'react-stripe-elements';
import { AsyncActionStatus } from 'store/asyncActions';
import { getCustomer, postCreditCard } from 'store/customer/actions';
import { BillingForm } from 'store/forms';
import { ThunkyDispatch } from 'store/types';
import { PaymentMethodFormActions, clearMessagesAction } from './types';

export const onSubmitUpdatePaymentMethod = (
  stripe: ReactStripeElements.StripeProps,
  formData: BillingForm,
) => {
  return async (dispatch: ThunkyDispatch) => {
    const postCreditCardResult = await dispatch(
      postCreditCard(stripe, formData),
    );

    if (postCreditCardResult.status === AsyncActionStatus.SUCCEEDED) {
      return dispatch(getCustomer());
    }
  };
};

export const clearMessages = (): clearMessagesAction => ({
  type: PaymentMethodFormActions.CLEAR_MESSAGES,
});
