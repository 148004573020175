import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom';
import Navigation from './Navigation';
import ProtectedRoute from './ProtectedRoute';
import CreateAccountView from './CreateAccountView';
import ChooseAddonsView from './ChooseAddonsView';
import PaymentView from './PaymentView';
import NotFoundView from './NotFoundView';
import { default as YourSubscription } from 'components/YourSubscription';
import Testimonial from './Testimonial';
import logo from '../../assets/logo.svg';
import './index.scss';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { initializePartnerStack } from '../../utils/partnerStack';
import {
  detectCurrency,
  setInterval,
  setPlan,
  validateEmail,
  validateSubdomain,
  submitAccount,
  selectAddon,
  removeAddon,
  submitAddons,
  incrementMultiTenant,
  decrementMultiTenant,
  incrementEnterpriseQuotes,
  decrementEnterpriseQuotes,
  setBillingCountry,
  setBillingRegion,
  applyCouponCode,
  submitPayment,
  setCurrency,
} from '../../store/signup/actions';

import { SignupState } from 'store/signup/types';
import { Addon } from 'store/plans/types';
import { PaymentInterval } from 'store/payment';
import { ReactStripeElements } from 'react-stripe-elements';
import ThankYouView from './ThankYouView';
import { BillingForm } from 'store/forms';
import { APP_QUOTER_URL } from 'store/api';

let psKey = process.env.REACT_APP_PARTNER_STACK_PUBLIC_KEY;
// debug initialization in console with `growsumo`
if (psKey && psKey !== '') initializePartnerStack(psKey);

interface SignupProps {
  signup: SignupState;
  detectCurrency: () => Promise<any>;
  setPlan: typeof setPlan;
  setInterval: typeof setInterval;
  setCurrency: typeof setCurrency;
  validateEmail: (email: string) => Promise<any>;
  validateSubdomain: (subdomain: string) => Promise<any>;
  submitAccount: (form: BillingForm) => Promise<any>;
  selectAddon: typeof selectAddon;
  removeAddon: typeof removeAddon;
  submitAddons: (addons: Addon[]) => Promise<any>;
  incrementMultiTenant: typeof incrementMultiTenant;
  decrementMultiTenant: typeof decrementMultiTenant;
  incrementEnterpriseQuotes: typeof incrementEnterpriseQuotes;
  decrementEnterpriseQuotes: typeof decrementEnterpriseQuotes;
  applyCouponCode: (
    currencyISO: string,
    interval: PaymentInterval,
    couponCode: string,
  ) => Promise<any>;
  setBillingCountry: typeof setBillingCountry;
  setBillingRegion: typeof setBillingRegion;
  submitPayment: (
    form: BillingForm,
    stripe: ReactStripeElements.StripeProps,
  ) => Promise<any>;
}

interface SignupRouteComponentProps
  extends RouteComponentProps<{
    plan?: string;
    interval?: string;
    currencyISO?: string;
  }> {}

type SignupWithRouterProps = SignupProps & SignupRouteComponentProps;

const NavigationWithRouter = withRouter(Navigation);

const Signup = (props: SignupWithRouterProps) => {
  const {
    signup: {
      detectedCountryISO,
      form,
      plan,
      planSet,
      addons,
      couponCode,
      onboardingCoupon,
      planCoupon,
      interval,
      intervalSet,
      currency,
      currencySet,
      amounts,
      createAccountView,
      chooseAddonsView,
      paymentView,
      couponView,
      signupComplete,
    },
    detectCurrency,
    setPlan,
    setInterval,
    setCurrency,
    validateEmail,
    validateSubdomain,
    submitAccount,
    selectAddon,
    removeAddon,
    submitAddons,
    incrementMultiTenant,
    decrementMultiTenant,
    decrementEnterpriseQuotes,
    incrementEnterpriseQuotes,
    applyCouponCode,
    setBillingCountry,
    setBillingRegion,
    submitPayment,
  } = props;

  let enableAddons = !signupComplete; // && createAccountView.formIsValid;
  let enablePayment = !signupComplete && createAccountView.formIsValid;
  let showSubscriptionSummary = true; // enablePayment && location.pathname === '/payment';

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized) {
      if (!currencySet) detectCurrency();
      setInitialized(true);
    }
  }, [initialized, setInitialized, currencySet, detectCurrency]);

  return (
    <div className="Signup">
      <nav className="navbar navbar-expand-lg navbar-light">
        <span className="navbar-brand">
          <img
            src={logo}
            className="d-inline-block align-top"
            height="38"
            alt="logo"
          />
        </span>
        {!signupComplete && (
          <button
            className="btn btn-outline-primary d-lg-none d-block"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Summary
            <i className="fas fa-shopping-cart pl-1" />
          </button>
        )}
        <div className="d-none d-lg-block mr-auto">
          {!signupComplete && (
            <NavigationWithRouter
              enableAddons={enableAddons}
              enablePayment={enablePayment}
            />
          )}
        </div>
        <span className="d-none d-lg-inline-block navbar-text text-right small">
          Already using Quoter? <a href={APP_QUOTER_URL}>Sign in</a>
        </span>
      </nav>
      <div className="container-fluid">
        <div className="row">
          {!signupComplete && (
            <div
              id="navbarNav"
              className="Sidebar d-lg-block col-lg-3 collapse"
            >
              <YourSubscription
                detectedCountryISO={detectedCountryISO}
                plan={plan}
                addons={addons}
                couponCode={couponCode}
                onboardingCoupon={onboardingCoupon}
                planCoupon={planCoupon}
                interval={interval}
                currency={currency}
                amounts={amounts}
                showSubscriptionSummary={showSubscriptionSummary}
                showProratedSting={false}
                couponErrorMessage={couponView.errorMessage}
                applyCouponCode={applyCouponCode}
                couponIsLoading={couponView.isLoading}
                couponCodeInvalid={couponView.couponCodeInvalid}
                multiTenantUsers={chooseAddonsView.multiTenantUsers}
                enterpriseQuotes={chooseAddonsView.enterpriseQuotes}
              />
              <Testimonial />
            </div>
          )}
          <div className={signupComplete ? 'col-lg-12' : 'col-lg-9'}>
            <div className="row collapse show">
              <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 pt-5">
                <Switch>
                  <Route
                    path="/:plan(basic|standard|pro|enterprise)?/:interval(monthly|yearly)?/:currencyISO(eur|gbp|usd)?"
                    exact
                    render={(
                      routeProps: SignupRouteComponentProps &
                        RouteComponentProps,
                    ) => (
                      <Redirect
                        to={`/a${
                          routeProps.match.params.plan
                            ? '/' + routeProps.match.params.plan
                            : ''
                        }${
                          routeProps.match.params.interval
                            ? '/' + routeProps.match.params.interval
                            : ''
                        }${
                          routeProps.match.params.currencyISO
                            ? '/' + routeProps.match.params.currencyISO
                            : ''
                        }${routeProps.location.search}`}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path="/a/:plan(basic|standard|pro|enterprise)?/:interval(monthly|yearly)?/:currencyISO(eur|gbp|usd)?"
                    exact
                    allowAccess={!signupComplete}
                    deniedAccessRedirectPath="/thank-you"
                    render={(routeProps: SignupRouteComponentProps) => (
                      <CreateAccountView
                        routePlanID={routeProps.match.params.plan}
                        plan={plan}
                        planSet={planSet}
                        routeInterval={routeProps.match.params.interval}
                        interval={interval}
                        intervalSet={intervalSet}
                        routeCurrencyISO={routeProps.match.params.currencyISO}
                        currency={currency}
                        currencySet={currencySet}
                        form={form}
                        formIsValid={createAccountView.formIsValid}
                        isLoading={createAccountView.isLoading}
                        emailInvalid={createAccountView.emailInvalid}
                        emailErrorMessage={createAccountView.emailErrorMessage}
                        subdomainInvalid={createAccountView.subdomainInvalid}
                        subdomainErrorMessage={
                          createAccountView.subdomainErrorMessage
                        }
                        setPlan={setPlan}
                        setInterval={setInterval}
                        setCurrency={setCurrency}
                        applyCouponCode={applyCouponCode}
                        validateEmail={validateEmail}
                        validateSubdomain={validateSubdomain}
                        submitAccount={submitAccount}
                        history={routeProps.history}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path="/addons/"
                    allowAccess={enableAddons}
                    deniedAccessRedirectPath="/a"
                    render={(routeProps: SignupRouteComponentProps) => (
                      <ChooseAddonsView
                        plan={plan}
                        addons={addons}
                        interval={interval}
                        currency={currency}
                        createAccountComplete={createAccountView.formIsValid}
                        chooseAddonsView={chooseAddonsView}
                        selectAddon={selectAddon}
                        removeAddon={removeAddon}
                        submitAddons={submitAddons}
                        incrementMultiTenant={incrementMultiTenant}
                        decrementMultiTenant={decrementMultiTenant}
                        decrementEnterpriseQuotes={decrementEnterpriseQuotes}
                        incrementEnterpriseQuotes={incrementEnterpriseQuotes}
                        history={routeProps.history}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path="/payment/"
                    allowAccess={enablePayment}
                    deniedAccessRedirectPath={'/addons'}
                    render={(routeProps: SignupRouteComponentProps) => (
                      <PaymentView
                        form={form}
                        amounts={amounts}
                        currency={currency}
                        interval={interval}
                        isLoading={paymentView.isLoading}
                        errorMessage={paymentView.errorMessage}
                        signupComplete={signupComplete}
                        setBillingCountry={setBillingCountry}
                        setBillingRegion={setBillingRegion}
                        submitPayment={submitPayment}
                        history={routeProps.history}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path="/thank-you/"
                    allowAccess={signupComplete}
                    deniedAccessRedirectPath="/a"
                    render={() => <ThankYouView form={form} />}
                  />
                  <Route component={NotFoundView} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const SignupWithRouter = withRouter(Signup);
const SignupContainer = (props: SignupProps) => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <SignupWithRouter {...props} />
  </BrowserRouter>
);

const mapStateToProps = (state: AppState) => ({
  signup: state.signup,
});

const mapDispatchToProps = {
  detectCurrency,
  setPlan,
  setInterval,
  setCurrency,
  validateEmail,
  validateSubdomain,
  submitAccount,
  selectAddon,
  removeAddon,
  submitAddons,
  incrementMultiTenant,
  decrementMultiTenant,
  incrementEnterpriseQuotes,
  decrementEnterpriseQuotes,
  setBillingCountry,
  setBillingRegion,
  applyCouponCode,
  submitPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer);
