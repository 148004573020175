import { IHttpResponse } from '../utils/fetch';
import { AddonID, PlanID } from 'store/plans/types';

export const API_BASE_URL: string = process.env.REACT_APP_VAULT_API_URL || '';

export const APP_QUOTER_URL: string = process.env.REACT_APP_QUOTER_URL || '';

export type IAPIResponse<T> = T & { errors: IAPIError[] };

export interface IAPIError {
  status: string;
  title: string;
  key: string;
  detail?: string;
  source?: IAPIErrorSource;
}

export interface IAPIErrorSource {
  pointer: string;
}

export interface IAPIAddon {
  id: string;
  name: string;
  pricing: {
    [id: string]: IAPIPriceInterval;
  };
  icon: string;
  description: string;
  features: string[];
}

export interface IAPICreditCard {
  id: string;
  billing_cardholder: string;
  credit_card_last_4: string;
  credit_card_expiry_month: number;
  credit_card_expiry_year: number;
  brand: string;
}

export interface IAPICouponValidation {
  onboarding: IAPIOnboardingCoupon | null;
  plan: IAPIPlanCoupon | null;
}

export interface IAPIOnboardingCoupon {
  id: string;
  name: string;
  amount_off_in_cents: number | null;
  percentage_off: number | null;
}

export interface IAPIPlanCoupon {
  id: string;
  name: string;
  amount_off_in_cents: number | null;
  percentage_off: number | null;
  duration_type: string;
  duration_in_months: number | null;
}

export interface IAPICustomer {
  id: string;
  company: string;
  currency: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  billing_address1: string;
  billing_address2: string | null;
  billing_city: string;
  billing_country_iso: string;
  billing_region_iso: string | null;
  billing_postal_code: string;
  payment_sources: IAPIPaymentSource[] | null;
  balance_in_cents: number;
}

export interface IAPIInvoice {
  id: string;
  pdf_link: string;
  amount_due_in_cents: number;
  amount_paid_in_cents: number | null;
  subtotal_in_cents: number;
  tax_in_cents: number | null;
  total_in_cents: number;
  period_end: number;
  currency: string;
  number: string;
  paid: boolean;
  created: number;
}

export interface IAPIPaymentSource {
  id: string;
  billing_cardholder: string;
  credit_card_last_4: string;
  credit_card_expiry_month: string;
  credit_card_expiry_year: string;
  brand: string;
  default: boolean;
}

export interface IAPIPlan {
  id: string;
  name: string;
  version: string;
  pricing: {
    [id: string]: IAPIPriceInterval;
  };
  num_quotes_included: number;
  unlimited_quotes: boolean;
  num_users_included: number;
  unlimited_users: boolean;
  features_included: {
    [name in AddonID]: boolean;
  };
  available_addons: {
    [name in AddonID]: boolean;
  };
}

export interface IAPIPriceInterval {
  unit_price_in_cents: number;
  price_tiers: IAPIPriceTier[];
}

export interface IAPIPriceTier {
  up_to_quantity: number;
  up_to_infinity: boolean;
  flat_price: number;
  unit_price: number;
}

export interface IAPISignup {
  subscription_id: string;
  status: string;
  amount_owing_in_cents: number;
  payment_error_message?: string;
}

export interface IAPIPlansAndAddons {
  plans: {
    [name in PlanID]: IAPIPlan;
  };
  addons: {
    [name in AddonID]: IAPIAddon;
  };
}

export interface IAPISubscription {
  id: string;
  customer_id: string;
  plan: IAPISubsciptionPlan;
  addons: IAPISubscriptionAddon[];
  coupon: IAPIPlanCoupon | null;
  payment_interval: string;
  commitment: string;
  status: string;
  amount_owing_in_cents: number;
  payment_error_message: string | null;
  mrr_in_cents: number;
  price_in_cents: number;
  currency_iso: string;
  current_period_starts_at: string;
  cancelled_at: string | null;
  current_period_ends_at: string;
}

export interface IAPISubsciptionPlan {
  id: string;
  name: string;
  version: string;
  price_in_cents: number;
  quantity: number;
}

export interface IAPISubscriptionAddon {
  id: string;
  name: string;
  price_in_cents: number;
  quantity: number;
}

export interface IAPISubscriptionPreview {
  coupon: IAPIPlanCoupon | null;
  discount_amount_in_cents: number | null;
  payment_interval: string;
  prorated_subtotal_in_cents: number;
  prorated_taxes: IAPISubscriptionPreviewTax[] | null;
  prorated_total_in_cents: number;
  recurring_subtotal_in_cents: number;
  recurring_total_in_cents: number;
  recurring_taxes: IAPISubscriptionPreviewTax[] | null;
}

export interface IAPISubscriptionPreviewTax {
  display_name: string;
  percentage: number;
  amount_in_cents: number;
}

export interface IAPISubsciptionPayAmountOwing {}

export function getAPIErrors(
  response: IHttpResponse<IAPIResponse<any>>,
): IAPIError[] | undefined {
  if (response.parsedBody) {
    if (response.parsedBody.errors) {
      return response.parsedBody.errors;
    }
  }
}

export function getAPIResource<T>(
  response: IHttpResponse<IAPIResponse<T>>,
): T | undefined {
  if (response.parsedBody) {
    if (!response.parsedBody.hasOwnProperty('errors')) {
      return response.parsedBody as T;
    }
  }
}

export function apiErrorMessage(apiErrors: IAPIError[]): string {
  return apiErrors.reduce((message: string, apiError: IAPIError) => {
    let error = apiError.detail || apiError.title;
    return (error.replace(/[.|\s]*$/, '') + '. ' + message).replace(/\s*$/, '');
  }, '');
}
