export type Country = {
  name: string;
  currency?: Currency;
  regions?: { [iso: string]: Region };
};

export type Region = {
  name: string;
};

export type Currency = {
  name: string;
  iso: string;
  before?: string;
  after?: string;
  thousands?: string;
  decimal: string;
};

export const Currencies: { [iso: string]: Currency } = {
  EUR: {
    name: 'Euro',
    iso: 'EUR',
    before: '€',
    thousands: '.',
    decimal: ',',
  },
  GBP: {
    name: 'Pound Sterling',
    iso: 'GBP',
    before: '£',
    thousands: ',',
    decimal: '.',
  },
  USD: {
    name: 'United States Dollar',
    iso: 'USD',
    before: '$',
    thousands: ',',
    decimal: '.',
  },
};

export const Countries: { [iso: string]: Country } = {
  AF: { name: 'Afghanistan' },
  AX: { name: 'Åland Islands' },
  AL: { name: 'Albania' },
  DZ: { name: 'Algeria' },
  AS: { name: 'American Samoa' },
  AD: { name: 'Andorra' },
  AO: { name: 'Angola' },
  AI: { name: 'Anguilla' },
  AQ: { name: 'Antarctica' },
  AG: { name: 'Antigua and Barbuda' },
  AR: { name: 'Argentina' },
  AM: { name: 'Armenia' },
  AW: { name: 'Aruba' },
  AU: { name: 'Australia' },
  AT: { name: 'Austria', currency: Currencies.EUR },
  AZ: { name: 'Azerbaijan' },
  BS: { name: 'Bahamas' },
  BH: { name: 'Bahrain' },
  BD: { name: 'Bangladesh' },
  BB: { name: 'Barbados' },
  BY: { name: 'Belarus' },
  BE: { name: 'Belgium', currency: Currencies.EUR },
  BZ: { name: 'Belize' },
  BJ: { name: 'Benin' },
  BM: { name: 'Bermuda' },
  BT: { name: 'Bhutan' },
  BO: { name: 'Bolivia' },
  BQ: { name: 'Bonaire, Sint Eustatius and Saba' },
  BA: { name: 'Bosnia and Herzegovina' },
  BW: { name: 'Botswana' },
  BV: { name: 'Bouvet Island' },
  BR: { name: 'Brazil' },
  IO: { name: 'British Indian Ocean Territory' },
  BN: { name: 'Brunei Darussalam' },
  BG: { name: 'Bulgaria', currency: Currencies.EUR },
  BF: { name: 'Burkina Faso' },
  BI: { name: 'Burundi' },
  CV: { name: 'Cabo Verde' },
  KH: { name: 'Cambodia' },
  CM: { name: 'Cameroon' },
  CA: {
    name: 'Canada',
    regions: {
      AB: { name: 'Alberta' },
      BC: { name: 'British Columbia' },
      MB: { name: 'Manitoba' },
      NB: { name: 'New Brunswick' },
      NL: { name: 'Newfoundland and Labrador' },
      NT: { name: 'Northwest Territories' },
      NS: { name: 'Nova Scotia' },
      NU: { name: 'Nunavut' },
      ON: { name: 'Ontario' },
      PE: { name: 'Prince Edward Island' },
      QC: { name: 'Quebec' },
      SK: { name: 'Saskatchewan' },
      YT: { name: 'Yukon Territory' },
    },
  },
  KY: { name: 'Cayman Islands' },
  CF: { name: 'Central African Republic' },
  TD: { name: 'Chad' },
  CL: { name: 'Chile' },
  CN: { name: 'China' },
  CX: { name: 'Christmas Island' },
  CC: { name: 'Cocos (Keeling) Islands' },
  CO: { name: 'Colombia' },
  KM: { name: 'Comoros' },
  CG: { name: 'Congo' },
  CD: { name: 'Congo, Democratic Republic of' },
  CK: { name: 'Cook Islands' },
  CR: { name: 'Costa Rica' },
  CI: { name: "Côte d'Ivoire" },
  HR: { name: 'Croatia', currency: Currencies.EUR },
  CU: { name: 'Cuba' },
  CW: { name: 'Curaçao' },
  CY: { name: 'Cyprus', currency: Currencies.EUR },
  CZ: { name: 'Czech Republic', currency: Currencies.EUR },
  DK: { name: 'Denmark', currency: Currencies.EUR },
  DJ: { name: 'Djibouti' },
  DM: { name: 'Dominica' },
  DO: { name: 'Dominican Republic' },
  EC: { name: 'Ecuador' },
  EG: { name: 'Egypt' },
  SV: { name: 'El Salvador' },
  GQ: { name: 'Equatorial Guinea' },
  ER: { name: 'Eritrea' },
  EE: { name: 'Estonia', currency: Currencies.EUR },
  ET: { name: 'Ethiopia' },
  FK: { name: 'Falkland Islands' },
  FO: { name: 'Faroe Islands' },
  FJ: { name: 'Fiji' },
  FI: { name: 'Finland', currency: Currencies.EUR },
  FR: { name: 'France', currency: Currencies.EUR },
  GF: { name: 'French Guiana' },
  PF: { name: 'French Polynesia' },
  TF: { name: 'French Southern Territories' },
  GA: { name: 'Gabon' },
  GM: { name: 'Gambia' },
  GE: { name: 'Georgia' },
  DE: { name: 'Germany', currency: Currencies.EUR },
  GH: { name: 'Ghana' },
  GI: { name: 'Gibraltar' },
  GR: { name: 'Greece', currency: Currencies.EUR },
  GL: { name: 'Greenland' },
  GD: { name: 'Grenada' },
  GP: { name: 'Guadeloupe' },
  GU: { name: 'Guam' },
  GT: { name: 'Guatemala' },
  GG: { name: 'Guernsey' },
  GN: { name: 'Guinea' },
  GW: { name: 'Guinea-Bissau' },
  GY: { name: 'Guyana' },
  HT: { name: 'Haiti' },
  HM: { name: 'Heard Island and McDonald Islands' },
  VA: { name: 'Holy See' },
  HN: { name: 'Honduras' },
  HK: { name: 'Hong Kong' },
  HU: { name: 'Hungary', currency: Currencies.EUR },
  IS: { name: 'Iceland' },
  IN: { name: 'India' },
  ID: { name: 'Indonesia' },
  IR: { name: 'Iran' },
  IQ: { name: 'Iraq' },
  IE: { name: 'Ireland', currency: Currencies.EUR },
  IM: { name: 'Isle of Man', currency: Currencies.GBP },
  IL: { name: 'Israel' },
  IT: { name: 'Italy', currency: Currencies.EUR },
  JM: { name: 'Jamaica' },
  JP: { name: 'Japan' },
  JE: { name: 'Jersey', currency: Currencies.GBP },
  JO: { name: 'Jordan' },
  KZ: { name: 'Kazakhstan' },
  KE: { name: 'Kenya' },
  KI: { name: 'Kiribati' },
  KP: { name: 'Korea' },
  KR: { name: 'Korea, Republic of' },
  XK: { name: 'Kosovo, Republic of' },
  KW: { name: 'Kuwait' },
  KG: { name: 'Kyrgyzstan' },
  LA: { name: 'Laos' },
  LV: { name: 'Latvia', currency: Currencies.EUR },
  LB: { name: 'Lebanon' },
  LS: { name: 'Lesotho' },
  LR: { name: 'Liberia' },
  LY: { name: 'Libya' },
  LI: { name: 'Liechtenstein' },
  LT: { name: 'Lithuania', currency: Currencies.EUR },
  LU: { name: 'Luxembourg', currency: Currencies.EUR },
  MO: { name: 'Macao' },
  MK: { name: 'Macedonia' },
  MG: { name: 'Madagascar' },
  MW: { name: 'Malawi' },
  MY: { name: 'Malaysia' },
  MV: { name: 'Maldives' },
  ML: { name: 'Mali' },
  MT: { name: 'Malta', currency: Currencies.EUR },
  MH: { name: 'Marshall Islands' },
  MQ: { name: 'Martinique' },
  MR: { name: 'Mauritania' },
  MU: { name: 'Mauritius' },
  YT: { name: 'Mayotte' },
  MX: { name: 'Mexico' },
  FM: { name: 'Micronesia' },
  MD: { name: 'Moldova, Republic of' },
  MC: { name: 'Monaco' },
  MN: { name: 'Mongolia' },
  ME: { name: 'Montenegro' },
  MS: { name: 'Montserrat' },
  MA: { name: 'Morocco' },
  MZ: { name: 'Mozambique' },
  MM: { name: 'Myanmar' },
  NA: { name: 'Namibia' },
  NR: { name: 'Nauru' },
  NP: { name: 'Nepal' },
  NL: { name: 'Netherlands', currency: Currencies.EUR },
  AN: { name: 'Netherlands Antilles', currency: Currencies.EUR },
  NC: { name: 'New Caledonia' },
  NZ: { name: 'New Zealand' },
  NI: { name: 'Nicaragua' },
  NE: { name: 'Niger' },
  NG: { name: 'Nigeria' },
  NU: { name: 'Niue' },
  NF: { name: 'Norfolk Island' },
  MP: { name: 'Northern Mariana Islands' },
  NO: { name: 'Norway' },
  OM: { name: 'Oman' },
  PK: { name: 'Pakistan' },
  PW: { name: 'Palau' },
  PS: { name: 'Palestine, State of' },
  PA: { name: 'Panama' },
  PG: { name: 'Papua New Guinea' },
  PY: { name: 'Paraguay' },
  PE: { name: 'Peru' },
  PH: { name: 'Philippines' },
  PN: { name: 'Pitcairn' },
  PL: { name: 'Poland', currency: Currencies.EUR },
  PT: { name: 'Portugal', currency: Currencies.EUR },
  PR: { name: 'Puerto Rico' },
  QA: { name: 'Qatar' },
  RE: { name: 'Reunion' },
  RO: { name: 'Romania', currency: Currencies.EUR },
  RU: { name: 'Russian Federation' },
  RW: { name: 'Rwanda' },
  BL: { name: 'Saint Barthélemy' },
  SH: { name: 'Saint Helena' },
  KN: { name: 'Saint Kitts and Nevis' },
  LC: { name: 'Saint Lucia' },
  MF: { name: 'Saint Martin' },
  PM: { name: 'Saint Pierre and Miquelon' },
  VC: { name: 'Saint Vincent' },
  WS: { name: 'Samoa' },
  SM: { name: 'San Marino' },
  ST: { name: 'Sao Tome and Principe' },
  SA: { name: 'Saudi Arabia' },
  SN: { name: 'Senegal' },
  RS: { name: 'Serbia' },
  SC: { name: 'Seychelles' },
  SL: { name: 'Sierra Leone' },
  SG: { name: 'Singapore' },
  SX: { name: 'Sint Maarten' },
  SK: { name: 'Slovakia', currency: Currencies.EUR },
  SI: { name: 'Slovenia', currency: Currencies.EUR },
  SB: { name: 'Solomon Islands' },
  SO: { name: 'Somalia' },
  ZA: { name: 'South Africa' },
  GS: { name: 'South Georgia', currency: Currencies.GBP },
  SS: { name: 'South Sudan' },
  ES: { name: 'Spain', currency: Currencies.EUR },
  LK: { name: 'Sri Lanka' },
  SD: { name: 'Sudan' },
  SR: { name: 'Suriname' },
  SJ: { name: 'Svalbard and Jan Mayen' },
  SZ: { name: 'Swaziland' },
  SE: { name: 'Sweden', currency: Currencies.EUR },
  CH: { name: 'Switzerland' },
  SY: { name: 'Syrian Arab Republic' },
  TW: { name: 'Taiwan' },
  TJ: { name: 'Tajikistan' },
  TZ: { name: 'Tanzania' },
  TH: { name: 'Thailand' },
  TL: { name: 'Timor-Leste' },
  TG: { name: 'Togo' },
  TK: { name: 'Tokelau' },
  TO: { name: 'Tonga' },
  TT: { name: 'Trinidad and Tobago' },
  TN: { name: 'Tunisia' },
  TR: { name: 'Turkey' },
  TM: { name: 'Turkmenistan' },
  TC: { name: 'Turks and Caicos Islands' },
  TV: { name: 'Tuvalu' },
  UG: { name: 'Uganda' },
  UA: { name: 'Ukraine' },
  AE: { name: 'United Arab Emirates' },
  GB: { name: 'United Kingdom', currency: Currencies.GBP },
  US: {
    name: 'United States',
    regions: {
      AL: { name: 'Alabama' },
      AK: { name: 'Alaska' },
      AS: { name: 'American Samoa' },
      AZ: { name: 'Arizona' },
      AR: { name: 'Arkansas' },
      AE: { name: 'Armed Forces' },
      CA: { name: 'California' },
      CO: { name: 'Colorado' },
      CT: { name: 'Connecticut' },
      DE: { name: 'Delaware' },
      DC: { name: 'District of Columbia' },
      FM: { name: 'Federated States of Micronesia' },
      FL: { name: 'Florida' },
      GA: { name: 'Georgia' },
      GU: { name: 'Guam' },
      HI: { name: 'Hawaii' },
      ID: { name: 'Idaho' },
      IL: { name: 'Illinois' },
      IN: { name: 'Indiana' },
      IA: { name: 'Iowa' },
      KS: { name: 'Kansas' },
      KY: { name: 'Kentucky' },
      LA: { name: 'Louisiana' },
      ME: { name: 'Maine' },
      MH: { name: 'Marshall Islands' },
      MD: { name: 'Maryland' },
      MA: { name: 'Massachusetts' },
      MI: { name: 'Michigan' },
      MN: { name: 'Minnesota' },
      MS: { name: 'Mississippi' },
      MO: { name: 'Missouri' },
      MT: { name: 'Montana' },
      NE: { name: 'Nebraska' },
      NV: { name: 'Nevada' },
      NH: { name: 'New Hampshire' },
      NJ: { name: 'New Jersey' },
      NM: { name: 'New Mexico' },
      NY: { name: 'New York' },
      NC: { name: 'North Carolina' },
      ND: { name: 'North Dakota' },
      MP: { name: 'Northern Mariana Islands' },
      OH: { name: 'Ohio' },
      OK: { name: 'Oklahoma' },
      OR: { name: 'Oregon' },
      PW: { name: 'Palau' },
      PA: { name: 'Pennsylvania' },
      PR: { name: 'Puerto Rico' },
      RI: { name: 'Rhode Island' },
      SC: { name: 'South Carolina' },
      SD: { name: 'South Dakota' },
      TN: { name: 'Tennessee' },
      TX: { name: 'Texas' },
      UT: { name: 'Utah' },
      VT: { name: 'Vermont' },
      VI: { name: 'Virgin Islands' },
      VA: { name: 'Virginia' },
      WA: { name: 'Washington' },
      WV: { name: 'West Virginia' },
      WI: { name: 'Wisconsin' },
      WY: { name: 'Wyoming' },
    },
  },
  UM: { name: 'United States Minor Outlying Islands' },
  UY: { name: 'Uruguay' },
  UZ: { name: 'Uzbekistan' },
  VU: { name: 'Vanuatu' },
  VE: { name: 'Venezuela' },
  VN: { name: 'Viet Nam' },
  VG: { name: 'Virgin Islands, British' },
  VI: { name: 'Virgin Islands, U.S.' },
  WF: { name: 'Wallis and Futuna' },
  EH: { name: 'Western Sahara' },
  YE: { name: 'Yemen' },
  ZM: { name: 'Zambia' },
  ZW: { name: 'Zimbabwe' },
};

export const countryHasRegions = (
  country: string | undefined,
  countries: { [iso: string]: Country },
): boolean => {
  if (typeof country === 'undefined') {
    return false;
  }

  if (!countries[country] || !countries[country].regions) {
    return false;
  }

  return true;
};

export const getCountrysRegions = (
  country: string | undefined,
  countries: { [iso: string]: Country },
): { [iso: string]: Region } => {
  if (typeof country === 'undefined') {
    return {};
  }

  if (!countries[country] || !countries[country].regions) {
    return {};
  }

  return countries[country].regions as { [iso: string]: Region };
};

export const selectCurrency = (currencyISO: string | undefined): Currency => {
  if (currencyISO) {
    const iso = currencyISO.toUpperCase();

    if (Currencies[iso]) {
      return Currencies[iso];
    }
  }

  return Currencies['USD'];
};
