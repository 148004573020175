import { Plans, PlansTypes, PlansActions } from './types';
import { AsyncActionStatus } from 'store/asyncActions';
import { getAPIResource } from 'store/api';
import { convertAPIPlanId, convertAPIPricingInterval } from './utility';

const initialState: Plans = {};

export function plans(state: Plans = initialState, action: PlansTypes): Plans {
  let newState: Plans;

  switch (action.type) {
    case PlansActions.GET_PLANS:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        let apiPlans = getAPIResource(action.payload);

        if (apiPlans) {
          let plans: Plans = {};

          for (const apiPlanId in apiPlans.plans) {
            const planId = convertAPIPlanId(apiPlanId);

            if (planId) {
              const apiPlan = apiPlans.plans[planId];
              const pricing = convertAPIPricingInterval(
                apiPlans.plans[planId].pricing,
              );

              plans[planId] = {
                id: convertAPIPlanId(apiPlan.id),
                name: apiPlan.name,
                pricing: pricing,
                numQuotesIncluded: apiPlan.num_quotes_included,
                unlimitedQuotes: apiPlan.unlimited_quotes,
                numUsersIncluded: apiPlan.num_users_included,
                unlimitedUsers: apiPlan.unlimited_users,
                featuresIncluded: { ...apiPlan.features_included },
                availableAddons: { ...apiPlan.available_addons },
              };
            }
          }

          return plans;
        }

        newState = state;
      } else {
        newState = state;
      }
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
