import { Plan, PlanID, AddonID, Addon } from 'store/plans/types';

export const SignupPlans: {
  [id: string]: Plan;
} = {
  basic: {
    id: PlanID.BASIC,
    name: 'Basic',
    pricing: {
      monthly: {
        unitPriceInCents: 14900,
      },
      yearly: {
        unitPriceInCents: 142800,
      },
    },
    numQuotesIncluded: 35,
    unlimitedQuotes: false,
    numUsersIncluded: 0,
    unlimitedUsers: true,
    featuresIncluded: {
      [AddonID.AVALARA_TAX_INTEGRATION]: false,
      [AddonID.BUNDLES]: false,
      [AddonID.CONTRACTS]: false,
      [AddonID.ENTERPRISE_INTEGRATIONS]: false,
      [AddonID.ENTERPRISE_QUOTES]: false,
      [AddonID.LINE_ITEM_BULK_EDIT]: false,
      [AddonID.LINE_ITEM_IMPORT]: false,
      [AddonID.LINE_ITEM_PRICE_MODIFIERS]: true,
      [AddonID.MANAGER_APPROVALS]: false,
      [AddonID.MULTI_TENANT_USERS]: false,
      [AddonID.QUOTE_REMINDERS]: false,
      [AddonID.REPORTING]: false,
      [AddonID.SAML]: false,
      [AddonID.SINGLE_SELECT_GROUPS]: false,
    },
    availableAddons: {
      [AddonID.AVALARA_TAX_INTEGRATION]: true,
      [AddonID.BUNDLES]: true,
      [AddonID.CONTRACTS]: true,
      [AddonID.ENTERPRISE_INTEGRATIONS]: true,
      [AddonID.ENTERPRISE_QUOTES]: false,
      [AddonID.LINE_ITEM_BULK_EDIT]: false,
      [AddonID.LINE_ITEM_IMPORT]: true,
      [AddonID.LINE_ITEM_PRICE_MODIFIERS]: false,
      [AddonID.MANAGER_APPROVALS]: true,
      [AddonID.MULTI_TENANT_USERS]: false,
      [AddonID.QUOTE_REMINDERS]: true,
      [AddonID.REPORTING]: true,
      [AddonID.SAML]: true,
      [AddonID.SINGLE_SELECT_GROUPS]: true,
    },
  },
  standard: {
    id: PlanID.STANDARD,
    name: 'Standard',
    pricing: {
      monthly: {
        unitPriceInCents: 28900,
      },
      yearly: {
        unitPriceInCents: 274800,
      },
    },
    numQuotesIncluded: 0,
    unlimitedQuotes: false,
    numUsersIncluded: 0,
    unlimitedUsers: true,
    featuresIncluded: {
      [AddonID.AVALARA_TAX_INTEGRATION]: false,
      [AddonID.BUNDLES]: true,
      [AddonID.CONTRACTS]: false,
      [AddonID.ENTERPRISE_INTEGRATIONS]: false,
      [AddonID.ENTERPRISE_QUOTES]: false,
      [AddonID.LINE_ITEM_BULK_EDIT]: true,
      [AddonID.LINE_ITEM_IMPORT]: false,
      [AddonID.LINE_ITEM_PRICE_MODIFIERS]: true,
      [AddonID.MANAGER_APPROVALS]: false,
      [AddonID.MULTI_TENANT_USERS]: false,
      [AddonID.QUOTE_REMINDERS]: true,
      [AddonID.REPORTING]: false,
      [AddonID.SAML]: false,
      [AddonID.SINGLE_SELECT_GROUPS]: false,
    },
    availableAddons: {
      [AddonID.AVALARA_TAX_INTEGRATION]: true,
      [AddonID.BUNDLES]: false,
      [AddonID.CONTRACTS]: true,
      [AddonID.ENTERPRISE_INTEGRATIONS]: true,
      [AddonID.ENTERPRISE_QUOTES]: false,
      [AddonID.LINE_ITEM_BULK_EDIT]: false,
      [AddonID.LINE_ITEM_IMPORT]: true,
      [AddonID.LINE_ITEM_PRICE_MODIFIERS]: false,
      [AddonID.MANAGER_APPROVALS]: true,
      [AddonID.MULTI_TENANT_USERS]: false,
      [AddonID.QUOTE_REMINDERS]: false,
      [AddonID.REPORTING]: true,
      [AddonID.SAML]: true,
      [AddonID.SINGLE_SELECT_GROUPS]: true,
    },
  },
  pro: {
    id: PlanID.PRO,
    name: 'Pro',
    pricing: {
      monthly: {
        unitPriceInCents: 42900,
      },
      yearly: {
        unitPriceInCents: 406800,
      },
    },
    numQuotesIncluded: 0,
    unlimitedQuotes: false,
    numUsersIncluded: 0,
    unlimitedUsers: true,
    featuresIncluded: {
      [AddonID.AVALARA_TAX_INTEGRATION]: true,
      [AddonID.BUNDLES]: true,
      [AddonID.CONTRACTS]: false,
      [AddonID.ENTERPRISE_INTEGRATIONS]: false,
      [AddonID.ENTERPRISE_QUOTES]: false,
      [AddonID.LINE_ITEM_BULK_EDIT]: true,
      [AddonID.LINE_ITEM_IMPORT]: true,
      [AddonID.LINE_ITEM_PRICE_MODIFIERS]: true,
      [AddonID.MANAGER_APPROVALS]: true,
      [AddonID.MULTI_TENANT_USERS]: false,
      [AddonID.QUOTE_REMINDERS]: true,
      [AddonID.REPORTING]: true,
      [AddonID.SAML]: true,
      [AddonID.SINGLE_SELECT_GROUPS]: true,
    },
    availableAddons: {
      [AddonID.AVALARA_TAX_INTEGRATION]: false,
      [AddonID.BUNDLES]: false,
      [AddonID.CONTRACTS]: true,
      [AddonID.ENTERPRISE_INTEGRATIONS]: true,
      [AddonID.ENTERPRISE_QUOTES]: false,
      [AddonID.LINE_ITEM_BULK_EDIT]: false,
      [AddonID.LINE_ITEM_IMPORT]: false,
      [AddonID.LINE_ITEM_PRICE_MODIFIERS]: false,
      [AddonID.MANAGER_APPROVALS]: false,
      [AddonID.MULTI_TENANT_USERS]: false,
      [AddonID.QUOTE_REMINDERS]: false,
      [AddonID.REPORTING]: false,
      [AddonID.SAML]: false,
      [AddonID.SINGLE_SELECT_GROUPS]: false,
    },
  },
  enterprise: {
    id: PlanID.ENTERPRISE,
    name: 'Enterprise',
    pricing: {
      monthly: {
        unitPriceInCents: 56900,
      },
      yearly: {
        unitPriceInCents: 538800,
      },
    },
    numQuotesIncluded: 0,
    unlimitedQuotes: false,
    numUsersIncluded: 0,
    unlimitedUsers: true,
    featuresIncluded: {
      [AddonID.AVALARA_TAX_INTEGRATION]: true,
      [AddonID.BUNDLES]: true,
      [AddonID.CONTRACTS]: false,
      [AddonID.ENTERPRISE_INTEGRATIONS]: true,
      [AddonID.ENTERPRISE_QUOTES]: false,
      [AddonID.LINE_ITEM_BULK_EDIT]: true,
      [AddonID.LINE_ITEM_IMPORT]: true,
      [AddonID.LINE_ITEM_PRICE_MODIFIERS]: true,
      [AddonID.MANAGER_APPROVALS]: true,
      [AddonID.MULTI_TENANT_USERS]: true,
      [AddonID.QUOTE_REMINDERS]: true,
      [AddonID.REPORTING]: true,
      [AddonID.SAML]: true,
      [AddonID.SINGLE_SELECT_GROUPS]: true,
    },
    availableAddons: {
      [AddonID.AVALARA_TAX_INTEGRATION]: false,
      [AddonID.BUNDLES]: false,
      [AddonID.CONTRACTS]: true,
      [AddonID.ENTERPRISE_INTEGRATIONS]: false,
      [AddonID.ENTERPRISE_QUOTES]: true,
      [AddonID.LINE_ITEM_BULK_EDIT]: false,
      [AddonID.LINE_ITEM_IMPORT]: false,
      [AddonID.LINE_ITEM_PRICE_MODIFIERS]: false,
      [AddonID.MANAGER_APPROVALS]: false,
      [AddonID.MULTI_TENANT_USERS]: true,
      [AddonID.QUOTE_REMINDERS]: false,
      [AddonID.REPORTING]: false,
      [AddonID.SAML]: false,
      [AddonID.SINGLE_SELECT_GROUPS]: false,
    },
  },
};

export const Addons: {
  [id: string]: Addon;
} = {
  'avalara-tax-integration': {
    id: AddonID.AVALARA_TAX_INTEGRATION,
    name: 'Avalara Tax Integration',
    pricing: {
      monthly: {
        unitPriceInCents: 5900,
      },
      yearly: {
        unitPriceInCents: 58800,
      },
    },
    icon: '',
    description: '',
    features: [],
  },
  bundles: {
    id: AddonID.BUNDLES,
    name: 'Bundles',
    pricing: {
      monthly: {
        unitPriceInCents: 5900,
      },
      yearly: {
        unitPriceInCents: 58800,
      },
    },
    icon: '',
    description: '',
    features: [],
  },
  'line-item-import': {
    id: AddonID.LINE_ITEM_IMPORT,
    name: 'Line Item Import',
    pricing: {
      monthly: {
        unitPriceInCents: 5900,
      },
      yearly: {
        unitPriceInCents: 58800,
      },
    },
    icon: '',
    description: '',
    features: [],
  },
  contracts: {
    id: AddonID.CONTRACTS,
    name: 'Contract Management',
    pricing: {
      monthly: {
        unitPriceInCents: 9900,
      },
      yearly: {
        unitPriceInCents: 94800,
      },
    },
    icon: '',
    description: '',
    features: [],
  },
  'manager-approvals': {
    id: AddonID.MANAGER_APPROVALS,
    name: 'Manager Approvals',
    pricing: {
      monthly: {
        unitPriceInCents: 9900,
      },
      yearly: {
        unitPriceInCents: 94800,
      },
    },
    icon: '',
    description: '',
    features: [],
  },
  'quote-reminders': {
    id: AddonID.QUOTE_REMINDERS,
    name: 'Quote Reminders',
    pricing: {
      monthly: {
        unitPriceInCents: 5900,
      },
      yearly: {
        unitPriceInCents: 58800,
      },
    },
    icon: '',
    description: '',
    features: [],
  },
  reporting: {
    id: AddonID.REPORTING,
    name: 'Reporting',
    pricing: {
      monthly: {
        unitPriceInCents: 5900,
      },
      yearly: {
        unitPriceInCents: 58800,
      },
    },
    icon: '',
    description: '',
    features: [],
  },
  'enterprise-integrations': {
    id: AddonID.ENTERPRISE_INTEGRATIONS,
    name: 'Enterprise Integrations',
    pricing: {
      monthly: {
        unitPriceInCents: 5900,
      },
      yearly: {
        unitPriceInCents: 58800,
      },
    },
    icon: '',
    description: '',
    features: [],
  },
  'enterprise-quotes': {
    id: AddonID.ENTERPRISE_QUOTES,
    name: 'Enterprise Quotes',
    pricing: {
      monthly: {
        unitPriceInCents: 0,
        priceTiers: [
          {
            upToQuantity: 50,
            upToInfinity: false,
            flatPrice: 10000,
            unitPrice: null,
          },
          {
            upToQuantity: 100,
            upToInfinity: false,
            flatPrice: 9000,
            unitPrice: null,
          },
          {
            upToQuantity: 150,
            upToInfinity: false,
            flatPrice: 8000,
            unitPrice: null,
          },
          {
            upToQuantity: 200,
            upToInfinity: false,
            flatPrice: 7000,
            unitPrice: null,
          },
          {
            upToQuantity: 250,
            upToInfinity: false,
            flatPrice: 6000,
            unitPrice: null,
          },
          {
            upToQuantity: 0,
            upToInfinity: true,
            flatPrice: 5000,
            unitPrice: null,
          },
        ],
      },
      yearly: {
        unitPriceInCents: 0,
        priceTiers: [
          {
            upToQuantity: 50,
            upToInfinity: false,
            flatPrice: 120000,
            unitPrice: null,
          },
          {
            upToQuantity: 100,
            upToInfinity: false,
            flatPrice: 108000,
            unitPrice: null,
          },
          {
            upToQuantity: 150,
            upToInfinity: false,
            flatPrice: 96000,
            unitPrice: null,
          },
          {
            upToQuantity: 200,
            upToInfinity: false,
            flatPrice: 84000,
            unitPrice: null,
          },
          {
            upToQuantity: 250,
            upToInfinity: false,
            flatPrice: 72000,
            unitPrice: null,
          },
          {
            upToQuantity: 0,
            upToInfinity: true,
            flatPrice: 60000,
            unitPrice: null,
          },
        ],
      },
    },
    icon: '',
    description: 'Additional pack of 50 Quotes per month',
    features: [],
  },
  saml: {
    id: AddonID.SAML,
    name: 'SAML Single Sign-On (SSO)',
    pricing: {
      monthly: {
        unitPriceInCents: 5900,
      },
      yearly: {
        unitPriceInCents: 58800,
      },
    },
    icon: '',
    description: '',
    features: [],
  },
  'multi-tenant-users': {
    id: AddonID.MULTI_TENANT_USERS,
    name: 'Multi-Tenant Users',
    pricing: {
      monthly: {
        unitPriceInCents: 0,
        priceTiers: [
          {
            upToQuantity: 10,
            upToInfinity: false,
            flatPrice: 10000,
            unitPrice: null,
          },
          {
            upToQuantity: 20,
            upToInfinity: false,
            flatPrice: 9000,
            unitPrice: null,
          },
          {
            upToQuantity: 30,
            upToInfinity: false,
            flatPrice: 8000,
            unitPrice: null,
          },
          {
            upToQuantity: 40,
            upToInfinity: false,
            flatPrice: 7000,
            unitPrice: null,
          },
          {
            upToQuantity: 50,
            upToInfinity: false,
            flatPrice: 6000,
            unitPrice: null,
          },
          {
            upToQuantity: 500,
            upToInfinity: false,
            flatPrice: 5000,
            unitPrice: null,
          },
        ],
      },
      yearly: {
        unitPriceInCents: 0,
        priceTiers: [
          {
            upToQuantity: 10,
            upToInfinity: false,
            flatPrice: 120000,
            unitPrice: null,
          },
          {
            upToQuantity: 20,
            upToInfinity: false,
            flatPrice: 108000,
            unitPrice: null,
          },
          {
            upToQuantity: 30,
            upToInfinity: false,
            flatPrice: 96000,
            unitPrice: null,
          },
          {
            upToQuantity: 40,
            upToInfinity: false,
            flatPrice: 84000,
            unitPrice: null,
          },
          {
            upToQuantity: 50,
            upToInfinity: false,
            flatPrice: 72000,
            unitPrice: null,
          },
          {
            upToQuantity: 500,
            upToInfinity: false,
            flatPrice: 60000,
            unitPrice: null,
          },
        ],
      },
    },
    icon: '',
    description: '',
    features: [],
  },
  'single-select-groups': {
    id: AddonID.SINGLE_SELECT_GROUPS,
    name: 'Single Select Groups',
    pricing: {
      monthly: {
        unitPriceInCents: 5900,
      },
      yearly: {
        unitPriceInCents: 58800,
      },
    },
    icon: '',
    description: '',
    features: [],
  },
};

export const OnboardingAmount = 495;
