import React from 'react';

interface Props {
  value: string | number;
  className?: string;
}

const DateNumber = (props: Props) => {
  const parsedTimestamp = Date.parse(props.value.toString());
  const date = new Date(parsedTimestamp);
  const dateString = date.toLocaleString('en-us', {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  });

  return <span className={props.className}>{dateString}</span>;
};

export default DateNumber;
