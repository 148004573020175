import { Subscription, SubscriptionTypes, SubscriptionActions } from './types';
import { AsyncActionStatus } from 'store/asyncActions';
import { getAPIResource } from 'store/api';

import {
  convertAPISubscription,
  convertAPISubscriptionPreview,
} from './utility';

const initialState: Subscription = {};

export function subscription(
  state: Subscription = initialState,
  action: SubscriptionTypes,
): Subscription {
  let newState: Subscription;

  switch (action.type) {
    case SubscriptionActions.GET_SUBSCRIPTION:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        const apiSubscription = getAPIResource(action.payload);
        if (apiSubscription) {
          return convertAPISubscription(apiSubscription);
        }

        newState = state;
      } else {
        newState = state;
      }
      break;
    case SubscriptionActions.POST_SUBSCRIPTION_PREVIEW:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        const apiSubscriptionPreview = getAPIResource(action.payload);
        if (apiSubscriptionPreview) {
          return {
            ...state,
            preview: {
              ...state.preview,
              ...convertAPISubscriptionPreview(apiSubscriptionPreview),
            },
          };
        }
      }
      newState = state;
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
