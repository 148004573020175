import React from 'react';
import { NavLink } from 'react-router-dom';
import { APP_QUOTER_URL } from 'store/api';
import Icon from 'components/Icon';

import style from './Navigation.module.scss';

const Navigation = () => (
  <nav className="nav flex-column">
    <div className="nav-item">
      <NavLink exact to="/" className="nav-link pl-0">
        Overview
      </NavLink>
    </div>
    <div className="nav-item">
      <NavLink to="/plans" className="nav-link pl-0">
        Plan and Add-Ons
      </NavLink>
    </div>
    <div className="nav-item">
      <NavLink to="/billing" className="nav-link pl-0">
        Billing
      </NavLink>
    </div>
    <div className="nav-item">
      <NavLink to="/invoices" className="nav-link pl-0">
        Invoices
      </NavLink>
    </div>
    <hr className={style.separator} />
    <div className={`nav-item ${style.externalLink}`}>
      <a
        href={APP_QUOTER_URL}
        target="_blank"
        rel="noopener noreferrer"
        className="nav-link pl-0"
      >
        Launch Quoter{' '}
        <Icon name="external-link-alt" className={style.externalLinkIcon} />
      </a>
      <a href={APP_QUOTER_URL + '/users/logout'} className="nav-link pl-0">
        Logout
      </a>
    </div>
  </nav>
);

export default Navigation;
