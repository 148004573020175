import React, { Fragment } from 'react';
import { Invoice } from 'store/invoices/types';
import { selectCurrency } from 'utils/countries';

import DateNumber from 'components/DateNumber';
import CurrencyNumber from 'components/CurrencyNumber';

import style from './InvoiceList.module.scss';

const InvoiceList = (props: { invoices: Invoice[] }) => {
  if (props.invoices.length === 0) {
    return <div className="text-muted">No Invoices</div>;
  }

  return (
    <Fragment>
      {Object.values(props.invoices).map(invoice => (
        <div key={invoice.id} className={`row ${style.invoice}`}>
          <div className="col-auto">
            <a className={style.header} href={invoice.pdfLink}>
              {invoice.created && (
                <DateNumber value={invoice.created} />
              )}
            </a>
            {/* <span className="text-muted"> &mdash; </span>
              {invoice.periodEnd && <DateNumber value={invoice.periodEnd} />} */}
            <span className="badge badge-light mr-2">{invoice.number}</span>
            {invoice.paid === true ? (
              <span className="badge badge-success">Paid</span>
            ) : (
              <span className="badge badge-info">Open</span>
            )}
          </div>
          <div className="col text-right">
            {invoice.totalInCents ? (
              <h5 className="text-primary">
                <strong>
                  <CurrencyNumber
                    currency={selectCurrency(invoice.currency)}
                    value={invoice.totalInCents / 100}
                  />{' '}
                  {invoice.currency?.toUpperCase()}
                </strong>
              </h5>
            ) : (
              <h5 className="text-muted">&mdash;</h5>
            )}
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default InvoiceList;
