import createCachedSelector from 're-reselect';
import { Customer } from 'store/customer/types';
import { AppState } from 'store';
import { Countries } from 'utils/countries';

const getCustomer = (state: AppState) => state.customer;

export type FormattedBillingAddress = {
  fullName: string;
  billingAddress: string;
  billingStreet: string;
  billingCountry: string;
};

const selectFormattedBillingAddress = (
  customer: Customer,
): FormattedBillingAddress => {
  return {
    fullName: customer.firstName + ' ' + customer.lastName,
    billingAddress: formatBillingAddress(customer),
    billingStreet: formatBillingStreet(customer),
    billingCountry: customer.billingCountryISO
      ? Countries[customer.billingCountryISO].name
      : '',
  };
};

const formatBillingAddress = ({
  billingAddress1,
  billingAddress2,
}: Customer): string => {
  let formattedBillingAddress: string = '';

  if (billingAddress1) {
    formattedBillingAddress += billingAddress1;
  }

  if (billingAddress2) {
    formattedBillingAddress += billingAddress2;
  }

  return formattedBillingAddress;
};

const formatBillingStreet = ({
  billingCity,
  billingRegionISO,
}: Customer): string => {
  let formattedBillingStreet: string = '';

  if (billingCity) {
    formattedBillingStreet += billingCity;
  }
  if (billingRegionISO) {
    formattedBillingStreet += ', ' + billingRegionISO;
  }

  return formattedBillingStreet;
};

export default createCachedSelector(
  getCustomer,
  selectFormattedBillingAddress,
)(state => state.customer.id);
