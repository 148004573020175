import { connect } from 'react-redux';
import { AppState } from 'store';
import { postSubscriptionPreview } from 'store/subscription/actions';

import selectPlanById from 'selectors/selectPlanById';
import selectPlansAddons from 'selectors/selectPlansAddons';
import selectBillingFormData from 'selectors/selectBillingFormData';
import selectSubscriptionCurrency from 'selectors/selectSubscriptionCurrency';
import selectPaymentAmounts from 'selectors/selectPaymentAmounts';
import selectPaymentSource from 'selectors/selectPaymentSource';

import {
  setInterval,
  applyCouponCode,
  onSubmitNewPaymentMethod,
  onSubmitExistingPaymentMethod,
  clearMessages,
} from 'store/paymentWizard/actions';

import Payment from './Payment';

const mapStateToProps = (state: AppState) => ({
  amounts: selectPaymentAmounts(state),
  amountsLoading: state.loading.previewSubscription,
  couponLoading: state.loading.coupon,
  currency: selectSubscriptionCurrency(state),
  form: selectBillingFormData(state),
  formLoading: state.loading.putSubscription,
  newPaymentLoading: state.loading.postCreditCard,
  paymentSource: selectPaymentSource(state),
  paymentWizard: state.paymentWizard,
  selectedPlan: selectPlanById(state),
  selectedPlanAddons: selectPlansAddons(state),
});

const mapDispatchToProps = {
  onSubmitExistingPaymentMethod: onSubmitExistingPaymentMethod,
  onSubmitNewPaymentMethod: onSubmitNewPaymentMethod,
  setInterval: setInterval,
  applyCouponCode: applyCouponCode,
  postSubscriptionPreview: postSubscriptionPreview,
  clearMessages: clearMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
