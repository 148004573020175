import React, { useState, useEffect } from 'react';
import * as H from 'history';

import { default as AddonComponent } from 'components/Addon';
import SubmitButton from 'components/Form/SubmitButton';
import { Addons } from 'store/signup/plans';
import { AddonID, Plan, Addon } from 'store/plans/types';
import { PaymentInterval } from 'store/payment';
import { Currency } from 'utils/countries';
import Agreement from 'components/Agreement';
import Icon from 'components/Icon';
import MultiTenant from 'components/Addon/MultiTenant';

import {
  selectAddon,
  removeAddon,
  decrementMultiTenant,
  incrementMultiTenant,
  incrementEnterpriseQuotes,
  decrementEnterpriseQuotes,
} from 'store/signup/actions';
import EnterpriseQuote from 'components/Addon/EnterpriseQuote';

type Props = {
  plan: Plan;
  addons: { [id: string]: Addon };
  interval: PaymentInterval;
  chooseAddonsView: {
    multiTenantUsers: number;
    enterpriseQuotes: number;
    isLoading: boolean;
  };
  currency: Currency;
  createAccountComplete: boolean;
  selectAddon: typeof selectAddon;
  removeAddon: typeof removeAddon;
  submitAddons: (addons: Addon[]) => Promise<any>;
  incrementMultiTenant: typeof incrementMultiTenant;
  decrementMultiTenant: typeof decrementMultiTenant;
  incrementEnterpriseQuotes: typeof incrementEnterpriseQuotes;
  decrementEnterpriseQuotes: typeof decrementEnterpriseQuotes;
  history: H.History;
};

export default function AddonsAccountView(props: Props) {
  const {
    plan,
    addons,
    chooseAddonsView,
    interval,
    currency,
    createAccountComplete,
    selectAddon,
    removeAddon,
    submitAddons,
    incrementMultiTenant,
    decrementMultiTenant,
    decrementEnterpriseQuotes,
    incrementEnterpriseQuotes,
  } = props;

  // This pattern allows us to replace componentDidMount behaviour with React Hooks.
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized) {
      window.scrollTo(0, 0);
      setInitialized(true);
    }
  }, [initialized, setInitialized]);

  const handleAddonSelected = (id: string) => {
    selectAddon(id);
  };

  const handleAddonRemoved = (id: string) => {
    removeAddon(id);
  };

  const [continuePressed, setContinuePressed] = useState(false);
  const handleSubmit = () => {
    submitAddons(Object.values(addons));
    setContinuePressed(true);
  };

  // Handle redirect after form submission
  useEffect(() => {
    if (continuePressed && !chooseAddonsView.isLoading) {
      if (createAccountComplete) props.history.push('/payment');
      else props.history.push('/a');
    }
  }, [
    continuePressed,
    chooseAddonsView.isLoading,
    props.history,
    createAccountComplete,
  ]);

  return (
    <div className="AddonsView">
      <h1 className="text-center">Available add-ons</h1>
      <div className="pt-5">
        {plan.availableAddons[AddonID.AVALARA_TAX_INTEGRATION] && (
          <AddonComponent
            icon="percent"
            addon={Addons[AddonID.AVALARA_TAX_INTEGRATION]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.AVALARA_TAX_INTEGRATION in addons}
          >
            <p>Integrate Quoter with Avalara.</p>
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Quote faster and smarter by automatically pulling applicable
                taxes from Avalara
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Ensure tax compliance and reduce manual edits and errors
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Taxes update in real-time as you edit Quotes
              </li>
            </ul>
          </AddonComponent>
        )}
        {plan.availableAddons[AddonID.BUNDLES] && (
          <AddonComponent
            icon="box"
            addon={Addons[AddonID.BUNDLES]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.BUNDLES in addons}
          >
            <p>
              Bundle multiple products and services together to save time and
              simplify complex quotes.
            </p>
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Bundle multiple products and services into a single line item
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Create reusable, complex product/service configurations to save
                time and avoid mistakes
              </li>
            </ul>
          </AddonComponent>
        )}
        {plan.availableAddons[AddonID.CONTRACTS] && (
          <AddonComponent
            icon="file-contract"
            addon={Addons[AddonID.CONTRACTS]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.CONTRACTS in addons}
          >
            <p>
              Simplify and automate contract management to capture lost revenue,
              empower your team, and maximize the value of your business.
            </p>
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Simple document builder with PDF importer and template
                repository for contract standardization
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Automated contract delivery and E-signature compliant execution
                upon quote acceptance
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Centralized contract management with renewal tracking
              </li>
            </ul>
          </AddonComponent>
        )}
        {plan.availableAddons[AddonID.ENTERPRISE_INTEGRATIONS] && (
          <AddonComponent
            icon="bolt"
            addon={Addons[AddonID.ENTERPRISE_INTEGRATIONS]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.ENTERPRISE_INTEGRATIONS in addons}
          >
            <p>Integrate Quoter with Salesforce and/or HubSpot.</p>
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Seamless integrations save time on duplicate data entry
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Enhanced integration functionality to ensure accurate data sync
              </li>
            </ul>
          </AddonComponent>
        )}
        {plan.availableAddons[AddonID.LINE_ITEM_IMPORT] && (
          <AddonComponent
            icon="file-import"
            addon={Addons[AddonID.LINE_ITEM_IMPORT]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.LINE_ITEM_IMPORT in addons}
          >
            <p>
              Build quotes faster by importing Line Items from vendors or other
              sources.
            </p>
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Save smart mapping templates that take all of the work out of
                importing Line Items
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Import up to 100 Line Items at a time
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Automatically match or create manufacturers and suppliers
              </li>
            </ul>
          </AddonComponent>
        )}
        {plan.availableAddons[AddonID.MANAGER_APPROVALS] && (
          <AddonComponent
            icon="user-shield"
            addon={Addons[AddonID.MANAGER_APPROVALS]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.MANAGER_APPROVALS in addons}
          >
            <p>
              Leverage automated approval workflows to empower more people to
              quote.
            </p>
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Automatically trigger approval workflows based on criteria you
                define
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Simple one-click, mobile-friendly Quote approval or rejection
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Turn every customer touchpoint into an opportunity
              </li>
            </ul>
          </AddonComponent>
        )}
        {plan.availableAddons[AddonID.MULTI_TENANT_USERS] && (
          <AddonComponent
            icon="user-friends"
            addon={Addons[AddonID.MULTI_TENANT_USERS]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.MULTI_TENANT_USERS in addons}
            count={chooseAddonsView.multiTenantUsers}
          >
            <MultiTenant
              addon={Addons[AddonID.MULTI_TENANT_USERS]}
              value={chooseAddonsView.multiTenantUsers}
              onSet={() => {}}
              onIncrement={incrementMultiTenant}
              onDecrement={decrementMultiTenant}
              selected={AddonID.MULTI_TENANT_USERS in addons}
            />
            <p>
              Empower your resellers or channel partners to create and manage
              their own Quotes.
            </p>
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Provide your resellers with their own sales quoting portal
                within your Quoter account.
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Maintain control of your products, services, and quote
                templates.
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Automate your channel sales process to drive more revenue.
              </li>
            </ul>
          </AddonComponent>
        )}
        {plan.availableAddons[AddonID.QUOTE_REMINDERS] && (
          <AddonComponent
            icon="ribbon"
            addon={Addons[AddonID.QUOTE_REMINDERS]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.QUOTE_REMINDERS in addons}
          >
            <p>
              Save time and avoid deals slipping through the cracks by
              automatically sending quote reminders to your customers.
            </p>
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Set default custom reminder intervals and override as needed
                when sending a Quote
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Send reminders from your User email or your Account email
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Speed up your Quote-to-Cash and reduce repetitive tasks
              </li>
            </ul>
          </AddonComponent>
        )}
        {plan.availableAddons[AddonID.REPORTING] && (
          <AddonComponent
            icon="chart-pie"
            addon={Addons[AddonID.REPORTING]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.REPORTING in addons}
          >
            <p>
              Gain valuable insight into your team’s performance with Reporting.
            </p>
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Sales Leaderboard helps motivate your team
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Detailed Item Report provides intelligence on products/services
                quoted and sold
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Improve your bottom line with reporting on profit margins
              </li>
            </ul>
          </AddonComponent>
        )}
        {plan.availableAddons[AddonID.SAML] && (
          <AddonComponent
            icon="lock"
            addon={Addons[AddonID.SAML]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.SAML in addons}
          >
            <p>
              Maintain full control over the authorization and authentication of
              your Quoter users.
            </p>
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Connect with leading identity management providers such as Okta,
                OneLogin, Azure, or any SAML 2.0 provider.
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Your Quoter users will be automatically redirected to your
                identity management provider during login.
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Configure one-click access to Quoter with automatic
                authentication in your identity management provider.
              </li>
            </ul>
          </AddonComponent>
        )}
        {plan.availableAddons[AddonID.SINGLE_SELECT_GROUPS] && (
          <AddonComponent
            icon="check-circle"
            addon={Addons[AddonID.SINGLE_SELECT_GROUPS]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.SINGLE_SELECT_GROUPS in addons}
          >
            <p>
              Upsell and cross-sell with Single Select Line Items or Sections on
              your Quotes.
            </p>
            <ul className="fa-ul">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Provide end-customer configurability to eliminate back-and-forth
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Automate upselling and cross-selling directly within your Quotes
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check" />
                </span>
                Provide multiple Quotes in one
              </li>
            </ul>
          </AddonComponent>
        )}
        {plan.availableAddons[AddonID.ENTERPRISE_QUOTES] && (
          <AddonComponent
            icon="plus"
            addon={Addons[AddonID.ENTERPRISE_QUOTES]}
            interval={interval}
            currency={currency}
            onSelect={handleAddonSelected}
            onRemove={handleAddonRemoved}
            selected={AddonID.ENTERPRISE_QUOTES in addons}
            count={chooseAddonsView.enterpriseQuotes}
          >
            <EnterpriseQuote
              addon={Addons[AddonID.ENTERPRISE_QUOTES]}
              value={chooseAddonsView.enterpriseQuotes}
              onSet={() => {}}
              onIncrement={incrementEnterpriseQuotes}
              onDecrement={decrementEnterpriseQuotes}
              selected={AddonID.ENTERPRISE_QUOTES in addons}
            />
          </AddonComponent>
        )}
        <div className="pb-5">
          <SubmitButton
            isLoading={chooseAddonsView.isLoading}
            disabled={continuePressed}
            onClick={handleSubmit}
          >
            Continue{' '}
            <Icon name="long-arrow-alt-right" size="sm" className="ml-2" />
          </SubmitButton>
          <Agreement buttonText="Continue" />
        </div>
      </div>
    </div>
  );
}
