import { connect } from 'react-redux';
import { AppState } from 'store';

import selectFormattedBillingAddress from 'selectors/selectFormattedBillingAddress';
import selectPaymentSource from 'selectors/selectPaymentSource';
import Billing from './Billing';

const mapStateToProps = (state: AppState) => ({
  billingAddress: selectFormattedBillingAddress(state),
  customer: state.customer,
  customerLoading: state.loading.customer,
  paymentSource: selectPaymentSource(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
