import React, { useState, useEffect, useRef } from 'react';
import * as H from 'history';
import { Countries, Currency } from '../../utils/countries';
import {
  StripeProvider,
  Elements,
  injectStripe,
  ReactStripeElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';
import { PaymentInterval, PaymentAmounts } from 'store/payment';
import useForm, { Form } from '../../hooks/useForm';
import { useGoogleReCaptchaV3 } from '../../hooks/useRecaptcha';
import validate from '../../utils/validate';
import Select from '../../components/Form/Select';
import SubmitButton from '../../components/Form/SubmitButton';
import classNames from 'classnames';
import './PaymentView.scss';
import Input from '../../components/Form/Input';
import {
  setBillingCountry,
  setBillingRegion,
} from '../../store/signup/actions';
import { STRIPE_PUSHABLE_KEY } from '../../store/stripe';
import CurrencyNumber from '../../components/CurrencyNumber';
import Agreement from 'components/Agreement';
import { BillingForm } from 'store/forms';
import Icon from 'components/Icon';

interface PaymentViewProps {
  form: BillingForm;
  amounts: PaymentAmounts;
  interval: PaymentInterval;
  currency: Currency;
  isLoading: boolean;
  errorMessage?: string | null;
  signupComplete: boolean;
  setBillingCountry: typeof setBillingCountry;
  setBillingRegion: typeof setBillingRegion;
  submitPayment: (
    form: BillingForm,
    stripe: ReactStripeElements.StripeProps,
  ) => Promise<any>;
  history: H.History;
}

// See:
// https://stripe.com/docs/recipes/elements-react
// https://github.com/stripe/react-stripe-elements
function PaymentView(props: PaymentViewProps) {
  if (STRIPE_PUSHABLE_KEY.length === 0) {
    throw new Error(
      'STRIPE_PUSHABLE_KEY not set. You must add REACT_APP_STRIPE_PUSHABLE_KEY to your environment',
    );
  }
  const stripeElementFonts = [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Lato:400,400i,700',
    },
  ];

  return (
    <div className="PaymentView">
      <h1 className="text-center">Payment information</h1>
      <div className="pt-5">
        <StripeProvider apiKey={STRIPE_PUSHABLE_KEY}>
          <Elements fonts={stripeElementFonts}>
            <StripeCheckoutForm {...props} />
          </Elements>
        </StripeProvider>
      </div>
    </div>
  );
}
export default PaymentView;

let StripeCheckoutForm = injectStripe(CheckoutForm);

interface CheckoutFormProps extends PaymentViewProps, InjectedStripeProps {}

// Modified version of ReactStripeElements.InjectedStripeProps to allow undefined props. This is
// necessary to avoid warnings when passing props to StripeCheckoutForm.
interface InjectedStripeProps {
  stripe?: ReactStripeElements.StripeProps | null;
  elements?: stripe.elements.Elements | null;
}

function CheckoutForm(props: CheckoutFormProps) {
  let {
    amounts,
    currency,
    isLoading,
    errorMessage,
    setBillingCountry,
    setBillingRegion,
    submitPayment,
    stripe,
  } = props;
  const stripeElementStyle = {
    base: {
      lineHeight: '1.5',
      fontSize: '16px',
      '::placeholder': {
        color: '#6C757D',
      },
      color: '#495057',
      fontFamily: '"Lato"',
      fontSmoothing: 'antialiased',
      fontWeight: 400,
    },
  };

  const initialFormState: Form = {
    formControls: {
      cardNumber: {},
      cardExpiry: {},
      cardCvc: {},
      billingCardHolder: {
        value: props.form.billingCardHolder || '',
        validationRules: {
          isRequired: true,
        },
        isValid: props.form.billingCardHolder ? true : undefined,
      },
      billingAddress1: {
        value: props.form.billingAddress1 || '',
        validationRules: {
          isRequired: true,
        },
        isValid: props.form.billingAddress1 ? true : undefined,
      },
      billingAddress2: {
        value: props.form.billingAddress2 || '',
        isValid: true,
      },
      billingCity: {
        value: props.form.billingCity || '',
        validationRules: {
          isRequired: true,
        },
        isValid: props.form.billingCity ? true : undefined,
      },
      billingCountry: {
        value: props.form.billingCountryISO || '',
        validationRules: {
          isRequired: true,
        },
        isValid: props.form.billingCountryISO !== undefined ? true : undefined,
      },
      billingRegion: {
        value: props.form.billingRegionISO || '',
        validationRules: {
          isRequired: false,
        },
        isValid: props.form.billingRegionISO !== undefined ? true : undefined,
      },
      billingPostalCode: {
        value: props.form.billingPostalCode || '',
        validationRules: {
          isRequired: true,
          minLength: 3,
          maxLength: 8,
        },
        isValid: props.form.billingPostalCode ? true : undefined,
      },
    },
  };

  // This pattern allows us to replace componentDidMount behaviour with React Hooks.
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized) {
      window.scrollTo(0, 0);
      setInitialized(true);
    }
  }, [initialized, setInitialized]);

  // Error message flash display
  const errorMessageRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (errorMessage && errorMessageRef.current) {
      window.scrollTo(0, errorMessageRef.current.offsetTop - 15);
    }
  }, [errorMessage]);

  const { executeReCaptcha } = useGoogleReCaptchaV3();

  // const { reCaptchaResponseToken } = useGoogleReCaptchaV3({
  //   loadOnStart: true,
  //   action: 'LoadReCaptchaOnStart',
  // });

  const onFormSubmit = async () => {
    if (!stripe) {
      throw new Error('props.stripe is undefined');
    }

    let recaptchaToken: string | undefined;
    if (process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
      recaptchaToken = await executeReCaptcha('LoadReCaptchaOnClick');
    }

    let completedForm = {
      ...props.form,
      billingCardHolder: formControls.billingCardHolder.value,
      billingAddress1: formControls.billingAddress1.value,
      billingAddress2: formControls.billingAddress2.value,
      billingCity: formControls.billingCity.value,
      billingCountryISO: formControls.billingCountry.value,
      billingRegionISO: formControls.billingRegion.value,
      billingPostalCode: formControls.billingPostalCode.value,
      recaptchaToken: recaptchaToken,
    };
    submitPayment(completedForm, stripe);
  };

  const { form, setForm, handleFormChange, handleFormSubmit } = useForm(
    initialFormState,
    validate,
    onFormSubmit,
  );
  const { formControls } = form;

  // Handle Stripe input changes
  const handleStripeElementChange = (
    e: ReactStripeElements.ElementChangeResponse,
  ) => {
    if (e.complete) {
      setForm((form: Form) => ({
        ...form,
        formControls: {
          ...form.formControls,
          [e.elementType]: {
            ...form.formControls[e.elementType],
            isValid: true,
          },
        },
      }));
    } else {
      setForm((form: Form) => ({
        ...form,
        formControls: {
          ...form.formControls,
          [e.elementType]: {
            ...form.formControls[e.elementType],
            isValid: false,
          },
        },
      }));
    }
  };

  // Fire action when country changes for tax purposes.
  // Handle Country/Region dependency.
  const handleBillingCountryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    handleFormChange(e);
    setBillingCountry(e.target.value);
    if (Countries[e.target.value] && Countries[e.target.value].regions) {
      setForm((form: Form) => ({
        ...form,
        formControls: {
          ...form.formControls,
          billingRegion: {
            ...form.formControls.billingRegion,
            value: undefined,
            isValid: undefined,
            validationRules: {
              isRequired: true,
            },
          },
        },
      }));
    } else {
      setForm((form: Form) => ({
        ...form,
        formControls: {
          ...form.formControls,
          billingRegion: {
            ...form.formControls.billingRegion,
            value: undefined,
            isValid: true,
            validationRules: {
              isRequired: false,
            },
          },
        },
      }));
    }
  };

  // Fire action when region changes to determine taxes
  const handleBillingRegionChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    handleFormChange(e);
    setBillingRegion(e.target.value);
  };

  // Disabled as this is not actually used. Redirection to /thank-you is handled by the ProtectedRoute for
  // CreateAccountView, which has allowAccess={!signupComplete} and deniedAccessRedirectPath="/thank-you"
  // Handle redirect after payment completion
  // useEffect(() => {
  //   if (signupComplete) {
  //     history.push('/thank-you');
  //   }
  // }, [
  //   signupComplete,
  //   history,
  // ]);

  return (
    <div className="PaymentForm" ref={errorMessageRef}>
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}

      <div className="row">
        <div className="CardNumber form-group col-sm-6">
          <label htmlFor="cardNumber">Card number</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fab fa-cc-visa" />
                &nbsp;
                <i className="fab fa-cc-mastercard" />
                &nbsp;
                <i className="fab fa-cc-amex" />
              </span>
            </div>
            <CardNumberElement
              id="cardNumber"
              className={classNames('form-control', {
                'is-valid': formControls.cardNumber.isValid === true,
                'is-invalid': formControls.cardNumber.isValid === false,
              })}
              placeholder="Card number"
              style={stripeElementStyle}
              onChange={handleStripeElementChange}
            />
          </div>
          <small id="cardNumberHelp" className="form-text text-muted">
            <i className="fas fa-lock" /> Handled securely by Stripe
          </small>
        </div>

        <div className="col-sm-6">
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="billingCountry">Expiry date</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-calendar-alt" />
                  </span>
                </div>
                <CardExpiryElement
                  id="cardExpiry"
                  className={classNames('form-control', {
                    'is-valid': formControls.cardExpiry.isValid === true,
                    'is-invalid': formControls.cardExpiry.isValid === false,
                  })}
                  style={stripeElementStyle}
                  onChange={handleStripeElementChange}
                />
              </div>
            </div>

            <div className="form-group col-6">
              <label htmlFor="billingCountry">CVC</label>
              <div className="input-group">
                <CardCvcElement
                  id="cardCVC"
                  className={classNames('form-control', {
                    'is-valid': formControls.cardCvc.isValid === true,
                    'is-invalid': formControls.cardCvc.isValid === false,
                  })}
                  style={stripeElementStyle}
                  onChange={handleStripeElementChange}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fas fa-credit-card" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <Input
          id="billingCardHolder"
          type="text"
          label="Card holder"
          placeholder="Card holder"
          onChange={handleFormChange}
          isInvalid={formControls.billingCardHolder.isValid === false}
          isValid={formControls.billingCardHolder.isValid === true}
          formGroupClassName="col-sm-6"
          value={formControls.billingCardHolder.value || ''}
        />

        <Input
          id="billingAddress1"
          type="text"
          label="Billing address"
          placeholder="Billing address"
          onChange={handleFormChange}
          isInvalid={formControls.billingAddress1.isValid === false}
          isValid={formControls.billingAddress1.isValid === true}
          formGroupClassName="col-sm-6"
          value={formControls.billingAddress1.value || ''}
        />
      </div>

      <div className="row">
        <Input
          id="billingAddress2"
          type="text"
          label="Billing address 2"
          placeholder="Billing address 2"
          onChange={handleFormChange}
          isValid={formControls.billingAddress2.isTouched === true}
          formGroupClassName="col-sm-6"
          value={formControls.billingAddress2.value || ''}
        />

        <Input
          id="billingCity"
          type="text"
          label="City"
          placeholder="City"
          onChange={handleFormChange}
          isInvalid={formControls.billingCity.isValid === false}
          isValid={formControls.billingCity.isValid === true}
          formGroupClassName="col-sm-6"
          value={formControls.billingCity.value || ''}
        />
      </div>

      <div className="row">
        <Select
          id="billingCountry"
          label="Country"
          onChange={handleBillingCountryChange}
          isInvalid={formControls.billingCountry.isValid === false}
          isValid={formControls.billingCountry.isValid === true}
          formGroupClassName="col-sm-6"
          value={formControls.billingCountry.value || ''}
        >
          <option value="">- Please Select -</option>
          {Object.entries(Countries).map(([iso, country]) => (
            <option key={iso} value={iso}>
              {country.name}
            </option>
          ))}
        </Select>

        {formControls.billingCountry.value &&
          Countries[formControls.billingCountry.value] &&
          Countries[formControls.billingCountry.value].regions && (
            <Select
              id="billingRegion"
              label="State/province"
              onChange={handleBillingRegionChange}
              isInvalid={formControls.billingRegion.isValid === false}
              isValid={formControls.billingRegion.isValid === true}
              formGroupClassName="col-sm-6"
              value={formControls.billingRegion.value || ''}
            >
              <option value="">- Please Select -</option>
              {Object.entries(
                Countries[formControls.billingCountry.value].regions || {},
              ).map(([iso, region]) => (
                <option key={iso} value={iso}>
                  {region.name}
                </option>
              ))}
            </Select>
          )}

        <Input
          id="billingPostalCode"
          type="text"
          label="Zip/postal code"
          placeholder="Zip/postal code"
          onChange={handleFormChange}
          isInvalid={formControls.billingPostalCode.isValid === false}
          isValid={formControls.billingPostalCode.isValid === true}
          formGroupClassName="col-sm-6"
          value={formControls.billingPostalCode.value || ''}
        />
      </div>

      <div className="py-5">
        <SubmitButton
          isLoading={isLoading}
          disabled={!form.isValid}
          onClick={handleFormSubmit}
        >
          <Icon name="lock" size="sm" className="mr-2" />
          Pay &nbsp;
          {amounts.total && (
            <CurrencyNumber
              value={amounts.total}
              currency={currency}
              decimals={2}
            />
          )}{' '}
          Now and Sign Up
        </SubmitButton>
        <Agreement buttonText="Sign Up" />
      </div>
    </div>
  );
}

// pk_test_0lhi4hCc8I2GJYuu9L2LQdYU
