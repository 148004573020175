import { Customer, CustomerTypes, CustomerActions } from './types';
import { AsyncActionStatus } from 'store/asyncActions';
import { getAPIResource } from 'store/api';

const initialState: Customer = {};

export function customer(
  state: Customer = initialState,
  action: CustomerTypes,
): Customer {
  let newState: Customer;

  switch (action.type) {
    case CustomerActions.GET_CUSTOMER:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        let apiCustomer = getAPIResource(action.payload);

        if (apiCustomer) {
          newState = {
            id: apiCustomer.id,
            company: apiCustomer.company,
            currency: apiCustomer.currency,
            firstName: apiCustomer.first_name,
            lastName: apiCustomer.last_name,
            email: apiCustomer.email,
            phone: apiCustomer.phone,
            billingAddress1: apiCustomer.billing_address1,
            billingAddress2: apiCustomer.billing_address2,
            billingCity: apiCustomer.billing_city,
            billingCountryISO: apiCustomer.billing_country_iso,
            billingRegionISO: apiCustomer.billing_region_iso,
            billingPostalCode: apiCustomer.billing_postal_code,
            balanceInCents: apiCustomer.balance_in_cents,
            paymentSources:
              apiCustomer.payment_sources?.map(source => ({
                id: source.id,
                billingCardholder: source.billing_cardholder,
                creditCardLast4: source.credit_card_last_4,
                creditCardExpiryMonth: parseInt(
                  source.credit_card_expiry_month,
                ),
                creditCardExpiryYear: parseInt(source.credit_card_expiry_year),
                brand: source.brand,
                default: source.default,
              })) || null,
          };
        } else {
          newState = state;
        }
      } else {
        newState = state;
      }
      break;
    case CustomerActions.PUT_CUSTOMER:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        let apiCustomer = getAPIResource(action.payload);

        if (apiCustomer) {
          newState = {
            ...state,
            company: apiCustomer.company,
            firstName: apiCustomer.first_name,
            lastName: apiCustomer.last_name,
            email: apiCustomer.email,
            phone: apiCustomer.phone,
            billingAddress1: apiCustomer.billing_address1,
            billingAddress2: apiCustomer.billing_address2,
            billingCity: apiCustomer.billing_city,
            billingCountryISO: apiCustomer.billing_country_iso,
            billingRegionISO: apiCustomer.billing_region_iso,
            billingPostalCode: apiCustomer.billing_postal_code,
          };
        } else {
          newState = state;
        }
      } else {
        newState = state;
      }
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
