import React, { Fragment } from 'react';
import useForm from 'hooks/useForm';
import useNavigateAway from 'hooks/useNavigateAway';
import { AsyncActionStatus } from 'store/asyncActions';
import validate from 'utils/validate';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/Icon';

import {
  BillingForm,
  setInitialAddressFormState,
  setBillingRegionForm,
} from 'store/forms';

import {
  Countries,
  countryHasRegions,
  getCountrysRegions,
} from 'utils/countries';

interface Props {
  clearMessages: () => void;
  errorMessage: string | undefined;
  form: BillingForm;
  isLoading: AsyncActionStatus;
  onSubmit: (e: BillingForm) => void;
  successMessage: string | undefined;
}

const UpdateBillingAddress = (props: Props) => {
  useNavigateAway(props.clearMessages);

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.isValid) {
      props.onSubmit({
        ...props.form,
        firstName: form.formControls.firstName.value,
        lastName: form.formControls.lastName.value,
        companyName: form.formControls.companyName.value,
        email: form.formControls.email.value,
        phone: form.formControls.phone.value,
        billingAddress1: form.formControls.billingAddress1.value,
        billingAddress2: form.formControls.billingAddress2.value,
        billingCity: form.formControls.billingCity.value,
        billingCountryISO: form.formControls.billingCountry.value,
        billingRegionISO: form.formControls.billingRegion.value,
        billingPostalCode: form.formControls.billingPostalCode.value,
      });
    }
  };

  const { form, setForm, handleFormChange, handleFormSubmit } = useForm(
    setInitialAddressFormState(props.form),
    validate,
    onFormSubmit,
  );

  const handleBillingCountryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    handleFormChange(e);
    setBillingRegionForm(e.target.value, Countries, setForm);
  };

  return (
    <Fragment>
      <div className="row row-spacer">
        <div className="col">
          <h2>Update Billing Address</h2>
        </div>
      </div>
      {props.successMessage && (
        <div className="alert alert-success">
          <Icon name="check" className="pr-4" />
          {props.successMessage}
        </div>
      )}
      {props.errorMessage && (
        <div className="alert alert-danger">
          <Icon name="exclamation-circle" className="pr-4" />
          {props.errorMessage}
        </div>
      )}
      <form noValidate onSubmit={handleFormSubmit}>
        <div className="row mb-4">
          <Input
            id="firstName"
            value={form.formControls.firstName.value || ''}
            onChange={handleFormChange}
            isInvalid={form.formControls.firstName.isValid === false}
            formGroupClassName="col-12 col-lg-3"
            type="text"
            label="First name"
            placeholder="First name"
          />
          <Input
            id="lastName"
            value={form.formControls.lastName.value || ''}
            onChange={handleFormChange}
            isInvalid={form.formControls.lastName.isValid === false}
            formGroupClassName="col-12 col-lg-3"
            type="text"
            label="Last name"
            placeholder="Last name"
          />
          <Input
            id="companyName"
            value={form.formControls.companyName.value || ''}
            onChange={handleFormChange}
            isInvalid={form.formControls.companyName.isValid === false}
            formGroupClassName="col-12 col-lg-6"
            type="text"
            label="Company name"
            placeholder="Company name"
          />
          <Input
            id="email"
            value={form.formControls.email.value || ''}
            onChange={handleFormChange}
            isInvalid={form.formControls.email.isValid === false}
            formGroupClassName="col-12 col-lg-6"
            type="text"
            label="Email"
            placeholder="Email"
          />
          <Input
            id="phone"
            value={form.formControls.phone.value || ''}
            onChange={handleFormChange}
            isInvalid={form.formControls.phone.isValid === false}
            formGroupClassName="col-12 col-lg-6"
            type="text"
            label="Phone"
            placeholder="Phone"
          />
        </div>
        <div className="row">
          <Input
            id="billingAddress1"
            value={form.formControls.billingAddress1.value || ''}
            onChange={handleFormChange}
            isInvalid={form.formControls.billingAddress1.isValid === false}
            formGroupClassName="col-12 col-lg-6"
            type="text"
            label="Billing address"
            placeholder="Billing address"
          />
          <Input
            id="billingAddress2"
            value={form.formControls.billingAddress2.value || ''}
            onChange={handleFormChange}
            isInvalid={form.formControls.billingAddress2.isValid === false}
            type="text"
            label="Billing address 2"
            formGroupClassName="col-12 col-lg-6"
            placeholder="Billing address 2"
          />
          <Input
            id="billingCity"
            value={form.formControls.billingCity.value || ''}
            onChange={handleFormChange}
            isInvalid={form.formControls.billingCity.isValid === false}
            formGroupClassName="col-12 col-lg-6"
            type="text"
            label="City"
            placeholder="City"
          />
          <Select
            id="billingCountry"
            value={form.formControls.billingCountry.value || ''}
            label="Country"
            onChange={handleBillingCountryChange}
            isInvalid={form.formControls.billingCountry.isValid === false}
            formGroupClassName="col-12 col-lg-6"
          >
            <option value="">- Please Select -</option>
            {Object.entries(Countries).map(([iso, country]) => (
              <option key={iso} value={iso}>
                {country.name}
              </option>
            ))}
          </Select>
          {countryHasRegions(
            form.formControls.billingCountry.value,
            Countries,
          ) && (
            <Select
              id="billingRegion"
              value={form.formControls.billingRegion.value || ''}
              label="State/province"
              onChange={handleFormChange}
              isInvalid={form.formControls.billingRegion.isValid === false}
              formGroupClassName="col-12 col-lg-6"
            >
              <option value="">- Please Select -</option>
              {Object.entries(
                getCountrysRegions(
                  form.formControls.billingCountry.value,
                  Countries,
                ),
              ).map(([iso, region]) => (
                <option key={iso} value={iso}>
                  {region.name}
                </option>
              ))}
            </Select>
          )}
          <Input
            id="billingPostalCode"
            value={form.formControls.billingPostalCode.value || ''}
            onChange={handleFormChange}
            isInvalid={form.formControls.billingPostalCode.isValid === false}
            formGroupClassName="col-12 col-lg-6"
            type="text"
            label="Zip/postal code"
            placeholder="Zip/postal code"
          />
        </div>
      </form>
      <SubmitButton
        isLoading={props.isLoading === AsyncActionStatus.STARTED}
        disabled={!form.isValid}
        onClick={handleFormSubmit}
        className="mt-4"
      >
        Update Billing Address
      </SubmitButton>
    </Fragment>
  );
};

export default UpdateBillingAddress;
