declare global {
  interface Window {
    growsumo: any;
    growsumoInit: any;
  }
}

export const initializePartnerStack = (key: string, callback?: () => void) => {
  const gs = document.createElement('script');
  gs.src = 'https://get.quoter.com/pr/js';
  gs.type = 'text/javascript';
  gs.async = true;

  document.addEventListener('readystatechange', ev => {
    const rs = document.readyState;
    if (rs && rs !== 'complete') {
      return;
    }

    try {
      let growsumo: any = window.growsumo;
      growsumo._initialize(key);
      if (typeof window.growsumoInit === 'function') {
        window.growsumoInit();
      }
      if (callback) {
        callback();
      }
    } catch (e) {}
  });
  const s = document.getElementsByTagName('script')[0];
  if (s.parentNode !== null) s.parentNode.insertBefore(gs, s);
};

export const createPartnerStackSignup = (
  name: string,
  email: string,
  customerKey: string,
  callback?: (r: any) => void,
) => {
  if (!window.growsumo) return;

  // Only continue if the signup is from a referral
  if (!window.growsumo.data?.partner_key) return;

  window.growsumo.data.name = name;
  window.growsumo.data.email = email;
  window.growsumo.data.customer_key = customerKey;
  window.growsumo.createSignup(callback);
};
