import React, { Fragment } from 'react';

import style from './Wizard.module.scss';
import { Link } from 'react-router-dom';

interface Props {
  step: 'chooseplan' | 'add-ons' | 'payment';
  paymentLocked: boolean;
}

const steps = [
  {
    id: 'chooseplan',
    text: '1. Choose Plan',
    route: '/plans',
  },
  {
    id: 'add-ons',
    text: '2. Add-Ons',
    route: '/plans/addons',
  },
  {
    id: 'payment',
    text: '3. Payment',
    route: '/plans/payment',
  },
];

const Wizard = (props: Props) => {
  return (
    <Fragment>
      <div className={style.wizard}>
        {steps.map((step, i) => {
          let className = style.wizardItem;

          if (props.step === step.id) {
            className += ' ' + style.active;
          }

          if (props.paymentLocked && step.id === 'payment') {
            return (
              <div key={i} className={`${className} ${style.locked}`}>
                {step.text}
              </div>
            );
          }

          return (
            <Link key={i} to={step.route} className={className}>
              {step.text}
            </Link>
          );
        })}
      </div>
    </Fragment>
  );
};

export default Wizard;
