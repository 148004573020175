import {
  SIGNUP_DETECT_CURRENCY,
  SIGNUP_SUBMIT_ACCOUNT,
  SIGNUP_SET_PLAN,
  SIGNUP_SET_INTERVAL,
  SIGNUP_SELECT_ADDON,
  SIGNUP_REMOVE_ADDON,
  SIGNUP_SUBMIT_ADDONS,
  SIGNUP_SET_BILLING_COUNTRY,
  SIGNUP_SET_BILLING_REGION,
  SIGNUP_SUBMIT_PAYMENT,
  SIGNUP_SUBMIT_COUPON_CODE,
  SIGNUP_APPLY_COUPON_CODE,
  SIGNUP_SET_CURRENCY,
  SignupState,
  SignupActionTypes,
  SIGNUP_CREATE_SUBSCRIPTION,
  SIGNUP_VALIDATE_EMAIL,
  SIGNUP_VALIDATE_SUBDOMAIN,
  SIGNUP_INCREMENT_MULTI_TENANT,
  SIGNUP_DECREMENT_MULTI_TENANT,
  SIGNUP_INCREMENT_ENTERPRISE_QUOTES,
  SIGNUP_DECREMENT_ENTERPRISE_QUOTES,
} from './types';

import { PaymentInterval } from 'store/payment';
import { asyncAction } from '../asyncActions';
import { ReactStripeElements } from 'react-stripe-elements';
import { debugLog } from '../../utils/logging';
import { get, post } from '../../utils/fetch';
import {
  API_BASE_URL,
  IAPIResponse,
  IAPICouponValidation,
  IAPISignup,
} from '../api';
import { Dispatch } from 'redux';
import { AppState } from '..';
import { BillingForm } from 'store/forms';
import { Addon, AddonID } from 'store/plans/types';

export const detectCurrency = () =>
  asyncAction(SIGNUP_DETECT_CURRENCY, detectCountryAPI);

const detectCountryAPI = () => {
  // Alternative API:
  // "http://api.ipstack.com/check?access_key=44086d5e7470f78a94991c0507fef0e9"
  // See also:
  // https://medium.com/@ipdata_co/what-is-the-best-commercial-ip-geolocation-api-d8195cda7027

  if (process.env.NODE_ENV === 'production') {
    return get(
      // Italy test
      // "https://api.ipdata.co/5.158.64.0?api-key=f09bcdc8e29a4ed4047419c157cd7c4553b362116fd18311f7b27957",
      // UK test
      // "https://api.ipdata.co/165.22.125.248?api-key=f09bcdc8e29a4ed4047419c157cd7c4553b362116fd18311f7b27957",
      'https://api.ipdata.co/?api-key=f09bcdc8e29a4ed4047419c157cd7c4553b362116fd18311f7b27957',
      { method: 'GET' },
    );
  }

  // Mock development
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({ parsedBody: { country_code: 'US' } });
    }, 200);
  });
};

export const setPlan = (planID: string | undefined) => ({
  type: SIGNUP_SET_PLAN,
  payload: planID,
});

export const setInterval = (interval: string | undefined) => ({
  type: SIGNUP_SET_INTERVAL,
  payload: interval,
});

export const setCurrency = (iso: string | undefined) => ({
  type: SIGNUP_SET_CURRENCY,
  payload: iso,
});

export const submitAccount = (form: BillingForm) =>
  asyncAction(SIGNUP_SUBMIT_ACCOUNT, postAccountAPI, form);

const postAccountAPI = (form: BillingForm) => {
  // Fake API request for now in case we want to submit the data at some point (e.g. for CRM insertion)
  return new Promise(resolve => {
    setTimeout(() => {
      debugLog('postAccountAPI', form);
      resolve(form);
    }, 200);
  });
};

export const selectAddon = (id: string) => ({
  type: SIGNUP_SELECT_ADDON,
  payload: id,
});

export const removeAddon = (id: string) => ({
  type: SIGNUP_REMOVE_ADDON,
  payload: id,
});

export const submitAddons = (addons: Addon[]) =>
  asyncAction(SIGNUP_SUBMIT_ADDONS, postAddonsAPI, addons);

const postAddonsAPI = (addons: Addon[]) => {
  // Fake API request for now in case we want to submit the data at some point (e.g. for CRM insertion)
  return new Promise(resolve => {
    setTimeout(() => {
      debugLog('postAddonsAPI', addons);
      resolve(addons);
    }, 200);
  });
};

export const incrementMultiTenant = () => ({
  type: SIGNUP_INCREMENT_MULTI_TENANT,
});

export const decrementMultiTenant = () => ({
  type: SIGNUP_DECREMENT_MULTI_TENANT,
});

export const incrementEnterpriseQuotes = () => ({
  type: SIGNUP_INCREMENT_ENTERPRISE_QUOTES,
});

export const decrementEnterpriseQuotes = () => ({
  type: SIGNUP_DECREMENT_ENTERPRISE_QUOTES,
});

export const setBillingCountry = (countryISO: string) => ({
  type: SIGNUP_SET_BILLING_COUNTRY,
  payload: countryISO,
});

export const setBillingRegion = (regionISO: string) => ({
  type: SIGNUP_SET_BILLING_REGION,
  payload: regionISO,
});

// export const applyCouponCode = (
//   currencyISO: string,
//   interval: PaymentInterval,
//   couponCode: string,
// ) =>
//   asyncAction(
//     SIGNUP_APPLY_COUPON_CODE,
//     getCouponAPI,
//     currencyISO,
//     interval,
//     couponCode,
//   );

const getCouponAPI = (
  currencyISO: string,
  interval: PaymentInterval,
  couponCode: string,
) => {
  return post<IAPIResponse<IAPICouponValidation>>(
    `${API_BASE_URL}/validate_coupon`,
    {
      code: couponCode,
      currency: currencyISO.toUpperCase(),
      interval: interval,
    },
  );
};

export const applyCouponCode = (
  currencyISO: string,
  interval: string,
  couponCode: string,
) => (dispatch: Dispatch<SignupActionTypes>, getState: () => AppState) => {
  // Dispatch the SIGNUP_SUBMIT_COUPON_CODE action to save the coupon code to the store
  dispatch({
    type: SIGNUP_SUBMIT_COUPON_CODE,
    payload: couponCode,
  });

  return asyncAction(
    SIGNUP_APPLY_COUPON_CODE,
    getCouponAPI,
    currencyISO,
    interval,
    couponCode,
  )(dispatch);
};

export const validateEmail = (email: string) =>
  asyncAction(SIGNUP_VALIDATE_EMAIL, getValidateEmailAPI, email);

const getValidateEmailAPI = (email: string) => {
  return post<IAPIResponse<null>>(`${API_BASE_URL}/validate_email`, {
    email: email.toLowerCase(),
  });
};

export const validateSubdomain = (subdomain: string) =>
  asyncAction(SIGNUP_VALIDATE_SUBDOMAIN, getValidateSubdomainAPI, subdomain);

const getValidateSubdomainAPI = (subdomain: string) => {
  return post<IAPIResponse<null>>(`${API_BASE_URL}/validate_subdomain`, {
    subdomain: subdomain.toLowerCase(),
  });
};

export const submitPayment = (
  form: BillingForm,
  stripe: ReactStripeElements.StripeProps,
) => (dispatch: Dispatch<SignupActionTypes>, getState: () => AppState) => {
  // Dispatch the SIGNUP_SUBMIT_PAYMENT action to save the payment form to the store
  dispatch({
    type: SIGNUP_SUBMIT_PAYMENT,
    payload: form,
  });

  let state = getState();
  let signup = {
    ...state.signup,
    form: {
      ...state.signup.form,
      ...form,
    },
  };

  // Dispatch the async action to create the subscription
  return asyncAction(
    SIGNUP_CREATE_SUBSCRIPTION,
    createStripeTokenAndSubscriptionAPI,
    signup,
    stripe,
  )(dispatch);
};

const createStripeTokenAndSubscriptionAPI = async (
  signup: SignupState,
  stripe: ReactStripeElements.StripeProps,
) => {
  // Create the Stripe token. An error will be thrown if the token could not be created.
  let token = await createStripeTokenAPI(signup, stripe);
  if (token) {
    signup.form.stripeToken = token.id;
    if (token.card) {
      signup.form.stripeCardID = token.card.id;
      signup.form.creditCardLast4 = token.card.last4;
      signup.form.creditCardExpiryMonth = token.card.exp_month;
      signup.form.creditCardExpiryYear = token.card.exp_year;
    }

    return postSubscriptionAPI(signup);
  }

  // This should never execute, but left as a fail-safe.
  throw new Error('Failed to create Stripe token.');
};

const createStripeTokenAPI = async (
  signup: SignupState,
  stripe: ReactStripeElements.StripeProps,
) => {
  let { form } = signup;
  let tokenOptions: stripe.TokenOptions = {
    name: form.billingCardHolder,
    address_line1: form.billingAddress1,
    address_line2: form.billingAddress2,
    address_city: form.billingCity,
    address_zip: form.billingPostalCode,
  };
  if (form.billingCountryISO)
    tokenOptions.address_country = form.billingCountryISO;
  if (form.billingRegionISO) tokenOptions.address_state = form.billingRegionISO;

  let { token, error: stripeError } = await stripe.createToken(tokenOptions);

  debugLog('Stripe Token', token);

  if (token) {
    return token;
  }

  if (stripeError) {
    throw stripeError;
  } else {
    // This should never happen, but left as a fail-safe.
    throw new Error(
      'Stripe failed to return a valid response. Please contact support at support@quoter.com.',
    );
  }
};

const postSubscriptionAPI = (signup: SignupState) => {
  debugLog('postSubscriptionAPI', signup);

  let body = {
    company: signup.form.companyName,
    subdomain: signup.form.subdomain,
    first_name: signup.form.firstName,
    last_name: signup.form.lastName,
    email: signup.form.email,
    password: signup.form.password,
    phone: signup.form.phone,
    plan: signup.plan.id,
    addons: Object.keys(signup.addons).reduce(
      (memo: { [id: string]: number }, addonID) => {
        if (addonID === AddonID.MULTI_TENANT_USERS) {
          memo[addonID] = signup.chooseAddonsView.multiTenantUsers;
        } else {
          memo[addonID] = 1;
        }
        return memo;
      },
      {},
    ),
    currency_iso: signup.currency.iso,
    interval: signup.interval,
    commitment: 'none',
    coupon: signup.couponCode,
    billing_address1: signup.form.billingAddress1,
    billing_address2: signup.form.billingAddress2,
    billing_city: signup.form.billingCity,
    billing_country_iso: signup.form.billingCountryISO,
    billing_region_iso: signup.form.billingRegionISO,
    billing_postal_code: signup.form.billingPostalCode,
    billing_cardholder: signup.form.billingCardHolder,
    credit_card_token: signup.form.stripeToken,
    credit_card_id: signup.form.stripeCardID,
    credit_card_last_4: signup.form.creditCardLast4,
    credit_card_expiry_month: signup.form.creditCardExpiryMonth,
    credit_card_expiry_year: signup.form.creditCardExpiryYear,
    recaptcha_token: signup.form.recaptchaToken,
    subscription_id: signup.subscriptionID,
  };

  return post<IAPIResponse<IAPISignup>>(`${API_BASE_URL}/subscription`, body);

  // return new Promise(resolve => {
  //   setTimeout(() => {
  //     debugLog("postSignupAPI", body);
  //     resolve(body);
  //   }, 500);
  // });
};
