import { Form } from 'hooks/useForm';
import { Country, countryHasRegions } from 'utils/countries';
import { ReactStripeElements } from 'react-stripe-elements';

export interface BillingForm {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  subdomain?: string;
  email?: string;
  password?: string;
  phone?: string;
  billingAddress1?: string;
  billingAddress2?: string;
  billingCity?: string;
  billingCountryISO?: string;
  billingRegionISO?: string | null;
  billingPostalCode?: string;
  billingCardHolder?: string;
  stripeToken?: string;
  stripeCardID?: string;
  creditCardLast4?: string;
  creditCardExpiryMonth?: number;
  creditCardExpiryYear?: number;
  recaptchaToken?: string;
}

export const setInitialBillingFormState = (): Form => ({
  formControls: {
    cardNumber: {},
    cardExpiry: {},
    cardCvc: {},
    billingCardHolder: {
      value: '',
      validationRules: {
        isRequired: true,
      },
    },
    billingAddress1: {
      value: '',
      validationRules: {
        isRequired: true,
      },
    },
    billingAddress2: {
      isValid: true,
      value: '',
      validationRules: {
        isRequired: false,
      },
    },
    billingCity: {
      value: '',
      validationRules: {
        isRequired: true,
      },
    },
    billingCountry: {
      value: '',
      validationRules: {
        isRequired: true,
      },
    },
    billingRegion: {
      value: '',
      validationRules: {
        isRequired: true,
      },
    },
    billingPostalCode: {
      value: '',
      validationRules: {
        isRequired: true,
        minLength: 3,
        maxLength: 8,
      },
    },
  },
});

export const setInitialAddressFormState = (form: BillingForm): Form => ({
  formControls: {
    firstName: {
      isValid: form.firstName ? true : undefined,
      value: form.firstName || '',
      validationRules: {
        isRequired: true,
      },
    },
    lastName: {
      isValid: form.lastName ? true : undefined,
      value: form.lastName || '',
      validationRules: {
        isRequired: true,
      },
    },
    companyName: {
      isValid: form.companyName ? true : undefined,
      value: form.companyName || '',
      validationRules: {
        isRequired: false,
        maxLength: 100,
      },
    },
    email: {
      isValid: form.email ? true : undefined,
      value: form.email || '',
      validationRules: {
        isRequired: true,
        isEmail: true,
      },
    },
    phone: {
      isValid: form.phone ? true : undefined,
      value: form.phone || '',
      validationRules: {
        isRequired: false,
        minLength: 10,
        maxLength: 20,
      },
    },
    billingAddress1: {
      isValid: form.billingAddress1 ? true : undefined,
      value: form.billingAddress1 || '',
      validationRules: {
        isRequired: true,
      },
    },
    billingAddress2: {
      isValid: true,
      value: form.billingAddress2 || '',
      validationRules: {
        isRequired: false,
      },
    },
    billingCity: {
      isValid: form.billingCity ? true : undefined,
      value: form.billingCity || '',
      validationRules: {
        isRequired: true,
      },
    },
    billingCountry: {
      isValid: form.billingCountryISO !== undefined ? true : undefined,
      value: form.billingCountryISO || '',
      validationRules: {
        isRequired: true,
      },
    },
    billingRegion: {
      isValid: form.billingRegionISO !== undefined ? true : undefined,
      value: form.billingRegionISO || '',
      validationRules: {
        isRequired: true,
      },
    },
    billingPostalCode: {
      isValid: form.billingPostalCode ? true : undefined,
      value: form.billingPostalCode || '',
      validationRules: {
        isRequired: true,
        minLength: 3,
        maxLength: 8,
      },
    },
  },
});

export const setBillingRegionForm = (
  country: string | undefined,
  countries: { [iso: string]: Country },
  setForm: (value: React.SetStateAction<Form>) => void,
) => {
  if (countryHasRegions(country, countries)) {
    setForm((form: Form) => ({
      ...form,
      formControls: {
        ...form.formControls,
        billingRegion: {
          ...form.formControls.billingRegion,
          value: undefined,
          isValid: undefined,
          validationRules: {
            isRequired: true,
          },
        },
      },
    }));
  } else {
    setForm((form: Form) => ({
      ...form,
      formControls: {
        ...form.formControls,
        billingRegion: {
          ...form.formControls.billingRegion,
          value: undefined,
          isValid: true,
          validationRules: {
            isRequired: false,
          },
        },
      },
    }));
  }
};

export const setStripeElementForm = (
  e: ReactStripeElements.ElementChangeResponse,
  setForm: (value: React.SetStateAction<Form>) => void,
) => {
  if (e.complete) {
    setForm((form: Form) => ({
      ...form,
      formControls: {
        ...form.formControls,
        [e.elementType]: {
          ...form.formControls[e.elementType],
          isValid: true,
        },
      },
    }));
  } else {
    setForm((form: Form) => ({
      ...form,
      formControls: {
        ...form.formControls,
        [e.elementType]: {
          ...form.formControls[e.elementType],
          isValid: false,
        },
      },
    }));
  }
};
