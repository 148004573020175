import { IAPIOnboardingCoupon, IAPIPlanCoupon } from './api';

export enum PaymentInterval {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export interface OnboardingCoupon {
  id: string;
  name: string;
  amountOffInCents: number | null;
  percentageOff: number | null;
}

export interface PlanCoupon {
  id: string;
  name: string;
  amountOffInCents: number | null;
  percentageOff: number | null;
  duration: 'forever' | 'once' | 'repeating';
  durationInMonths: number | null;
}

export interface PaymentAmounts {
  onboardingDiscount?: number;
  planDiscount?: number;
  subtotal?: number;
  total?: number;
  onboarding?: number;
  recurring?: number;
  hst?: number;
  gst?: number;
  pst?: number;
  taxes?: Array<{
    name: string;
    amount: number;
  }>;
}

export interface PaymentSource {
  id: string;
  billingCardholder: string;
  creditCardLast4: string;
  creditCardExpiryMonth: number;
  creditCardExpiryYear: number;
  brand: string;
  default: boolean;
}

export type UIPaymentMethod = 'existingPaymentMethod' | 'newPaymentMethod';

export const convertAPIOnboardingCoupon = (
  apiCoupon: IAPIOnboardingCoupon | null,
): OnboardingCoupon | null => {
  if (apiCoupon === null) {
    return null;
  }

  let coupon: OnboardingCoupon = {
    id: apiCoupon.id,
    name: apiCoupon.name,
    amountOffInCents: apiCoupon.amount_off_in_cents,
    percentageOff: apiCoupon.percentage_off,
  };

  return coupon;
};

export const convertAPIPlanCoupon = (
  apiCoupon: IAPIPlanCoupon | null,
): PlanCoupon | null => {
  if (apiCoupon === null) {
    return null;
  }

  let coupon: PlanCoupon = {
    id: apiCoupon.id,
    name: apiCoupon.name,
    amountOffInCents: apiCoupon.amount_off_in_cents,
    percentageOff: apiCoupon.percentage_off,
    duration: 'once',
    durationInMonths: apiCoupon.duration_in_months,
  };

  switch (apiCoupon.duration_type) {
    case 'forever':
      coupon.duration = 'forever';
      break;
    case 'repeating':
      coupon.duration = 'repeating';
      break;
    default:
      coupon.duration = 'once';
      break;
  }

  return coupon;
};

export const disableSubmitPaymentButton = (
  paymentMethod: UIPaymentMethod,
  formIsValid?: boolean,
) => {
  if (paymentMethod === 'existingPaymentMethod') {
    return false;
  }

  return !formIsValid;
};
