import { AsyncAction } from 'store/asyncActions';
import { IAPIPlansAndAddons, IAPIResponse } from 'store/api';
import { IHttpResponse } from 'utils/fetch';

export interface Plan {
  id: PlanID;
  name: string;
  pricing: {
    [id: string]: PriceInterval;
  };
  numQuotesIncluded: number;
  unlimitedQuotes: boolean;
  numUsersIncluded: number;
  unlimitedUsers: boolean;
  featuresIncluded: {
    [name in AddonID]: boolean;
  };
  availableAddons: {
    [name in AddonID]: boolean;
  };
}

export type Plans = { [name: string]: Plan };

export type Addons = { [name: string]: Addon };

export interface PriceInterval {
  unitPriceInCents: number;
  priceTiers?: PriceTier[];
}

export interface PriceTier {
  upToQuantity: number;
  upToInfinity: boolean;
  flatPrice: number;
  unitPrice: number | null;
}

export enum PlanID {
  BASIC = 'basic',
  STANDARD = 'standard',
  PRO = 'pro',
  ENTERPRISE = 'enterprise',
  CUSTOM = 'custom',
}

export interface Addon {
  id: AddonID;
  name: string;
  pricing: {
    [id: string]: PriceInterval;
  };
  icon: string;
  description: string;
  features: string[];
}

export enum AddonID {
  AVALARA_TAX_INTEGRATION = 'avalara-tax-integration',
  BUNDLES = 'bundles',
  CONTRACTS = 'contracts',
  ENTERPRISE_INTEGRATIONS = 'enterprise-integrations',
  ENTERPRISE_QUOTES = 'enterprise-quotes',
  LINE_ITEM_BULK_EDIT = 'line-item-bulk-edit',
  LINE_ITEM_IMPORT = 'line-item-import',
  LINE_ITEM_PRICE_MODIFIERS = 'line-item-price-modifiers',
  MANAGER_APPROVALS = 'manager-approvals',
  MULTI_TENANT_USERS = 'multi-tenant-users',
  QUOTE_REMINDERS = 'quote-reminders',
  REPORTING = 'reporting',
  SAML = 'saml',
  SINGLE_SELECT_GROUPS = 'single-select-groups',
}

export enum PlansActions {
  GET_PLANS = 'GET_PLANS',
}

export type getPlansAsyncAction = AsyncAction<
  PlansActions.GET_PLANS,
  IHttpResponse<IAPIResponse<IAPIPlansAndAddons>>
>;

export type PlansTypes = getPlansAsyncAction;
