import DateNumber from 'components/DateNumber';
import React from 'react';
import { Subscription } from 'store/subscription/types';

type Props = {
  subscription: Subscription;
};

const CancelledAccount = (props: Props) => {
  return (
    <div className="row row-spacer">
      <div className="col-12 order-last col-lg order-lg-first">
        <h4 className="pt-3">Account Cancelled</h4>
        <div className="pt-2 pb-4">
          <p>
            Your account with Quoter was cancelled on{' '}
            <DateNumber value={props.subscription.cancelledAt || ''} />. Please
            conact sales to reactivate your subscirption.
          </p>
          <a
            className="btn btn-outline-primary px-3"
            href="mailto:support@quoter.com"
          >
            Contact Sales
          </a>
        </div>
      </div>
    </div>
  );
};

export default CancelledAccount;
