import React from 'react';
import { PaymentSource } from 'store/payment';
import Card from 'containers/Billing/components/Card';
import CreditCard from 'containers/Billing/components/CreditCard';
import style from './PaymentMethod.module.scss';

interface Props {
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  paymentSource: PaymentSource | undefined;
}

const PaymentMethodExisting = (props: Props) => {
  if (!props.paymentSource) {
    return null;
  }

  return (
    <Card removePadding title="Payment Method" className="mb-2">
      <label className={style.rowLabel} htmlFor={props.id}>
        <div className={style.colRadioButton}>
          <div className="custom-control custom-radio d-inline-block">
            <input
              type="radio"
              id={props.id}
              name="paymentMethod"
              className="custom-control-input"
              defaultChecked={true}
              onChange={props.onChange}
            />
            <div className="custom-control-label"></div>
          </div>
        </div>
        <div className={style.colCreditCard}>
          <CreditCard brand={props.paymentSource.brand} size="44" />
        </div>
        <div className="col">
          <h5 className={style.paymentTitle}>
            Ending in **** {props.paymentSource.creditCardLast4}
          </h5>
          <div className="text-muted">{props.paymentSource.brand}</div>
        </div>
      </label>
    </Card>
  );
};

export default PaymentMethodExisting;
