import { connect } from 'react-redux';
import { AppState } from 'store';

import {
  setInterval,
  setPlanId,
  continueToAddons,
} from 'store/paymentWizard/actions';

import selectPlanById from 'selectors/selectPlanById';
import selectSubscriptionCurrency from 'selectors/selectSubscriptionCurrency';
import selectIsPaymentDisabled from 'selectors/selectIsPaymentDisabled';

import ChoosePlan from './ChoosePlan';

const mapStateToProps = (state: AppState) => ({
  currency: selectSubscriptionCurrency(state),
  selectedPlan: selectPlanById(state),
  selectedPlanInterval: state.paymentWizard.planInterval,
  subscriptionPlan: state.subscription.plan,
  plans: state.plans.plans,
  plansLoading: state.loading.plans,
  subscriptionLoading: state.loading.subscription,
  isPaymentDisabled: selectIsPaymentDisabled(state),
});

const mapDispatchToProps = {
  setInterval: setInterval,
  setPlanId: setPlanId,
  continueToAddons: continueToAddons,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePlan);
