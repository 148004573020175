import zxcvbn from 'zxcvbn';

export interface ValidationRules {
  [key: string]: any;
}

/**
 * Validate a given `value` with the provided `rules`.
 * @param value
 * @param rules
 */
const validate = (value: string, rules: ValidationRules) => {
  let isValid = true;

  for (let rule in rules) {
    // Rule value can be falsy, e.g. isRequired: false.
    if (rules[rule] === false) {
      continue;
    }

    switch (rule) {
      case 'minLength':
        isValid = isValid && minLengthValidator(value, rules[rule]);
        break;

      case 'maxLength':
        isValid = isValid && maxLengthValidator(value, rules[rule]);
        break;

      case 'isRequired':
        isValid = isValid && requiredValidator(value);
        break;

      case 'isPhoneNumber':
        isValid = isValid && phoneNumberValidator(value);
        break;

      case 'isEmail':
        isValid = isValid && emailValidator(value);
        break;

      case 'regex':
        isValid = isValid && regexValidator(value, rules[rule]);
        break;

      case 'password':
        isValid = isValid && strongPasswordValidator(value, rules[rule]);
        break;

      default:
        console.error(`${rule} is not a supported validation rule`);
        isValid = true;
    }
  }

  return isValid;
};

/**
 * Minimum length validation
 * @param  value
 * @param  minLength
 * @return
 */
const minLengthValidator = (value: string, minLength: number) => {
  return value.length >= minLength;
};

/**
 * Maximum length validation
 * @param  value
 * @param  minLength
 * @return
 */
const maxLengthValidator = (value: string, maxLength: number) => {
  return value.length <= maxLength;
};

/**
 * Required field validation
 *
 * @param  value
 * @return
 */
const requiredValidator = (value: string) => {
  return value.trim() !== '';
};

/**
 * Phone Number validation
 *
 * @param value
 * @return bool
 */
const phoneNumberValidator = (value: string): boolean => {
  const chars = value.match(/\d/g);
  return (chars && chars.length >= 10) || false;
};

/**
 * Email validation
 *
 * @param value
 * @return
 */
const emailValidator = (value: string) => {
  // eslint-disable-next-line
  var re = /^[\w\+\.=-]+@[\w\.-]+\.[\w]{2,8}$/;
  return re.test(String(value).toLowerCase());
};

/**
 * Email validation
 *
 * @param value
 * @return
 */
const regexValidator = (value: string, re: RegExp) => {
  return re.test(String(value));
};

/**
 * Strong password validation
 *
 * @param value
 * @return
 */
const strongPasswordValidator = (value: string, score: number) => {
  let result = zxcvbn(value);
  return result.score >= score;
};

export default validate;
