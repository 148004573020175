import React, { useState, useEffect } from 'react';
import { APP_QUOTER_URL } from 'store/api';
import { BillingForm } from 'store/forms';
import { createPartnerStackSignup } from '../../utils/partnerStack';

type Props = {
  form: BillingForm;
};

export default function ThankYouView(props: Props) {
  // This pattern allows us to replace componentDidMount behaviour with React Hooks.
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized) {
      let name = props.form.firstName;
      if (props.form.lastName && props.form.lastName !== '')
        name += ` ${props.form.lastName}`;
      let email = props.form.email;
      if (name && email) createPartnerStackSignup(name, email, email);

      setInitialized(true);
    }
  }, [
    initialized,
    props.form.firstName,
    props.form.lastName,
    props.form.email,
    setInitialized,
  ]);

  return (
    <div className="ThankYouView">
      <h1 className="text-center">Your account has been created</h1>

      <div className="pt-5 pb-3">
        <p>
          Thank you for signing up for Quoter. Your account has been provisioned
          and you can{' '}
          <a href={APP_QUOTER_URL} target="_blank" rel="noopener noreferrer">
            sign in
          </a>{' '}
          immediately.
        </p>
        <p>
          The next step is to book your first onboarding session at a time that
          is convenient for you. If you'd rather not do this now, don't worry, a
          member of our team will be in touch.
        </p>
        {/* <p>
          If you have any questions in the meantime, please{" "}
          <a
            href="https://www.quoter.com/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </a>
          .
        </p> */}
      </div>
      <div>
        <a
          href="https://calendly.com/quoter-partner-success/onboarding-session-1"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary btn-md"
          role="button"
        >
          Book Onboarding Session
        </a>{' '}
        &nbsp;
        <a
          href={APP_QUOTER_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-outline-primary btn-md"
          role="button"
        >
          Sign Into Quoter
        </a>
      </div>
    </div>
  );
}
