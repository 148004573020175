import React, { forwardRef } from 'react';
import classNames from 'classnames';

type Props = {
  id: string;
  value?: string;
  defaultValue?: string;
  type?: string;
  label?: string;
  placeholder?: string;
  inputPrepend?: React.ReactElement | string;
  inputAppend?: React.ReactElement | string;
  helperText?: React.ReactElement | string;
  isValid?: boolean;
  validFeedback?: string | null;
  isInvalid?: boolean;
  invalidFeedback?: string | null;
  formGroupClassName?: string;
  inputClassName?: string;
  onChange?: React.ChangeEventHandler;
  disabled?: boolean;
  required?: boolean;
  children?: React.ReactElement;
};

export type Ref = HTMLInputElement;

const Input = forwardRef<Ref, Props>((props, ref) => {
  const {
    id,
    value,
    defaultValue,
    type,
    label,
    placeholder,
    inputPrepend,
    inputAppend,
    helperText,
    isValid,
    validFeedback,
    isInvalid,
    invalidFeedback,
    formGroupClassName,
    inputClassName,
    onChange,
    disabled,
    required,
    children,
  } = props;

  return (
    <div className={classNames('form-group', formGroupClassName)}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="input-group">
        {inputPrepend && (
          <div className="input-group-prepend">
            <span className="input-group-text">{inputPrepend}</span>
          </div>
        )}
        {children ? (
          children
        ) : (
          <input
            id={id}
            type={type || 'text'}
            ref={ref}
            className={classNames('form-control', inputClassName, {
              'is-valid': isValid,
              'is-invalid': isInvalid,
            })}
            aria-describedby={`${id}Help`}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
            required={required}
          />
        )}
        {inputAppend && (
          <div className="input-group-append">
            <span className="input-group-text">{inputAppend}</span>
          </div>
        )}
        {isValid && validFeedback && (
          <div className="valid-feedback">{validFeedback}</div>
        )}
        {isInvalid && invalidFeedback && (
          <div className="invalid-feedback">{invalidFeedback}</div>
        )}
      </div>
      {!isInvalid && helperText && (
        <small id={`${id}Help`} className="form-text text-muted">
          {helperText}
        </small>
      )}
    </div>
  );
});

export default Input;
