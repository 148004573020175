import React, { Fragment } from 'react';
import { Invoice } from 'store/invoices/types';
import { AsyncActionStatus } from 'store/asyncActions';

import InvoiceList from 'containers/Billing/components/InvoiceList';
import ComponentSpinner from 'containers/Billing/components/ComponentSpinner';

interface ConnectedProps {
  invoices: Invoice[];
  invoicesLoading: AsyncActionStatus;
}

const Invoices = (props: ConnectedProps) => {
  const hasInvoices = props.invoices.length >= 1;
  const isLoading =
    props.invoicesLoading === AsyncActionStatus.STARTED ||
    props.invoicesLoading === AsyncActionStatus.UNSTARTED;

  let subView;

  if (isLoading) {
    subView = <ComponentSpinner />;
  } else if (hasInvoices === false) {
    subView = (
      <div className="text-muted text-center mt-5">
        Plans could not be loaded. Please contact support@quoter.com
      </div>
    );
  } else {
    subView = <InvoiceList invoices={props.invoices} />;
  }

  return (
    <Fragment>
      <div className="row row-spacer">
        <div className="col">
          <h2>Invoices</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">{subView}</div>
      </div>
    </Fragment>
  );
};

export default Invoices;
