import React from 'react';

type Props = {
  valueNow?: number;
  valueMin?: number;
  valueMax?: number;
  valueThresholds?: {
    [limit: number]: string;
  };
};

export default function ProgressBar(props: Props) {
  let percentage = 0;
  let valueNow = props.valueNow || 0;
  let valueMin = props.valueMin || 0;
  let valueMax = props.valueMax || 100;

  percentage = (valueNow / valueMax) * 100;
  if (percentage < valueMin) percentage = valueMin;

  let status = 'bg-danger';
  for (let limit in props.valueThresholds) {
    if (percentage >= Number(limit)) {
      status = props.valueThresholds[Number(limit)];
    }
  }

  return (
    <div className="ProgressBar progress">
      <div
        className={`progress-bar ${status}`}
        style={{ width: `${percentage}%` }}
        role="progressbar"
        aria-valuenow={valueNow}
        aria-valuemin={valueMin}
        aria-valuemax={valueMax}
      />
    </div>
  );
}
