import { ReactStripeElements } from 'react-stripe-elements';
import { AsyncActionStatus } from 'store/asyncActions';
import { getCustomer, postCreditCard } from 'store/customer/actions';
import { BillingForm } from 'store/forms';
import { postPayAmountOwing } from 'store/subscription/actions';
import { ThunkyDispatch } from 'store/types';
import { clearMessagesAction, PayAmountOwingFormActions } from './types';

export const onSubmitExistingPaymentMethod = () => {
  return async (dispatch: ThunkyDispatch) => {
    await dispatch(postPayAmountOwing());
    return dispatch(getCustomer());
  };
};

export const onSubmitNewPaymentMethod = (
  stripe: ReactStripeElements.StripeProps,
  formData: BillingForm,
) => {
  return async (dispatch: ThunkyDispatch) => {
    const postCreditCardResult = await dispatch(
      postCreditCard(stripe, formData),
    );

    if (postCreditCardResult.status === AsyncActionStatus.SUCCEEDED) {
      await dispatch(postPayAmountOwing());
      return dispatch(getCustomer());
    }
  };
};

export const clearMessages = (): clearMessagesAction => ({
  type: PayAmountOwingFormActions.CLEAR_MESSAGES,
});
