import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import redirectMiddleware from 'middleware/redirectMiddleware';
import { reduceAsyncActionStatusByDomain } from './asyncActions';
import { addons } from './plans/addonsReducer';
import { billingAddressForm } from './billingAddressForm/reducer';
import { customer } from './customer/reducer';
import { allInvoices } from './invoices/invoicesReducer';
import { paymentMethodForm } from './paymentMethodForm/reducer';
import { paymentWizard } from './paymentWizard/reducer';
import { plans } from './plans/plansReducer';
import { signupReducer } from './signup/reducers';
import { subscription } from './subscription/reducer';
import { upcomingInvoice } from './invoices/upcomingInvoiceReducer';
import { payAmountOwingForm } from './payAmountOwingForm/reducer';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const rootReducer = combineReducers({
  router: connectRouter(history),
  billingAddressForm: billingAddressForm,
  customer: customer,
  invoices: combineReducers({
    allInvoices: allInvoices,
    upcomingInvoice: upcomingInvoice,
  }),
  loading: reduceAsyncActionStatusByDomain,
  payAmountOwingForm: payAmountOwingForm,
  paymentMethodForm: paymentMethodForm,
  paymentWizard: paymentWizard,
  plans: combineReducers({
    addons: addons,
    plans: plans,
  }),
  signup: signupReducer,
  subscription: subscription,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middlewares = [thunk, routerMiddleware(history), redirectMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer),
  );

  return store;
}
