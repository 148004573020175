import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Plan, Addons, AddonID } from 'store/plans/types';
import {
  OnboardingCoupon,
  PlanCoupon,
  PaymentInterval,
  PaymentAmounts,
} from 'store/payment';
import { Currency } from 'utils/countries';

import {
  calcMonthlyPrice,
  convertAPIPaymentInterval,
} from 'store/plans/utility';

import CurrencyNumber from 'components/CurrencyNumber';
import SubscriptionItem from './SubscriptionItem';
import CouponCode from './CouponCode';
import Select from 'components/Form/Select';
import { setIntervalAction } from 'store/paymentWizard/types';

interface Props {
  detectedCountryISO?: string;
  plan: Plan;
  addons: Addons;
  couponCode?: string | null;
  onboardingCoupon?: OnboardingCoupon | null;
  planCoupon?: PlanCoupon | null;
  amounts?: PaymentAmounts;
  interval: PaymentInterval;
  currency: Currency;
  showSubscriptionSummary?: boolean;
  showIntervalSelection?: boolean;
  showProratedSting?: boolean;
  multiTenantUsers?: number;
  enterpriseQuotes?: number;
  couponErrorMessage?: string | null;
  couponIsLoading?: boolean;
  couponCodeInvalid?: boolean;
  applyCouponCode?: (
    currencyISO: string,
    interval: PaymentInterval,
    couponCode: string,
  ) => any;
  onIntervalChange?: (interval: PaymentInterval) => setIntervalAction;
}

const YourSubscription = (props: Props) => {
  const intervalLong =
    props.interval === PaymentInterval.YEARLY ? 'annually' : 'monthly';

  const intervalShort = props.interval === PaymentInterval.YEARLY ? 'yr' : 'mo';

  const handleCouponApply = (couponCode: string) => {
    if (props.applyCouponCode) {
      props.applyCouponCode(props.currency.iso, props.interval, couponCode);
    }
  };

  const handleIntervalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.onIntervalChange) {
      props.onIntervalChange(convertAPIPaymentInterval(e.target.value));
    }
  };

  return (
    <Fragment>
      <h4 className="mb-0" data-detected-country={props.detectedCountryISO}>
        Your subscription
      </h4>
      <div className="mt-3">
        <SubscriptionItem
          type="plan"
          name={props.plan.name}
          monthlyPrice={calcMonthlyPrice(props.interval, props.plan)}
          interval={props.interval}
          currency={props.currency}
        />
        {Object.keys(props.addons).map(key => (
          <SubscriptionItem
            key={key}
            type="addon"
            name={props.addons[key].name}
            monthlyPrice={calcMonthlyPrice(
              props.interval,
              props.addons[key],
              props.addons[key].id === AddonID.MULTI_TENANT_USERS
                ? props.multiTenantUsers
                : props.enterpriseQuotes,
            )}
            interval={props.interval}
            currency={props.currency}
            multiTenantUsers={
              key === AddonID.MULTI_TENANT_USERS
                ? props.multiTenantUsers
                : undefined
            }
            enterpriseQuotes={
              key === AddonID.ENTERPRISE_QUOTES
                ? props.enterpriseQuotes
                : undefined
            }
          />
        ))}
        {props.amounts && (
          <div
            className={classNames({
              'd-none': !props.showSubscriptionSummary,
            })}
          >
            <h4 className="mt-5">Summary</h4>
            {props.showIntervalSelection && (
              <Fragment>
                <Select
                  id="interval"
                  value={props.interval}
                  onChange={handleIntervalChange}
                  inputClassName="mt-2"
                >
                  <option value={PaymentInterval.YEARLY}>Annual Plan</option>
                  <option value={PaymentInterval.MONTHLY}>Monthly Plan</option>
                </Select>
              </Fragment>
            )}
            {!!props.amounts.recurring && (
              <div className="row text-muted mb-1">
                <span className="col">Total {intervalLong}</span>
                <span className="col-auto">
                  <CurrencyNumber
                    value={props.amounts.recurring}
                    currency={props.currency}
                    decimals={0}
                  />
                  /{intervalShort}
                </span>
              </div>
            )}
            {!!props.amounts.onboarding && props.amounts.onboarding > 0 && (
              <div className="row text-muted mb-1">
                <span className="col">Onboarding</span>
                <span className="col-auto">
                  <CurrencyNumber
                    value={props.amounts.onboarding}
                    currency={props.currency}
                    decimals={2}
                  />
                </span>
              </div>
            )}
            {props.onboardingCoupon &&
              !!props.amounts.onboardingDiscount &&
              props.amounts.onboardingDiscount > 0 && (
                <div className="row text-muted mb-1">
                  <span className="col">Onboarding Discount</span>
                  <span className="col-auto">
                    (
                    <CurrencyNumber
                      value={props.amounts.onboardingDiscount}
                      currency={props.currency}
                      decimals={2}
                    />
                    )
                  </span>
                </div>
              )}
            {props.planCoupon &&
              !!props.amounts.planDiscount &&
              props.amounts.planDiscount > 0 && (
                <div className="row text-muted mb-1">
                  <span className="col">Plan Discount</span>
                  <span className="col-auto">
                    (
                    <CurrencyNumber
                      value={props.amounts.planDiscount}
                      currency={props.currency}
                      decimals={2}
                    />
                    )
                  </span>
                </div>
              )}
            {props.amounts.taxes?.map((tax, i) => (
              <div key={i} className="row text-muted mb-1">
                <span className="col">{tax.name}</span>
                <span className="col-auto">
                  <CurrencyNumber
                    value={tax.amount}
                    currency={props.currency}
                    decimals={2}
                  />
                </span>
              </div>
            ))}
            {!!props.amounts.gst && props.amounts.gst > 0 && (
              <div className="row text-muted mb-1">
                <span className="col">GST</span>
                <span className="col-auto">
                  <CurrencyNumber
                    value={props.amounts.gst}
                    currency={props.currency}
                    decimals={2}
                  />
                </span>
              </div>
            )}
            {!!props.amounts.hst && props.amounts.hst > 0 && (
              <div className="row text-muted mb-1">
                <span className="col">HST</span>
                <span className="col-auto">
                  <CurrencyNumber
                    value={props.amounts.hst}
                    currency={props.currency}
                    decimals={2}
                  />
                </span>
              </div>
            )}
            {!!props.amounts.pst && props.amounts.pst > 0 && (
              <div className="row text-muted mb-1">
                <span className="col">PST</span>
                <span className="col-auto">
                  <CurrencyNumber
                    value={props.amounts.pst}
                    currency={props.currency}
                    decimals={2}
                  />
                </span>
              </div>
            )}
            <CouponCode
              onApply={handleCouponApply}
              isLoading={props.couponIsLoading === true}
              isInvalid={props.couponCodeInvalid === true}
              errorMessage={props.couponErrorMessage}
              appliedCouponCode={props.couponCode}
              appliedOnboardingCoupon={props.onboardingCoupon}
              appliedPlanCoupon={props.planCoupon}
            />
            <hr />
            {(props.amounts.total || props.amounts.total === 0) && (
              <div className="row mb-2 font-weight-bold">
                <span className="col" style={{ fontSize: 18 }}>
                  Due today
                </span>
                <span className="col-auto" style={{ fontSize: 18 }}>
                  <CurrencyNumber
                    value={props.amounts.total}
                    currency={props.currency}
                    decimals={2}
                  />
                  {props.showProratedSting && <span>*</span>}
                </span>
              </div>
            )}
            {props.showProratedSting && (
              <p className="text-muted text-right small">
                * Estimated prorated amount based on per-second billing.
              </p>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default YourSubscription;
