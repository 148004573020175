import { SubscriptionActions } from './types';
import { ThunkyDispatch, ThunkyAction } from 'store/types';
import { asyncAction } from 'store/asyncActions';
import { get, put, post, IHttpResponse } from 'utils/fetch';
import { AppState } from 'store';
import { PaymentWizard } from 'store/paymentWizard/types';
import { Plan } from 'store/plans/types';
import { filterIncludedAddons } from './utility';
import { InvoicesActions } from 'store/invoices/types';
import { apiGetInvoices, apiGetUpcomingInvoice } from 'store/invoices/actions';
import { CustomerActions } from 'store/customer/types';
import { apiGetCustomer } from 'store/customer/actions';

import {
  API_BASE_URL,
  IAPIResponse,
  IAPISubscription,
  IAPISubscriptionPreview,
  IAPISubsciptionPayAmountOwing,
} from 'store/api';

export const getSubscription = (): ThunkyAction => {
  return async (dispatch: ThunkyDispatch) => {
    return dispatch(
      asyncAction(SubscriptionActions.GET_SUBSCRIPTION, apiGetSubscription),
    );
  };
};

export const apiGetSubscription = async (): Promise<any> => {
  const response = await get<IAPIResponse<IAPISubscription>>(
    `${API_BASE_URL}/subscription`,
  );

  return response;
};

export const putSubscription = () => {
  return async (dispatch: ThunkyDispatch, getState: () => AppState) => {
    const { plans, paymentWizard } = getState();

    if (paymentWizard.planId) {
      await dispatch(
        asyncAction(
          SubscriptionActions.PUT_SUBSCRIPTION,
          apiPutSubscription,
          plans.plans[paymentWizard.planId],
          paymentWizard,
        ),
      );
    }

    // Get latest Customer, Subscription, Invoices, Upcoming Invoice after new subscription
    dispatch(asyncAction(CustomerActions.GET_CUSTOMER, apiGetCustomer));
    dispatch(asyncAction(InvoicesActions.GET_INVOICES, apiGetInvoices));
    dispatch(
      asyncAction(SubscriptionActions.GET_SUBSCRIPTION, apiGetSubscription),
    );
    dispatch(
      asyncAction(InvoicesActions.GET_UPCOMING_INVOICE, apiGetUpcomingInvoice),
    );
  };
};

export const apiPutSubscription = async (
  plan: Plan,
  paymentWizard: PaymentWizard,
): Promise<IHttpResponse<IAPIResponse<IAPISubscription>>> => {
  const response = await put<IAPIResponse<IAPISubscription>>(
    `${API_BASE_URL}/subscription`,
    {
      plan: paymentWizard.planId,
      addons: filterIncludedAddons(plan, paymentWizard),
      interval: paymentWizard.planInterval,
      coupon: paymentWizard.couponCode,
    },
  );

  return response;
};

export const postSubscriptionPreview = () => {
  return async (dispatch: ThunkyDispatch, getState: () => AppState) => {
    const { plans, paymentWizard } = getState();

    if (paymentWizard.planId && plans.plans[paymentWizard.planId]) {
      return dispatch(
        asyncAction(
          SubscriptionActions.POST_SUBSCRIPTION_PREVIEW,
          apiPostSubscriptionPreview,
          plans.plans[paymentWizard.planId],
          paymentWizard,
        ),
      );
    }
  };
};

export const apiPostSubscriptionPreview = async (
  plan: Plan,
  paymentWizard: PaymentWizard,
): Promise<IHttpResponse<IAPIResponse<IAPISubscriptionPreview>>> => {
  const response = await put<IAPIResponse<IAPISubscriptionPreview>>(
    `${API_BASE_URL}/subscription/preview`,
    {
      plan: paymentWizard.planId,
      addons: filterIncludedAddons(plan, paymentWizard),
      interval: paymentWizard.planInterval,
      coupon: paymentWizard.couponCode,
    },
  );

  return response;
};

export const postPayAmountOwing = () => {
  return async (dispatch: ThunkyDispatch) => {
    return dispatch(
      asyncAction(SubscriptionActions.PAY_AMOUNT_OWING, apiPostPayAmountOwing),
    );
  };
};

export const apiPostPayAmountOwing = async (): Promise<
  IHttpResponse<IAPIResponse<IAPISubsciptionPayAmountOwing>> | undefined
> => {
  const response = await post<IAPIResponse<IAPISubsciptionPayAmountOwing>>(
    `${API_BASE_URL}/customer/pay_amount_owing`,
    null,
  );

  return response;
};
