import createCachedSelector from 're-reselect';
import { AppState } from 'store';
import { Customer } from 'store/customer/types';
import { BillingForm } from 'store/forms';
import selectPaymentSource from './selectPaymentSource';
import { PaymentSource } from 'store/payment';

const getCustomer = (state: AppState): Customer | undefined => state.customer;

const selectBillingFormData = (
  customer: Customer | undefined,
  paymentSource: PaymentSource | undefined,
): BillingForm => {
  let formData: BillingForm = {};

  if (customer) {
    formData = {
      firstName: customer.firstName || '',
      lastName: customer.lastName || '',
      companyName: customer.company || '',
      email: customer.email || '',
      phone: customer.phone || '',
      billingAddress1: customer.billingAddress1 || '',
      billingAddress2: customer.billingAddress2 || '',
      billingCity: customer.billingCity || '',
      billingCountryISO: customer.billingCountryISO || '',
      billingRegionISO: customer.billingRegionISO || '',
      billingPostalCode: customer.billingPostalCode || '',
    };
  }

  if (paymentSource) {
    formData = {
      ...formData,
      billingCardHolder: paymentSource.billingCardholder || '',
    };
  }

  return formData;
};

export default createCachedSelector(
  getCustomer,
  selectPaymentSource,
  selectBillingFormData,
)(state => 'billingFormData');
