import React, { Fragment } from 'react';

type Props = {
  isLoading: boolean;
  onClick: React.FormEventHandler;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
};

export default function SubmitButton(props: Props) {
  return (
    <button
      type="submit"
      disabled={props.disabled || props.isLoading}
      className={`btn btn-primary btn-xl btn-block ${props.className}`}
      onClick={props.onClick}
    >
      {props.isLoading ? (
        <Fragment>
          <span className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
          </span>
          <span className="pl-2">Loading...</span>
        </Fragment>
      ) : (
        props.children
      )}
    </button>
  );
}
