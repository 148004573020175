import { Invoice, InvoicesTypes, InvoicesActions } from './types';
import { AsyncActionStatus } from 'store/asyncActions';
import { getAPIResource } from 'store/api';

const initialState: Invoice = {};

export function upcomingInvoice(
  state: Invoice = initialState,
  action: InvoicesTypes,
): Invoice {
  let newState: Invoice;

  switch (action.type) {
    case InvoicesActions.GET_UPCOMING_INVOICE:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        const apiInvoice = getAPIResource(action.payload);

        if (apiInvoice) {
          return {
            id: apiInvoice.id,
            pdfLink: apiInvoice.pdf_link,
            amountDueInCents: apiInvoice.amount_due_in_cents,
            amountPaidInCents: apiInvoice.amount_paid_in_cents,
            subtotalInCents: apiInvoice.subtotal_in_cents,
            taxInCents: apiInvoice.tax_in_cents,
            totalInCents: apiInvoice.total_in_cents,
            periodEnd: apiInvoice.period_end,
            currency: apiInvoice.currency,
            number: apiInvoice.number,
            paid: apiInvoice.paid,
            created: apiInvoice.created,
          };
        }

        newState = state;
      } else {
        newState = state;
      }
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
