import React, { Fragment } from 'react';
import { Invoice } from 'store/invoices/types';
import { AsyncActionStatus, asyncActionStatusBool } from 'store/asyncActions';
import { Subscription } from 'store/subscription/types';
import { PaymentSource } from 'store/payment';
import { Currency } from 'utils/countries';

import YourPlan from './YourPlan';
import UpcomingInvoice from './UpcomingInvoice';
import LatestInvoices from './LatestInvoices';

interface ConnectedProps {
  currency: Currency;
  invoices: Invoice[];
  invoicesLoading: AsyncActionStatus;
  paymentSource: PaymentSource | undefined;
  planLoading: AsyncActionStatus;
  subscription: Subscription;
  subscriptionLoading: AsyncActionStatus;
  upcomingInvoice: Invoice;
}

const Overview = (props: ConnectedProps) => (
  <Fragment>
    <div className="row row-spacer">
      <div className="col">
        <h2>Overview</h2>
      </div>
    </div>
    <div className="row row-spacer">
      <div className="col-md-12 col-lg-6">
        <YourPlan
          currency={props.currency}
          isLoading={
            asyncActionStatusBool(props.planLoading) ||
            asyncActionStatusBool(props.subscriptionLoading)
          }
          subscription={props.subscription}
        />
      </div>
      <div className="col-md-12 col-lg-6 mt-4 mt-lg-0">
        <UpcomingInvoice
          invoice={props.upcomingInvoice}
          isLoading={asyncActionStatusBool(props.invoicesLoading)}
          paymentSource={props.paymentSource}
        />
      </div>
    </div>
    <div className="row">
      <div className="col">
        <LatestInvoices
          invoices={props.invoices}
          isLoading={asyncActionStatusBool(props.invoicesLoading)}
        />
      </div>
    </div>
  </Fragment>
);

export default Overview;
