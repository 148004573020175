import { connect } from 'react-redux';
import { AppState } from 'store';

import selectSubscriptionCurrency from 'selectors/selectSubscriptionCurrency';
import selectBillingFormData from 'selectors/selectBillingFormData';
import selectPaymentSource from 'selectors/selectPaymentSource';
import PayAmountOwing from './PayAmountOwing';

import {
  clearMessages,
  onSubmitExistingPaymentMethod,
  onSubmitNewPaymentMethod,
} from 'store/payAmountOwingForm/actions';

const mapStateToProps = (state: AppState) => ({
  currency: selectSubscriptionCurrency(state),
  customer: state.customer,
  errorMessage: state.payAmountOwingForm.errorMessage,
  form: selectBillingFormData(state),
  formLoading: state.loading.payAmountOwing,
  newPaymentLoading: state.loading.postCreditCard,
  paymentSource: selectPaymentSource(state),
  successMessage: state.payAmountOwingForm.successMessage,
});

const mapDispatchToProps = {
  onSubmitExistingPaymentMethod: onSubmitExistingPaymentMethod,
  onSubmitNewPaymentMethod: onSubmitNewPaymentMethod,
  clearMessages: clearMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayAmountOwing);
