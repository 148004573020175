import React from 'react';

export default function NotFoundView() {
  return (
    <div className="AddonsView">
      <h1 className="text-center">Page not found</h1>

      <div className="pt-5">
        <p>Nothing to see here.</p>
      </div>
    </div>
  );
}
