import { useEffect, useState } from 'react';

// useDebounce sets a state value after delay (ms). This can be used when, e.g.,
// making API calls after an input value changes (e.g. via onChange)
// Inspiration: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Return a cleanup function that will be called every time useEffect is re-called.
    // useEffect will only be re-called if value changes. This is how we prevent debouncedValue
    // from changing if value is changed within the delay period. Timeout gets cleared and
    /// restarted. To put it in context, if the user is typing within an input field, we don't
    // want the debouncedValue to update until they've stopped typing for more than 500ms.
    return () => {
      clearTimeout(handler);
    };
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
