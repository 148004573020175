import { MiddlewareAPI, Dispatch, Middleware, AnyAction } from 'redux';
import { push } from 'connected-react-router';

const redirectMiddleware: Middleware<Dispatch> = (
  store: MiddlewareAPI,
) => next => (action: AnyAction) => {
  if (!action.redirect) {
    return next(action);
  }

  store.dispatch(push(action.redirect));

  return next(action);
};

export default redirectMiddleware;
