import { ReactStripeElements } from 'react-stripe-elements';
import { PaymentInterval } from 'store/payment';
import { PlanID, AddonID } from 'store/plans/types';
import { asyncAction, AsyncActionStatus } from 'store/asyncActions';
import { IAPIResponse, IAPICouponValidation, API_BASE_URL } from 'store/api';
import { ThunkyAction, ThunkyDispatch } from 'store/types';
import { IHttpResponse, post } from 'utils/fetch';
import { BillingForm } from 'store/forms';
import { getCustomer, postCreditCard } from 'store/customer/actions';

import {
  putSubscription,
  postSubscriptionPreview,
} from 'store/subscription/actions';

import {
  setIntervalAction,
  PaymentWizardActions,
  setPlanIdAction,
  addAddonAction,
  removeAddonAction,
  setMultiTenantAction,
  incrementMultiTenantAction,
  decrementMultiTenantAction,
  clearMessagesAction,
  continueToAddonsAction,
  PaymentWizardTypes,
  incrementEnterpriseQuotesAction,
  decrementEnterpriseQuotesAction,
} from './types';

import { Dispatch } from 'redux';

export const setInterval = (interval: PaymentInterval): setIntervalAction => ({
  type: PaymentWizardActions.SET_INTERVAL,
  payload: interval,
});

export const setPlanId = (planId: PlanID): setPlanIdAction => ({
  type: PaymentWizardActions.SET_PLAN_ID,
  payload: planId,
});

export const continueToAddons = (): continueToAddonsAction => ({
  type: PaymentWizardActions.CONTINUE_TO_ADDONS,
  redirect: '/plans/addons',
});

export const addAddon = (addonId: AddonID): addAddonAction => ({
  type: PaymentWizardActions.ADD_ADDON,
  payload: addonId,
});

export const removeAddon = (addonId: AddonID): removeAddonAction => ({
  type: PaymentWizardActions.REMOVE_ADDON,
  payload: addonId,
});

export const setMultiTenant = (value: string): setMultiTenantAction => ({
  type: PaymentWizardActions.SET_MULTI_TENANT,
  payload: value,
});

export const incrementMultiTenant = (): incrementMultiTenantAction => ({
  type: PaymentWizardActions.INCREMENT_MULTI_TENANT,
});

export const decrementMultiTenant = (): decrementMultiTenantAction => ({
  type: PaymentWizardActions.DECREMENT_MULTI_TENANT,
});
export const incrementEnterpriseQuotes = (): incrementEnterpriseQuotesAction => ({
  type: PaymentWizardActions.INCREMENT_ENTERPRISE_QUOTES,
});

export const decrementEnterpriseQuotes = (): decrementEnterpriseQuotesAction => ({
  type: PaymentWizardActions.DECREMENT_ENTERPRISE_QUOTES,
});

export const applyCouponCode = (
  currencyISO: string,
  interval: PaymentInterval,
  couponCode: string,
): ThunkyAction => {
  return async (dispatch: Dispatch<PaymentWizardTypes>) => {
    // Dispatch the SUBMIT_COUPON_CODE action to save the coupon code to the store
    dispatch({
      type: PaymentWizardActions.SUBMIT_COUPON_CODE,
      payload: couponCode,
    });

    return asyncAction(
      PaymentWizardActions.APPLY_COUPON_CODE,
      apiGetCoupon,
      couponCode,
      currencyISO,
      interval,
    )(dispatch);
  };
};

export const apiGetCoupon = async (
  couponCode: string,
  currencyISO: string,
  interval: PaymentInterval,
): Promise<IHttpResponse<IAPIResponse<IAPICouponValidation>>> => {
  const response = await post<IAPIResponse<IAPICouponValidation>>(
    `${API_BASE_URL}/validate_coupon`,
    {
      code: couponCode,
      currency: currencyISO.toUpperCase(),
      interval: interval,
    },
  );

  return response;
};

export const onSubmitExistingPaymentMethod = () => {
  return async (dispatch: ThunkyDispatch) => {
    await dispatch(putSubscription());

    return dispatch(postSubscriptionPreview());
  };
};

export const onSubmitNewPaymentMethod = (
  stripe: ReactStripeElements.StripeProps,
  formData: BillingForm,
) => {
  return async (dispatch: ThunkyDispatch) => {
    const postCreditCardResult = await dispatch(
      postCreditCard(stripe, formData),
    );

    if (postCreditCardResult.status === AsyncActionStatus.SUCCEEDED) {
      await dispatch(getCustomer());
      await dispatch(putSubscription());
    }
  };
};

export const clearMessages = (): clearMessagesAction => ({
  type: PaymentWizardActions.CLEAR_MESSAGES,
});
