import { AsyncAction } from 'store/asyncActions';
import { IAPICustomer, IAPIResponse, IAPICreditCard } from 'store/api';
import { PaymentSource } from 'store/payment';
import { IHttpResponse } from 'utils/fetch';

export interface Customer {
  id?: string;
  company?: string;
  currency?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  billingAddress1?: string;
  billingAddress2?: string | null;
  billingCity?: string;
  billingCountryISO?: string;
  billingRegionISO?: string | null;
  billingPostalCode?: string;
  paymentSources?: PaymentSource[] | null;
  balanceInCents?: number;
}

export enum CustomerActions {
  GET_CUSTOMER = 'GET_CUSTOMER',
  PUT_CUSTOMER = 'PUT_CUSTOMER',
  POST_CREDIT_CARD = 'POST_CREDIT_CARD',
}

export type getCustomerAsyncAction = AsyncAction<
  CustomerActions.GET_CUSTOMER,
  IHttpResponse<IAPIResponse<IAPICustomer>>
>;

export type putCustomerAsyncAction = AsyncAction<
  CustomerActions.PUT_CUSTOMER,
  IHttpResponse<IAPIResponse<IAPICustomer>>
>;

export type postCreditCardAsyncAction = AsyncAction<
  CustomerActions.POST_CREDIT_CARD,
  IHttpResponse<IAPIResponse<IAPICreditCard>>
>;

export type CustomerTypes =
  | getCustomerAsyncAction
  | putCustomerAsyncAction
  | postCreditCardAsyncAction;
