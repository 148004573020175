import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useNavigateAway = <T extends Function>(callback: T) => {
  let { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen(() => {
      callback();
    });

    return unlisten;
  }, [listen, callback]);
};

export default useNavigateAway;
