import React, { Fragment } from 'react';
import { ReactStripeElements, injectStripe } from 'react-stripe-elements';

import { setInitialBillingFormState } from 'store/forms';
import { BillingForm as BillingFormType } from 'store/forms';
import { AsyncActionStatus } from 'store/asyncActions';
import useForm from 'hooks/useForm';
import validate from 'utils/validate';
import useNavigateAway from 'hooks/useNavigateAway';

import BillingForm from '../../components/BillingForm';
import withStripe from 'containers/Billing/enhancers/withStripe';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/Icon';

interface ConnectedProps {
  clearMessages: () => void;
  form: BillingFormType;
  successMessage: string | undefined;
  isLoading: AsyncActionStatus;
  errorMessage: string | undefined;
  onSubmitUpdatePaymentMethod: (
    stripe: ReactStripeElements.StripeProps,
    formData: BillingFormType,
  ) => void;
}

interface InjectedStripeProps extends ConnectedProps {
  stripe?: ReactStripeElements.StripeProps | null;
  elements?: stripe.elements.Elements | null;
}

const UpdatePaymentMethod = (props: InjectedStripeProps) => {
  useNavigateAway(props.clearMessages);

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.isValid && props.stripe) {
      props.onSubmitUpdatePaymentMethod(props.stripe, {
        billingCardHolder: form.formControls.billingCardHolder.value,
        billingAddress1: form.formControls.billingAddress1.value,
        billingAddress2: form.formControls.billingAddress2.value,
        billingCity: form.formControls.billingCity.value,
        billingCountryISO: form.formControls.billingCountry.value,
        billingRegionISO: form.formControls.billingRegion.value,
        billingPostalCode: form.formControls.billingPostalCode.value,
      });
    }
  };

  const { form, setForm, handleFormChange, handleFormSubmit } = useForm(
    setInitialBillingFormState(),
    validate,
    onFormSubmit,
  );

  return (
    <Fragment>
      <div className="row row-spacer">
        <div className="col">
          <h2>Update Payment Method</h2>
        </div>
      </div>
      {props.successMessage && (
        <div className="alert alert-success">
          <Icon name="check" className="pr-4" />
          {props.successMessage}
        </div>
      )}
      {props.errorMessage && (
        <div className="alert alert-danger">
          <Icon name="exclamation-circle" className="pr-4" />
          {props.errorMessage}
        </div>
      )}
      <BillingForm
        form={form}
        onSubmit={handleFormSubmit}
        onFormChange={handleFormChange}
        setForm={setForm}
      />
      <SubmitButton
        isLoading={props.isLoading === AsyncActionStatus.STARTED}
        disabled={!form.isValid}
        onClick={handleFormSubmit}
        className="mt-4"
      >
        <Icon name="lock" size="sm" /> Update Payment Method
      </SubmitButton>
    </Fragment>
  );
};

export default withStripe(injectStripe(UpdatePaymentMethod));
