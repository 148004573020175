import React, { Fragment, useState, useEffect } from 'react';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { BillingForm, setInitialBillingFormState } from 'store/forms';
import { Currency } from 'utils/countries';
import { setIntervalAction, PaymentWizard } from 'store/paymentWizard/types';
import { AsyncActionStatus } from 'store/asyncActions';
import { Plan, Addons } from 'store/plans/types';
import useNavigateAway from 'hooks/useNavigateAway';

import useForm from 'hooks/useForm';
import validate from 'utils/validate';

import Wizard from 'containers/Billing/components/Wizard';
import YourSubscription from 'components/YourSubscription';
import Agreement from 'components/Agreement';
import Icon from 'components/Icon';
import SubmitButton from 'components/Form/SubmitButton';
import CurrencyNumber from 'components/CurrencyNumber';
import withStripe from 'containers/Billing/enhancers/withStripe';
import NoPlanRedirect from 'containers/Billing/components/NoPlanRedirect';

import {
  PaymentInterval,
  PaymentAmounts,
  PaymentSource,
  disableSubmitPaymentButton,
  UIPaymentMethod,
} from 'store/payment';

import {
  PaymentMethodExisting,
  PaymentMethodNew,
} from 'containers/Billing/components/PaymentMethod';

import style from './Payment.module.scss';

interface ConnectedProps {
  amounts: PaymentAmounts | undefined;
  amountsLoading: AsyncActionStatus;
  couponLoading: AsyncActionStatus;
  currency: Currency;
  form: BillingForm;
  formLoading: AsyncActionStatus;
  newPaymentLoading: AsyncActionStatus;
  paymentSource: PaymentSource | undefined;
  paymentWizard: PaymentWizard;
  selectedPlan: Plan | undefined;
  selectedPlanAddons: Addons | undefined;
  onSubmitExistingPaymentMethod: () => void;
  onSubmitNewPaymentMethod: (
    stripe: ReactStripeElements.StripeProps,
    form: BillingForm,
  ) => void;
  setInterval: (interval: PaymentInterval) => setIntervalAction;
  applyCouponCode?: (
    currencyISO: string,
    interval: PaymentInterval,
    couponCode: string,
  ) => any;
  postSubscriptionPreview: () => void;
  clearMessages: () => void;
}

interface StripeProps extends ConnectedProps {
  stripe?: ReactStripeElements.StripeProps | null;
  elements?: stripe.elements.Elements | null;
}

const Payment = ({ postSubscriptionPreview, ...props }: StripeProps) => {
  useNavigateAway(props.clearMessages);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [containerHeight, setContainerHeight] = useState<string | number>(0);
  const [paymentMethod, setPaymentMethod] = useState<UIPaymentMethod>(
    'existingPaymentMethod',
  );

  useEffect(() => {
    postSubscriptionPreview();
  }, [
    props.paymentWizard.planInterval,
    props.paymentWizard.couponCode,
    postSubscriptionPreview,
  ]);

  const handleChangePaymentMethod = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const id = e.target.id as UIPaymentMethod;

    setPaymentMethod(id);
    setContainerHeight(containerHeight === 0 ? 'auto' : 0);
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.isValid && props.stripe) {
      props.onSubmitNewPaymentMethod(props.stripe, {
        billingCardHolder: form.formControls.billingCardHolder.value,
        billingAddress1: form.formControls.billingAddress1.value,
        billingAddress2: form.formControls.billingAddress2.value,
        billingCity: form.formControls.billingCity.value,
        billingCountryISO: form.formControls.billingCountry.value,
        billingRegionISO: form.formControls.billingRegion.value,
        billingPostalCode: form.formControls.billingPostalCode.value,
      });
    }
  };

  const { form, setForm, handleFormChange, handleFormSubmit } = useForm(
    setInitialBillingFormState(),
    validate,
    onFormSubmit,
  );

  useEffect(() => {
    setIsSubmitDisabled(
      disableSubmitPaymentButton(paymentMethod, form.isValid),
    );
  }, [paymentMethod, form.isValid, props.amounts]);

  const determineFormSubmit = (e: React.FormEvent<any>) => {
    if (paymentMethod === 'existingPaymentMethod') {
      return props.onSubmitExistingPaymentMethod();
    }

    return handleFormSubmit(e);
  };

  const submitText = () => {
    if (props.amounts?.total && props.amounts.total > 0) {
      return (
        <>
          Pay{' '}
          <CurrencyNumber
            value={props.amounts.total}
            currency={props.currency}
            decimals={2}
          />{' '}
          Now and Update Plan
        </>
      );
    }

    return `Update Plan`;
  };

  if (props.paymentWizard.successMessage) {
    return (
      <Fragment>
        <div className="row row-spacer">
          <div className="col">
            <h2>Plan and Add-Ons</h2>
          </div>
        </div>
        <div className="row row-spacer">
          <div className="col-12 order-last col-lg order-lg-first">
            <h4 className="pt-1">{props.paymentWizard.successMessage}</h4>
            <div className="pt-2 pb-3">
              {props.paymentWizard.errorMessage ? (
                <>
                  <p>
                    <Icon
                      name="exclamation-triangle"
                      className="text-warning"
                    />{' '}
                    {props.paymentWizard.errorMessage} A{' '}
                    <Link to="/invoices">pending invoice</Link> has been
                    generated and charge will be retried automatically. You may
                    need to contact your bank to authorize the charge.
                  </p>
                </>
              ) : (
                <p>
                  Thank you for using Quoter. Your account has been provisioned
                  and your new subscription is available on your account.
                </p>
              )}
            </div>
            <div>
              <Link to="/" className="btn btn-outline-primary btn-md">
                Review Plan
              </Link>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  let subView;

  if (!props.selectedPlan || !props.selectedPlanAddons) {
    subView = <NoPlanRedirect />;
  } else {
    subView = (
      <div className="row row-spacer">
        <div className="col-12 order-last col-lg order-lg-first">
          {props.paymentWizard.successMessage && (
            <div className="alert alert-success">
              <Icon name="check" className="pr-4" />
              {props.paymentWizard.successMessage}
            </div>
          )}
          {props.paymentWizard.errorMessage && (
            <div className="alert alert-danger">
              <Icon name="exclamation-circle" className="pr-4" />
              {props.paymentWizard.errorMessage}
            </div>
          )}
          <PaymentMethodExisting
            id="existingPaymentMethod"
            onChange={handleChangePaymentMethod}
            paymentSource={props.paymentSource}
          />
          <PaymentMethodNew
            id="newPaymentMethod"
            form={form}
            height={containerHeight}
            onSubmit={handleFormSubmit}
            onFormChange={handleFormChange}
            onChange={handleChangePaymentMethod}
            setForm={setForm}
          />
          <SubmitButton
            isLoading={
              props.formLoading === AsyncActionStatus.STARTED ||
              props.newPaymentLoading === AsyncActionStatus.STARTED ||
              props.amountsLoading === AsyncActionStatus.STARTED
            }
            disabled={isSubmitDisabled}
            onClick={determineFormSubmit}
          >
            <Icon name="lock" size="sm" />
            {submitText()}
          </SubmitButton>
          <Agreement buttonText="Update Plan" />
        </div>
        <div
          className={classnames(style.colYourSubscription, {
            [style.isLoading]:
              props.amountsLoading === AsyncActionStatus.STARTED,
          })}
        >
          <YourSubscription
            detectedCountryISO="CAN"
            plan={props.selectedPlan}
            couponCode={props.paymentWizard.couponCode}
            planCoupon={props.paymentWizard.planCoupon}
            addons={props.selectedPlanAddons}
            interval={props.paymentWizard.planInterval}
            multiTenantUsers={props.paymentWizard.multiTenantUsers}
            enterpriseQuotes={props.paymentWizard.enterpriseQuotes}
            currency={props.currency}
            amounts={props.amounts}
            onIntervalChange={props.setInterval}
            showSubscriptionSummary={true}
            showProratedSting={true}
            showIntervalSelection={true}
            couponErrorMessage={props.paymentWizard.couponErrorMessage}
            applyCouponCode={props.applyCouponCode}
            couponIsLoading={props.couponLoading === AsyncActionStatus.STARTED}
            couponCodeInvalid={
              props.paymentWizard.couponErrorMessage.length > 0
            }
          />
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="row row-spacer">
        <div className="col">
          <h2>Plan and Add-Ons</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Wizard
            step="payment"
            paymentLocked={typeof props.selectedPlan === 'undefined'}
          />
        </div>
      </div>
      {subView}
    </Fragment>
  );
};

export default withStripe(injectStripe(Payment));
