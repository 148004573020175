export const ENTERPRISE_QUOTES_INITIAL = 50;
export const ENTERPRISE_QUOTES_MAX = 250;
export const ENTERPRISE_QUOTES_MIN = 50;
export const MULTI_TENENT_INITIAL = 10;
export const MULTI_TENTANT_MAX_USERS = 500;
export const MULTI_TENTANT_MIN_USERS = 10;
export const REDIRECT_DELAY_MS = 2500;

export const BILLING_ADDRESS_FORM_SUCCESS =
  'Your billing address has been updated successfully.';

export const BILLING_ADDRESS_FORM_ERROR =
  'Could not update billing address, please try again or contact support@quoter.com if the problem persists.';

export const PAYMENT_METHOD_FORM_SUCCESS =
  'Your payment method has been updated successfully.';

export const PAYMENT_METHOD_FORM_ERROR =
  'Could not update payment method, please try again or contact support@quoter.com if the problem persists.';

export const COUPON_ERROR =
  'Could not apply coupon, please try again or contact support@quoter.com if the problem persists.';

export const PAYMENT_WIZARD_FORM_SUCCESS =
  'Your plan has been updated succesfully.';

export const PAYMENT_WIZARD_FORM_SUCCESS_CARD_DECLINED =
  'Your plan has been updated succesfully but your card was declined.';

export const PAYMENT_WIZARD_FORM_ERROR =
  'Could not create new plan, please try again or contact support@quoter.com if the problem persists.';

export const PAY_AMOUNT_OWING_FORM_SUCCESS = 'Payment Received';

export const PAY_AMOUNT_OWING_FORM_ERROR =
  'Could not past due balance, please try again or contact support@quoter.com if the problem persists.';
