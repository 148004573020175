// Inspired by: https://www.carlrippon.com/fetch-with-async-await-and-typescript/
export interface IHttpResponse<T> extends Response {
  parsedBody?: T;
}

export const http = <T>(request: RequestInfo): Promise<IHttpResponse<T>> => {
  return new Promise((resolve, reject) => {
    let response: IHttpResponse<T>;
    fetch(request)
      .then(res => {
        response = res;
        if (res.status === 204) return '';
        return res.json();
      })
      .then(body => {
        response.parsedBody = body;
        if (response.ok) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

let authenticationParams: { [k: string]: any } = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
};
// Prioritize access token from .env in development
if (process.env.NODE_ENV === 'development') {
  let accessToken: string | undefined = process.env.REACT_APP_ACCESS_TOKEN;
  if (accessToken !== '') {
    authenticationParams['headers']['Authorization'] = 'Bearer ' + accessToken;
  }
}

export const get = async <T>(
  path: string,
  args: RequestInit = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    ...authenticationParams,
  },
  params?: { [key: string]: string } | null,
): Promise<IHttpResponse<T>> => {
  let url = new URL(path);

  if (params) {
    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key]),
    );
  }

  return await http<T>(new Request(`${url}`, args));
};

export const post = async <T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    body: JSON.stringify(body),
    ...authenticationParams,
  },
): Promise<IHttpResponse<T>> => {
  return await http<T>(new Request(path, args));
};

export const put = async <T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    body: JSON.stringify(body),
    ...authenticationParams,
  },
): Promise<IHttpResponse<T>> => {
  return await http<T>(new Request(path, args));
};

// export const patch = async <T>(
//   path: string,
//   body: any,
//   args: RequestInit = {
//     method: 'PATCH',
//     mode: 'cors',
//     cache: 'no-cache',
//     headers: {
//       'Content-Type': 'application/vnd.api+json',
//     },
//     redirect: 'follow',
//     body: JSON.stringify(body),
//   },
// ): Promise<IHttpResponse<T>> => {
//   return await http<T>(new Request(path, args));
// };
