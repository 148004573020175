import createCachedSelector from 're-reselect';
import { AppState } from 'store';
import { Currency, Currencies } from 'utils/countries';

const getCustomerCurrencyISO = (state: AppState): string | undefined =>
  state.customer.currency;

const selectSubscriptionCurrency = (
  currencyISO: string | undefined,
): Currency => {
  if (currencyISO) {
    const iso = currencyISO.toUpperCase();

    if (Currencies[iso]) {
      return Currencies[iso];
    }
  }

  return Currencies['USD'];
};

export default createCachedSelector(
  getCustomerCurrencyISO,
  selectSubscriptionCurrency,
)(state => 'planById');
