import React from 'react';

interface Props {
  className?: string;
  fixedWidth?: boolean;
  flip?: 'horizontal' | 'vertical';
  inverse?: boolean;
  name: string;
  pulse?: boolean;
  rotate?: '45' | '90' | '135' | '180' | '225' | '270' | '315';
  size?: 'sm' | 'lg' | '2x' | '3x' | '4x' | '5x';
  spin?: boolean;
  stack?: '1x' | '2x';
  onClick?: (e: React.MouseEvent) => void;
}

export const Icon = ({
  className,
  fixedWidth,
  flip,
  inverse,
  name,
  pulse,
  rotate,
  size,
  spin,
  stack,
  onClick,
  ...props
}: Props) => {
  let classNames = `fa fa-fw fa-${name}`;

  if (size) {
    classNames = `${classNames} fa-${size}`;
  }
  if (rotate) {
    classNames = `${classNames} fa-rotate-${rotate}`;
  }
  if (flip) {
    classNames = `${classNames} fa-flip-${flip}`;
  }
  if (fixedWidth) {
    classNames = `${classNames} fa-fw`;
  }
  if (spin) {
    classNames = `${classNames} fa-spin`;
  }
  if (pulse) {
    classNames = `${classNames} fa-pulse`;
  }
  if (stack) {
    classNames = `${classNames} fa-stack-${stack}`;
  }
  if (inverse) {
    classNames = `${classNames} fa-inverse`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return <span {...props} className={classNames} onClick={onClick} />;
};

export default Icon;
