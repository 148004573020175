// https://developers.google.com/recaptcha/docs/v3
import { useCallback, useEffect, useState } from 'react';

import { injectScript, removeScript } from '../utils/htmlDom';

const RECAPTCHA_SITE_KEY: string =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

const ERROR_SCRIPT_NOT_AVAILABLE = 'Google reCAPTCHA is not available';

const GOOGLE_RECAPTCHA_V3_SCRIPT = 'https://www.google.com/recaptcha/api.js';
const SCRIPT_ID = 'google-recaptcha-v3';
const GOOGLE_RECAPTCHA_BADGE_CLASSNAME = '.grecaptcha-badge';

export interface IGoogleReCaptcha {
  ready: (callback: () => void) => void;
  execute: (siteKey: string, options?: { action?: string }) => Promise<string>;
}

export interface IGoogleReCaptchWindow extends Window {
  grecaptcha: IGoogleReCaptcha;
}

type TGoogleReCaptchaV3Hook = (
  options?: IGoogleReCaptchaOptions,
) => IGoogleReCaptchaV3HookReturn;

interface IGoogleReCaptchaOptions {
  loadOnStart: boolean;
  action: string;
}

interface IGoogleReCaptchaV3HookReturn {
  reCaptchaResponseToken?: string;
  executeReCaptcha: (action?: string) => Promise<string>;
}

export const getGoogleReCaptchWindow = () =>
  (window as unknown) as IGoogleReCaptchWindow;

export const useGoogleReCaptchaV3: TGoogleReCaptchaV3Hook = (
  options?: IGoogleReCaptchaOptions,
) => {
  let loadOnStart = options?.loadOnStart || false;
  let action = options?.action || 'submit';

  const [responseToken, setResponseToken] = useState<string>();

  const executeReCaptcha = useCallback(async (action?: string): Promise<
    string
  > => {
    const window = getGoogleReCaptchWindow();
    const { grecaptcha } = window;
    if (!grecaptcha) {
      throw new Error(ERROR_SCRIPT_NOT_AVAILABLE);
    }

    return new Promise(resolve => {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(RECAPTCHA_SITE_KEY, { action })
          .then(token => resolve(token));
      });
    });
  }, []);

  const removeGReCaptchaDivElement = () => {
    let element = window.document.querySelector(
      GOOGLE_RECAPTCHA_BADGE_CLASSNAME,
    );
    if (element && element.parentElement) {
      element.parentElement.remove();
    }
  };

  useEffect(() => {
    if (!RECAPTCHA_SITE_KEY) {
      return;
    }

    const onLoadInjectedScript = async () => {
      if (!loadOnStart) {
        return;
      }

      try {
        const token = await executeReCaptcha(action);
        setResponseToken(token);
      } catch (e) {
        console.warn(e);
      }
    };

    const scriptTag = window.document.getElementById(SCRIPT_ID);
    if (!scriptTag) {
      injectScript(
        SCRIPT_ID,
        `${GOOGLE_RECAPTCHA_V3_SCRIPT}?render=${RECAPTCHA_SITE_KEY}`,
        onLoadInjectedScript,
      );
    }

    return () => {
      removeScript(SCRIPT_ID);
      removeGReCaptchaDivElement();
    };
  }, [loadOnStart, action, executeReCaptcha, setResponseToken]);

  return { executeReCaptcha, reCaptchaResponseToken: responseToken };
};

export default useGoogleReCaptchaV3;
