import { CustomerTypes, CustomerActions } from 'store/customer/types';
import { AsyncActionStatus } from 'store/asyncActions';

import {
  BILLING_ADDRESS_FORM_ERROR,
  BILLING_ADDRESS_FORM_SUCCESS,
} from 'store/constants';

import {
  BillingAddressForm,
  BillingAddressFormTypes,
  BillingAddressFormActions,
} from './types';

const initialState: BillingAddressForm = {};

export function billingAddressForm(
  state: BillingAddressForm = initialState,
  action: CustomerTypes | BillingAddressFormTypes,
): BillingAddressForm {
  let newState: BillingAddressForm;

  switch (action.type) {
    case CustomerActions.PUT_CUSTOMER:
      if (action.status === AsyncActionStatus.SUCCEEDED) {
        return {
          successMessage: BILLING_ADDRESS_FORM_SUCCESS,
        };
      } else if (action.status === AsyncActionStatus.FAILED) {
        return {
          errorMessage: BILLING_ADDRESS_FORM_ERROR,
        };
      } else {
        newState = state;
      }
      break;
    case BillingAddressFormActions.CLEAR_MESSAGES:
      newState = {
        errorMessage: '',
        successMessage: '',
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
