import React from 'react';
import { APP_QUOTER_URL } from 'store/api';
import { REDIRECT_DELAY_MS } from 'store/constants';
import style from './Layout.module.scss';

const LayoutError = () => {
  setTimeout(() => {
    window.location.href = APP_QUOTER_URL;
  }, REDIRECT_DELAY_MS);

  return (
    <div className={`${style.authLayout}`}>
      <div className={style.authCenter}>
        <span className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </span>
        <div className={`${style.authCenterFloatingText} fadein`}>
          <h5 className="text-center mt-4">
            You must be logged in to view this page.
          </h5>
          <div className="text-muted text-center">Redirecting...</div>
        </div>
      </div>
    </div>
  );
};

export default LayoutError;
