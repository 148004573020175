import React, { Fragment, useState } from 'react';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { Currency } from 'utils/countries';
import { BillingForm, setInitialBillingFormState } from 'store/forms';
import { PaymentSource } from 'store/payment';
import { clearMessagesAction } from 'store/payAmountOwingForm/types';
import { Customer } from 'store/customer/types';
import { Link, useHistory } from 'react-router-dom';
import { AsyncActionStatus } from 'store/asyncActions';

import withStripe from 'containers/Billing/enhancers/withStripe';
import useNavigateAway from 'hooks/useNavigateAway';
import SubmitButton from 'components/Form/SubmitButton';
import Icon from 'components/Icon';
import Agreement from 'components/Agreement';
import CurrencyNumber from 'components/CurrencyNumber';
import useForm from 'hooks/useForm';
import validate from 'utils/validate';

import {
  customerHasBalance,
  customerHasBalanceDue,
} from 'store/customer/utility';

import {
  PaymentMethodExisting,
  PaymentMethodNew,
} from 'containers/Billing/components/PaymentMethod';

type ConnectedProps = {
  clearMessages: () => clearMessagesAction;
  currency: Currency;
  customer: Customer;
  errorMessage?: string;
  form: BillingForm;
  formLoading: AsyncActionStatus;
  newPaymentLoading: AsyncActionStatus;
  onSubmitExistingPaymentMethod: () => void;
  onSubmitNewPaymentMethod: (
    stripe: ReactStripeElements.StripeProps,
    form: BillingForm,
  ) => void;
  paymentSource?: PaymentSource;
  successMessage?: string;
};

interface StripeProps extends ConnectedProps {
  stripe?: ReactStripeElements.StripeProps | null;
  elements?: stripe.elements.Elements | null;
}

type UIPaymentMethod = 'existingPaymentMethod' | 'newPaymentMethod';

const PayAmountOwing = (props: StripeProps) => {
  useNavigateAway(props.clearMessages);

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.isValid && props.stripe) {
      props.onSubmitNewPaymentMethod(props.stripe, {
        billingCardHolder: form.formControls.billingCardHolder.value,
        billingAddress1: form.formControls.billingAddress1.value,
        billingAddress2: form.formControls.billingAddress2.value,
        billingCity: form.formControls.billingCity.value,
        billingCountryISO: form.formControls.billingCountry.value,
        billingRegionISO: form.formControls.billingRegion.value,
        billingPostalCode: form.formControls.billingPostalCode.value,
      });
    }
  };

  const { form, setForm, handleFormChange, handleFormSubmit } = useForm(
    setInitialBillingFormState(),
    validate,
    onFormSubmit,
  );

  const history = useHistory();
  const [containerHeight, setContainerHeight] = useState<string | number>(0);
  const [paymentMethod, setPaymentMethod] = useState<UIPaymentMethod>(
    'existingPaymentMethod',
  );

  const handleChangePaymentMethod = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const id = e.target.id as UIPaymentMethod;

    setPaymentMethod(id);
    setContainerHeight(containerHeight === 0 ? 'auto' : 0);
  };

  const shouldSubmitDisable = () => {
    if (paymentMethod === 'existingPaymentMethod') {
      return false;
    }

    return !form.isValid;
  };

  const determineFormSubmit = (e: React.FormEvent<any>) => {
    if (paymentMethod === 'existingPaymentMethod') {
      return props.onSubmitExistingPaymentMethod();
    }

    return handleFormSubmit(e);
  };

  if (
    props.successMessage &&
    !customerHasBalanceDue(props.customer.balanceInCents)
  ) {
    return (
      <Fragment>
        <div className="row row-spacer">
          <div className="col">
            <h2>Pay Amount Owing</h2>
          </div>
        </div>
        <div className="row row-spacer">
          <div className="col-12 order-last col-lg order-lg-first">
            <h4 className="pt-1">{props.successMessage}</h4>
            <div className="pt-2 pb-3">
              <p>
                Thank you for using Quoter. Your account's due balance was payed
                successfully.
              </p>
            </div>
            <div>
              <Link to="/" className="btn btn-outline-primary btn-md">
                Back to Overview
              </Link>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  if (
    customerHasBalance(props.customer.balanceInCents) ||
    !customerHasBalanceDue(props.customer.balanceInCents)
  ) {
    history.push('/');
    return null;
  }

  return (
    <Fragment>
      <div className="row row-spacer">
        <div className="col">
          <h2>Pay Amount Owing</h2>
        </div>
      </div>
      <div className="row row-spacer">
        <div className="col-12">
          {props.errorMessage && (
            <div className="alert alert-danger">
              <Icon name="exclamation-circle" className="pr-4" />
              {props.errorMessage}
            </div>
          )}
          <PaymentMethodExisting
            id="existingPaymentMethod"
            onChange={handleChangePaymentMethod}
            paymentSource={props.paymentSource}
          />
          <PaymentMethodNew
            id="newPaymentMethod"
            form={form}
            height={containerHeight}
            onSubmit={handleFormSubmit}
            onFormChange={handleFormChange}
            onChange={handleChangePaymentMethod}
            setForm={setForm}
          />
          <SubmitButton
            isLoading={
              props.formLoading === AsyncActionStatus.STARTED ||
              props.newPaymentLoading === AsyncActionStatus.STARTED
            }
            disabled={shouldSubmitDisable()}
            onClick={determineFormSubmit}
          >
            <Icon name="lock" size="sm" />
            <>
              Pay{' '}
              <CurrencyNumber
                value={Math.abs(props.customer.balanceInCents / 100)}
                currency={props.currency}
                decimals={2}
              />{' '}
              Now
            </>
          </SubmitButton>
          <Agreement buttonText="Pay Now" />
        </div>
      </div>
    </Fragment>
  );
};

export default withStripe(injectStripe(PayAmountOwing));
