import { AsyncAction } from 'store/asyncActions';
import { PlanCoupon, PaymentInterval } from 'store/payment';
import { PlanID } from 'store/plans/types';
import { IHttpResponse } from 'utils/fetch';

import {
  IAPISubscription,
  IAPIResponse,
  IAPISubscriptionPreview,
  IAPISubsciptionPayAmountOwing,
} from 'store/api';

export interface Subscription {
  id?: string;
  customerId?: string;
  plan?: SubscriptionPlan;
  addons?: SubscriptionAddon[];
  coupon?: PlanCoupon | null;
  paymentInterval?: PaymentInterval;
  paymentErrorMessage?: string | null;
  commitment?: string;
  status?: SubscriptionStatusTypes;
  amountOwingInCents?: number;
  mrrInCents?: number;
  priceInCents?: number;
  currencyISO?: string;
  currentPeriodStartsAt?: string;
  cancelledAt?: string | null;
  currentPeriodEndsAt?: string;
  preview?: SubscriptionPreview;
}

export interface SubscriptionPlan {
  id: PlanID;
  name: string;
  version: string;
  priceInCents: number;
  quantity: number;
}

export interface SubscriptionAddon {
  id: string;
  name: string;
  priceInCents: number;
  quantity: number;
}

export interface SubscriptionPreview {
  coupon: PlanCoupon | null;
  discountAmountInCents: number | null;
  paymentInterval: string;
  proratedSubtotalInCents: number;
  proratedTaxes: SubscriptionPreviewTax[] | null;
  proratedTotalInCents: number;
  recurringSubtotalInCents: number;
  recurringTotalInCents: number;
  recurringTaxes: SubscriptionPreviewTax[] | null;
}

export interface SubscriptionPreviewTax {
  displayName: string;
  percentage: number;
  amountInCents: number;
}

export enum SubscriptionStatusTypes {
  active = 'active',
  cancelled = 'cancelled',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  trialing = 'trialing',
  unpaid = 'unpaid',
}

export enum SubscriptionActions {
  GET_SUBSCRIPTION = 'GET_SUBSCRIPTION',
  PUT_SUBSCRIPTION = 'PUT_SUBSCRIPTION',
  POST_SUBSCRIPTION_PREVIEW = 'POST_SUBSCRIPTION_PREVIEW',
  PAY_AMOUNT_OWING = 'PAY_AMOUNT_OWING',
}

export type getSubscriptionAsyncAction = AsyncAction<
  SubscriptionActions.GET_SUBSCRIPTION,
  IHttpResponse<IAPIResponse<IAPISubscription>>
>;

export type putSubscriptionAsyncAction = AsyncAction<
  SubscriptionActions.PUT_SUBSCRIPTION,
  IHttpResponse<IAPIResponse<IAPISubscription>>
>;

export type postSubscriptionPreviewAsyncAction = AsyncAction<
  SubscriptionActions.POST_SUBSCRIPTION_PREVIEW,
  IHttpResponse<IAPIResponse<IAPISubscriptionPreview>>
>;

export type postSubscriptionPayAmountOwingAsyncAction = AsyncAction<
  SubscriptionActions.PAY_AMOUNT_OWING,
  IHttpResponse<IAPIResponse<IAPISubsciptionPayAmountOwing>>
>;

export type SubscriptionTypes =
  | getSubscriptionAsyncAction
  | putSubscriptionAsyncAction
  | postSubscriptionPreviewAsyncAction
  | postSubscriptionPayAmountOwingAsyncAction;
