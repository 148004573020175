import React from 'react';

import amex from 'assets/credit-cards/amex.svg';
import mastercard from 'assets/credit-cards/mastercard.svg';
import nocard from 'assets/credit-cards/no-card.svg';
import paypal from 'assets/credit-cards/paypal.svg';
import visa from 'assets/credit-cards/visa.svg';

interface Props {
  brand?: string;
  size?: string;
}

const CreditCard = (props: Props) => {
  const size = props.size ? props.size : '38';
  const brand = props.brand?.toLowerCase();

  let card: string;

  switch (brand) {
    case 'amex':
      card = amex;
      break;
    case 'mastercard':
      card = mastercard;
      break;
    case 'paypal':
      card = paypal;
      break;
    case 'visa':
      card = visa;
      break;
    default:
      card = nocard;
      break;
  }

  return <img src={card} alt="Your Payment Method" height={size} />;
};

export default CreditCard;
