import { connect } from 'react-redux';
import { AppState } from 'store';

import selectPaymentSource from 'selectors/selectPaymentSource';
import selectSubscriptionCurrency from 'selectors/selectSubscriptionCurrency';

import Overview from './Overview';

const mapStateToProps = (state: AppState) => ({
  currency: selectSubscriptionCurrency(state),
  invoices: state.invoices.allInvoices,
  invoicesLoading: state.loading.invoices,
  paymentSource: selectPaymentSource(state),
  planLoading: state.loading.plans,
  subscription: state.subscription,
  subscriptionLoading: state.loading.subscription,
  upcomingInvoice: state.invoices.upcomingInvoice,
});

export default connect(mapStateToProps, null)(Overview);
