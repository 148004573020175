import { PaymentWizard } from 'store/paymentWizard/types';
import { AddonID, Addons, Plan } from 'store/plans/types';
import { convertAPIPlanCoupon, PaymentInterval } from 'store/payment';
import {
  ENTERPRISE_QUOTES_INITIAL,
  MULTI_TENENT_INITIAL,
} from 'store/constants';

import {
  IAPISubscription,
  IAPISubscriptionPreview,
  IAPISubscriptionPreviewTax,
} from 'store/api';

import {
  convertAPIPlanId,
  convertAPIPaymentInterval,
  anyAddonsSelected,
  convertAPIAddonId,
} from 'store/plans/utility';

import {
  Subscription,
  SubscriptionAddon,
  SubscriptionPreview,
  SubscriptionPreviewTax,
  SubscriptionStatusTypes,
} from './types';

export const convertAPISubscriptionType = (
  apiType: string,
): SubscriptionStatusTypes => {
  let type: SubscriptionStatusTypes = SubscriptionStatusTypes.active;

  switch (apiType) {
    case 'active':
      type = SubscriptionStatusTypes.active;
      break;
    case 'cancelled':
      type = SubscriptionStatusTypes.cancelled;
      break;
    case 'incomplete':
      type = SubscriptionStatusTypes.incomplete;
      break;
    case 'incomplete_expired':
      type = SubscriptionStatusTypes.incomplete_expired;
      break;
    case 'past_due':
      type = SubscriptionStatusTypes.past_due;
      break;
    case 'trialing':
      type = SubscriptionStatusTypes.trialing;
      break;
    case 'unpaid':
      type = SubscriptionStatusTypes.unpaid;
      break;
    default:
      type = SubscriptionStatusTypes.incomplete;
      break;
  }

  return type;
};

export const convertAPISubscription = (
  apiSubscription: IAPISubscription,
): Subscription => {
  return {
    id: apiSubscription.id,
    customerId: apiSubscription.customer_id,
    plan: {
      id: convertAPIPlanId(apiSubscription.plan.id),
      name: apiSubscription.plan.name,
      version: apiSubscription.plan.version,
      priceInCents: apiSubscription.plan.price_in_cents,
      quantity: apiSubscription.plan.quantity,
    },
    addons: apiSubscription.addons.map(addon => ({
      id: addon.id,
      name: addon.name,
      priceInCents: addon.price_in_cents,
      quantity: addon.quantity,
    })),
    coupon: convertAPIPlanCoupon(apiSubscription.coupon),
    paymentInterval: convertAPIPaymentInterval(
      apiSubscription.payment_interval,
    ),
    paymentErrorMessage: apiSubscription.payment_error_message,
    commitment: apiSubscription.commitment,
    status: convertAPISubscriptionType(apiSubscription.status),
    amountOwingInCents: apiSubscription.amount_owing_in_cents,
    mrrInCents: apiSubscription.mrr_in_cents,
    priceInCents: apiSubscription.price_in_cents,
    currencyISO: apiSubscription.currency_iso,
    currentPeriodStartsAt: apiSubscription.current_period_starts_at,
    cancelledAt: apiSubscription.cancelled_at,
    currentPeriodEndsAt: apiSubscription.current_period_ends_at,
  };
};

export const convertAPISubscriptionPreview = (
  apiSubscriptionPreview: IAPISubscriptionPreview,
): SubscriptionPreview => {
  return {
    coupon: convertAPIPlanCoupon(apiSubscriptionPreview.coupon),
    discountAmountInCents: apiSubscriptionPreview.discount_amount_in_cents,
    paymentInterval: apiSubscriptionPreview.payment_interval,
    proratedSubtotalInCents: apiSubscriptionPreview.prorated_subtotal_in_cents,
    proratedTaxes: convertAPISubscriptionPreviewTax(
      apiSubscriptionPreview.prorated_taxes,
    ),
    proratedTotalInCents: apiSubscriptionPreview.prorated_total_in_cents,
    recurringSubtotalInCents:
      apiSubscriptionPreview.recurring_subtotal_in_cents,
    recurringTotalInCents: apiSubscriptionPreview.recurring_total_in_cents,
    recurringTaxes: convertAPISubscriptionPreviewTax(
      apiSubscriptionPreview.recurring_taxes,
    ),
  };
};

export const convertAPISubscriptionPreviewTax = (
  apiTaxes: IAPISubscriptionPreviewTax[] | null,
): SubscriptionPreviewTax[] | null => {
  if (apiTaxes) {
    return apiTaxes.map(apiTax => ({
      displayName: apiTax.display_name,
      percentage: apiTax.percentage,
      amountInCents: apiTax.amount_in_cents,
    }));
  }
  return null;
};

export const buildSubscriptionIAPIAddon = (paymentWizard: PaymentWizard) => (
  addons: { [id: string]: number },
  addonId: AddonID,
) => {
  if (addonId === 'multi-tenant-users')
    addons[addonId] = paymentWizard.multiTenantUsers;
  else if (addonId === 'enterprise-quotes')
    addons[addonId] = paymentWizard.enterpriseQuotes;
  else addons[addonId] = 1;

  return addons;
};

export const getSubscriptionMultiTenantUsers = (
  subscription: Subscription,
): number => {
  let multiTenantUsers = MULTI_TENENT_INITIAL;

  const multiTenantAddon = subscription.addons?.find(isMultiTenantAddon);
  if (multiTenantAddon) {
    multiTenantUsers = multiTenantAddon.quantity;
  }

  return multiTenantUsers;
};

export const getSubscriptionEnterpriseQuotes = (
  subscription: Subscription,
): number => {
  let enterpriseQuotes = ENTERPRISE_QUOTES_INITIAL;

  const enterpriseQuotesAddon = subscription.addons?.find(
    isEnterpriseQuotesAddon,
  );
  if (enterpriseQuotesAddon) {
    enterpriseQuotes = enterpriseQuotesAddon.quantity;
  }

  return enterpriseQuotes;
};

export const isMultiTenantAddon = (addon: SubscriptionAddon): boolean =>
  addon.id === AddonID.MULTI_TENANT_USERS;

export const isEnterpriseQuotesAddon = (addon: SubscriptionAddon): boolean =>
  addon.id === AddonID.ENTERPRISE_QUOTES;

export const isChangedSubscription = (
  selectedPlan?: Plan,
  selectedAddons?: Addons,
  selectedInterval?: PaymentInterval,
  subscription?: Subscription,
): boolean => {
  let isChangedSubscription = false;

  if (selectedPlan?.id !== subscription?.plan?.id) {
    isChangedSubscription = true;
  }

  if (subscription?.plan?.version !== 'v2') {
    isChangedSubscription = true;
  }

  if (selectedInterval !== subscription?.paymentInterval) {
    isChangedSubscription = true;
  }

  if (anyAddonsSelected(selectedAddons, subscription?.addons)) {
    isChangedSubscription = true;
  }

  return isChangedSubscription;
};

export const isChangedMultiTenant = (
  multiTenantUsers: number,
  subscription?: Subscription,
): boolean => {
  const multiTenantAddon = subscription?.addons?.find(
    addon => addon.id === AddonID.MULTI_TENANT_USERS,
  );

  if (multiTenantAddon) {
    return multiTenantUsers > multiTenantAddon.quantity;
  }

  return false;
};

export const isChangedEnterpriseQuotes = (
  enterpriseQuotes: number,
  subscription?: Subscription,
): boolean => {
  const enterpriseQuotesAddon = subscription?.addons?.find(
    addon => addon.id === AddonID.ENTERPRISE_QUOTES,
  );

  if (enterpriseQuotesAddon) {
    return enterpriseQuotes > enterpriseQuotesAddon.quantity;
  }

  return false;
};

// We want to show the subscription's addons and allow them to be
// added and removed, but only if they're available for that addon.
// So in the case of Basic + SAML -> Pro, don't show the SAML addon.
export const filterIncludedAddons = (
  plan: Plan,
  paymentWizard: PaymentWizard,
) => {
  const availableAddons = Object.keys(plan.availableAddons).map(addonId =>
    convertAPIAddonId(addonId),
  );
  const filteredAddonIds = paymentWizard.addons.filter(addonId =>
    availableAddons.includes(addonId),
  );

  return filteredAddonIds.reduce(buildSubscriptionIAPIAddon(paymentWizard), {});
};
