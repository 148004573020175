import React from 'react';
import { Currency } from 'utils/countries';

interface Props {
  value: string | number;
  currency: Currency;
  className?: string;
  decimals?: number;
}

const CurrencyNumber = (props: Props) => {
  const { value, className, currency } = props;
  const decimals = props.decimals !== undefined ? props.decimals : 2;
  const thousandSeparator = currency.thousands ? currency.thousands : '';

  const formatValue = (value: number): string =>
    value
      .toFixed(decimals)
      .replace(/\./, currency.decimal)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + thousandSeparator);

  const formatCurrency = (value: number): string =>
    (currency.before ? currency.before : '') +
    formatValue(value) +
    (currency.after ? currency.after : '');

  return (
    <span className={className}>
      {formatCurrency(parseFloat(value.toString()))}
    </span>
  );
};

export default CurrencyNumber;
