import createCachedSelector from 're-reselect';
import { AppState } from 'store';
import { PaymentAmounts } from 'store/payment';
import { SubscriptionPreview } from 'store/subscription/types';

const getSubscriptionPreview = (state: AppState) => state.subscription.preview;

const selectPaymentAmounts = (
  subscriptionPreview: SubscriptionPreview | undefined,
): PaymentAmounts | undefined => {
  if (subscriptionPreview) {
    return {
      recurring: subscriptionPreview.recurringSubtotalInCents / 100,
      taxes: subscriptionPreview.recurringTaxes?.map(previewTax => ({
        name: previewTax.displayName,
        amount: previewTax.amountInCents / 100,
      })),
      planDiscount: subscriptionPreview.discountAmountInCents
        ? subscriptionPreview.discountAmountInCents / 100
        : 0,
      total: subscriptionPreview.proratedTotalInCents / 100,
    };
  }

  return undefined;
};

export default createCachedSelector(
  getSubscriptionPreview,
  selectPaymentAmounts,
)(state => state.customer.id);
